import React, { useState } from "react";
import "./css/index.css";
type Props={
    category:string[];
    nowCategory: number;
    UpdateData:(type:string,value:string|number)=>void;
}

const CategoryBox = ({category ,nowCategory ,UpdateData }:Props)=>{
    return (
        <div className='category-menu-container'>
            {category.map((item,idx)=>(
                
                <button
                    key={idx} 
                    className={`category-menu ${nowCategory ===idx && "selected"}`}
                    onClick={()=>{
                    UpdateData("category",idx)
                }}
                >
          <p>      {item}</p>
                </button>
            ))}
        </div>
    )
}
export default CategoryBox;