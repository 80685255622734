import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./css/index.css";
import { PurposeType } from "../../interface/interface";

type Props = {
  individualCheck: boolean[];
  setIndividualCheck: React.Dispatch<React.SetStateAction<boolean[]>>;
  purpose: PurposeType;
};
const PaymentPolicy = ({
  individualCheck,
  setIndividualCheck,
  purpose,
}: Props) => {
  const isAll = individualCheck.every((item) => item);
  const handlePolicyChange = (index: any) => {
    if (index === "all") {
      setIndividualCheck([!isAll, !isAll, !isAll]);
    } else {
      const newIndividualCheck = [...individualCheck];
      newIndividualCheck[index] = !individualCheck[index];
      setIndividualCheck(newIndividualCheck);
    }
  };

  return (
    <section className="policy-section">
      <button
        className={`policy-wrapper all ${isAll ? `${purpose}-all` : ""}`}
        onClick={() => {
          handlePolicyChange("all");
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="23.273" height="23.273">
          <g transform="translate(-434 -239)">
            <circle
              data-name="타원 59"
              cx="11.637"
              cy="11.637"
              r="11.637"
              transform="translate(434 239)"
              style={{
                fill: isAll
                  ? purpose === "projects"
                    ? "var(--main)"
                    : "var(--red600)"
                  : "#DBDBDB",
              }}
            />
            <path
              data-name="패스 275"
              d="m3698.752 1185.89 3.99 3.99 5.185-6.831"
              transform="translate(-3257.466 -935.319)"
              style={{
                fill: "none",
                stroke: "#fff",
                strokeLinecap: "round",
                strokeWidth: "1.5px",
                strokeLinejoin: "round",
              }}
            />
          </g>
        </svg>
        전체 동의 <span>(필수)</span>
      </button>
      <div className="policy-wrapper">
        <button
          className={`left ${individualCheck[0] ? "checked" : ""}`}
          onClick={() => {
            handlePolicyChange(0);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.273"
            height="23.273"
          >
            <g transform="translate(-434 -239)">
              <circle
                data-name="타원 59"
                cx="11.637"
                cy="11.637"
                r="11.637"
                transform="translate(434 239)"
                style={{
                  fill: individualCheck[1]
                    ? purpose === "projects"
                      ? "var(--main)"
                      : "var(--red600)"
                    : "#DBDBDB",
                }}
              />
              <path
                data-name="패스 275"
                d="m3698.752 1185.89 3.99 3.99 5.185-6.831"
                transform="translate(-3257.466 -935.319)"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeLinecap: "round",
                  strokeWidth: "1.5px",
                  strokeLinejoin: "round",
                }}
              />
            </g>
          </svg>
          제 3자에 대한 개인정보제공 동의
        </button>
        <Link to="/policy/offer" target="_blank" className="link">
          전문보기
        </Link>
      </div>
      <div className="policy-wrapper">
        <button
          className={`left ${individualCheck[1] ? "checked" : ""}`}
          onClick={() => {
            handlePolicyChange(1);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.273"
            height="23.273"
          >
            <g transform="translate(-434 -239)">
              <circle
                data-name="타원 59"
                cx="11.637"
                cy="11.637"
                r="11.637"
                transform="translate(434 239)"
                style={{
                  fill: individualCheck[2]
                    ? purpose === "projects"
                      ? "var(--main)"
                      : "var(--red600)"
                    : "#DBDBDB",
                }}
              />
              <path
                data-name="패스 275"
                d="m3698.752 1185.89 3.99 3.99 5.185-6.831"
                transform="translate(-3257.466 -935.319)"
                style={{
                  fill: "none",
                  stroke: "#fff",
                  strokeLinecap: "round",
                  strokeWidth: "1.5px",
                  strokeLinejoin: "round",
                }}
              />
            </g>
          </svg>
          책임 규정에 대한 동의
        </button>
        <Link to="/policy/donate" target="_blank" className="link">
          전문보기
        </Link>
      </div>
    </section>
  );
};

export default PaymentPolicy;
