import React, { Dispatch, SetStateAction, useCallback } from "react";
import "./css/index.css";
import { useNavigate } from "react-router-dom";
import PqboxSearch from "../../components/Pqbox/PqboxSearch/PqboxSearch";
import PqboxListEmty from "../../components/Pqbox/PqboxListEmty/PqboxListEmty";
import { ProjectType, PurposeType } from "../../interface/interface";
import PqboxManageCard from "../../components/Pqbox/PqboxManageCard/PqboxManageCard";
import { UserAgentType } from "../../common/common.inerface";
import PqboxUserCard from "../../components/Pqbox/PqboxUserCard/PqboxUserCard";
import { useDispatch } from "react-redux";
import { ToastChange } from "../../reducers/config";
type Props = {
  projectId?: string;
  purpose?: PurposeType;
  RewardList: ProjectType[];
  isManager: boolean;
  Search: (value: string, category?: number) => void;
  userAgent: UserAgentType;
  NavManager?: () => void;
  type: "user" | "manager";
  setPopupType?: Dispatch<
    SetStateAction<
      | {
          type: string;
          data: any;
        }
      | undefined
    >
  >;
};

const PqboxList = ({
  projectId,
  purpose,
  RewardList,
  userAgent,
  isManager,
  NavManager,
  Search,
  setPopupType,
  type,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tag_arr = type === "manager" ? tag_layouts : user_layouts;
  const NavWrite = useCallback(() => {
    if (userAgent === "pc") {
      navigate("/pqbox/write", {
        state: {
          projectId,
          purpose,
        },
      });
    } else {
      dispatch(
        ToastChange({
          active: true,
          content: "새로운 리워드 작성은 PC에서만 가능합니다",
        })
      );
    }
  }, [navigate, projectId, purpose, userAgent]);

  return (
    <main className="pqbox-management-page">
      <button
        className="back-btn"
        onClick={() => {
          if (type === "manager") {
            navigate("/my/open");
          } else {
            navigate(`/${purpose}/pqbox/list`);
          }
        }}
      >
        <img src="/assets/common/left-arrow.svg" alt="" />
        <div className="text">목록으로</div>
      </button>
      <div className="title-section-wrapper">
        <div className="title">
          {type === "manager" ? "PQ박스 리워드 관리" : "리워드가 도착했어요 👍"}
        </div>
        {type === "manager" && userAgent === "pc" && (
          <button className="new-reward-btn" onClick={NavWrite}>
            새로운 리워드 작성
          </button>
        )}
        {isManager && type === "user" && NavManager && userAgent === "pc" && (
          <button className="new-reward-btn" onClick={NavManager}>
            리워드 관리
          </button>
        )}
      </div>
      {type === "user" && (
        <div className="sub">*열람기간내 리워드를 확인하세요.</div>
      )}
      <PqboxSearch Search={Search} />
      {userAgent === "pc" && (
        <div
          className={`tag-section ${type !== "manager" && "user-tag-section"}`}
        >
          {tag_arr.map((item, idx) => {
            return (
              <div className="tag-content" key={idx}>
                {item}
              </div>
            );
          })}
        </div>
      )}
      {RewardList.length > 0 ? (
        <div className="pqbox-list-content">
          {RewardList.map((item, idx) => {
            if (type === "manager") {
              return (
                <PqboxManageCard
                  key={idx}
                  data={item}
                  userAgent={userAgent}
                  navigate={navigate}
                  purpose={purpose}
                  projectId={projectId}
                  setPopupType={setPopupType}
                />
              );
            }
            return (
              <PqboxUserCard
                key={idx}
                data={item}
                userAgent={userAgent}
                navigate={navigate}
                purpose={purpose}
                projectId={projectId}
              />
            );
          })}
        </div>
      ) : (
        <PqboxListEmty NavWrite={NavWrite} isManager={type === "manager"} />
      )}
      {isManager && type === "user" && NavManager && userAgent !== "pc" && (
        <button className="new-reward-btn" onClick={NavManager}>
          리워드 관리
        </button>
      )}
    </main>
  );
};

export default PqboxList;

const tag_layouts = ["리워드 제목", "열람기간", "리워드종류", "상태"];
const user_layouts = [
  "리워드 제목",
  "창작자",
  "열람기간",
  "리워드종류",
  "상태",
];
