import React, { useCallback, useState } from "react";
import "./css/index.css";
import CommonInput from "../../components/Common/CommonInput/CommonInput";
import JoinPolicySection from "../../components/Signup/JoinPolicySection";
import JoinBottom from "../../components/Signup/JoinBottom";
import { SignInterface, UserAgentType } from "../../common/common.inerface";
type Props = {
  dispatch: React.Dispatch<{
    payload: any;
    type: string;
  }>;
  userAgent: UserAgentType;
  data: SignInterface;
  CheckEmail?: (e: string) => Promise<any>;
  CheckNickname: (e: string) => Promise<any>;
  SubmitSignup: () => void;
  type: "normal" | "social";
};

const emailReg = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
export const passwordreg = new RegExp(
  "^(?=.*[A-Za-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const Signup = ({
  dispatch,
  data,
  CheckEmail,
  CheckNickname,
  userAgent,
  SubmitSignup,
  type,
}: Props) => {
  const [errorType, setErrorType] = useState<string | undefined>(undefined);
  const [timer, setTimer] = useState<NodeJS.Timeout | number>(0);

  const CommonUpdate = useCallback(
    (type: string, value: any) => {
      dispatch({
        type,
        payload: value,
      });
      if (type === "email" && emailReg.test(value) && CheckEmail) {
        clearTimeout(timer);
        setTimer(
          setTimeout(function () {
            CheckEmail(value).then(({ state }) => {
              if (!state) {
                setErrorType("usingEmail");
              } else if (errorType === "usingEmail") {
                setErrorType(undefined);
              }
            });
          }, 200)
        );
      }
      if (type === "name") {
        clearTimeout(timer);
        setTimer(
          setTimeout(function () {
            CheckNickname(value).then(({ state }) => {
              if (!state) {
                setErrorType("usingName");
              } else if (errorType === "usingName") {
                setErrorType(undefined);
              }
            });
          }, 200)
        );
      }
    },
    [dispatch, CheckEmail, CheckNickname, errorType, timer]
  );
  return (
    <main className="signup-page">
      <div className="signup-content-title">회원가입</div>
      <div className="signup-content">
        {type === "normal" && (
          <CommonInput
            category="email"
            Update={CommonUpdate}
            title="이메일"
            placeholder="이메일을 입력해주세요"
            data={data.email}
            errorType={
              data.email
                ? !emailReg.test(data.email)
                  ? "양식이 올바르지 않습니다"
                  : errorType === "usingEmail"
                  ? "이미 사용 중인 이메일입니다"
                  : undefined
                : undefined
            }
          />
        )}
        <CommonInput
          category="name"
          Update={CommonUpdate}
          title="닉네임"
          placeholder="활동에 사용할 닉네임을 입력해주세요"
          data={data.name}
          errorType={
            errorType === "usingName"
              ? "이미 사용 중인 닉네임입니다"
              : undefined
          }
        />

        {type === "normal" && (
          <div className="signup-password-wrapper">
            <CommonInput
              type="password"
              category="password"
              Update={CommonUpdate}
              title="비밀번호"
              placeholder="영문 / 특수문자 / 숫자 포함 8자리 이상"
              data={data.password}
              errorType={
                data.password && !passwordreg.test(data.password)
                  ? "영문 / 특수문자 / 숫자 포함 8자리 이상"
                  : data.passwordcheck && data.password !== data.passwordcheck
                  ? "비밀번호 확인이 일치하지 않습니다"
                  : undefined
              }
              clearText={
                data.password &&
                passwordreg.test(data.password) &&
                data.passwordcheck &&
                data.password === data.passwordcheck
                  ? "사용 가능한 비밀번호입니다"
                  : undefined
              }
            />
            <CommonInput
              type="password"
              category="passwordcheck"
              Update={CommonUpdate}
              title=""
              placeholder="비밀번호를 재입력해주세요"
              data={data.passwordcheck}
              errorType={
                data.password && !passwordreg.test(data.password)
                  ? ""
                  : data.passwordcheck && data.password !== data.passwordcheck
                  ? ""
                  : undefined
              }
            />
          </div>
        )}
        <JoinPolicySection
          data={data.policy}
          dispatch={dispatch}
          userAgent={userAgent}
        />
        <JoinBottom
          data={data}
          errorType={errorType}
          SubmitSignup={SubmitSignup}
        />
      </div>
    </main>
  );
};

export default Signup;
