import { Dispatch, SetStateAction, useState } from "react";
import CategoryFilter from "../../components/Catagory/CategoryFilter";
import { ProjectType, PurposeType, StoryType } from "../../interface/interface";
import "./css/index.css";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import StoryCard from "../../components/Story/StoryCard/StoryCard";
import EmtyList from "../../components/EmtyList/EmtyList";
import { authStoryType } from "../../reducers/auth";
import { UserAgentType } from "../../common/common.inerface";
import ProjectTop, {
  rewardCategory,
  sortArr,
  stateCategory,
} from "../../components/ProjectTop/ProjectTop";
import ProjectCardSk from "../../components/ProjectCard/ProjectCardSk";
import LoadingStoryCard from "../../components/Story/StoryCard/LoadingStoryCard";
type Props = {
  projectData?: ProjectType[];
  storyData?: StoryType[];
  setNowCategory: Dispatch<SetStateAction<number>>;
  nowCategory: number;
  userAgent: UserAgentType;
  ChangeLike: (key: string) => void;
  user_story?: authStoryType;
  pqPage: number;
  projectPage: number;
  setStoryPage: Dispatch<SetStateAction<number>>;
  setProjectPage: Dispatch<SetStateAction<number>>;
  purpose: PurposeType;
  scrapList?: { key: string; purpose: PurposeType }[];
  ScrapProject: (key: string, purpose: PurposeType) => void;
  RewardFilter: undefined | "funding" | "finish";
  Sort: number;
  setSort: Dispatch<SetStateAction<number>>;
  isPq: boolean;
  setIsPq: Dispatch<SetStateAction<boolean>>;
  SearchInputData: (text: string) => void;
  setRewardFilter: Dispatch<SetStateAction<undefined | "funding" | "finish">>;
  searchtext: string;
};
const Search = ({
  projectData,
  setNowCategory,
  nowCategory,
  userAgent,
  ChangeLike,
  user_story,
  pqPage,
  setStoryPage,
  scrapList,
  purpose,
  RewardFilter,
  Sort,
  setSort,
  setRewardFilter,
  ScrapProject,
  isPq,
  storyData,
  setIsPq,
  SearchInputData,
  setProjectPage,
  projectPage,
  searchtext,
}: Props) => {
  const filterArr = [
    {
      nowData: Sort,
      list: sortArr,
      Update: setSort,
    },
    {
       nowData: RewardFilter? stateCategory.findIndex((item) => item.type === RewardFilter) : 0,
      list: stateCategory,
      Update: setRewardFilter,
    },
  ];
  const [value, setValue] = useState<string>(searchtext);
  return (
    <main className="search-page">
      <div className="search-section">
        <div className="search-wrapper">
          <div className="search-title-container">
            <p className="title-text keyboard-title">{searchtext}</p>
            <p className="title-text search-title">검색결과</p>
          </div>
          <form
            className="search-container"
            onSubmit={(e) => {
              e.preventDefault();
              SearchInputData(value);
            }}
          >
            {/* 검색 */}
            <input
              type="text"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              className="search-input"
            />
            <button className="search-button">
              <img src="/assets/home/search-btn.svg" alt="검색" />
            </button>
          </form>
        </div>

        <div className="category-container">
          <CategoryFilter
            categories={AllSearchListCategory}
            nowCategory={nowCategory}
            onCategoryChange={setNowCategory}
            purpose="projects"
          />
          {/* <ProjectTop
            userAgent={userAgent}
            type="project"
            title={purpose === "projects" ? "" : ""}
            list={filterArr}
            isPq={isPq}
            setIsPq={setIsPq}
          /> */}
        </div>
        {/* project 검색 부분 */}
        <div className="ordermade-section">
          <div className={`project-page`}>
            {projectData && projectData.map((item, idx) => (
              <ProjectCard
                data={item}
                key={idx}
                type={item.purpose === "ordermade" ? "order" : "funding"}
                ScrapProject={ScrapProject}
                isScrap={
                  scrapList &&
                  scrapList.filter(({ key }) => key === item.key).length > 0
                }
              />
            ))}
            {!projectData && Array(6).fill(0).map((item,idx)=>{
              return <ProjectCardSk key={idx}/>
            })}
          </div>
          {projectData && projectData.length === 0 && (
            <div className="search-emty">
              <EmtyList title="검색 결과가 없습니다" sub={``} />
            </div>
          )}
        </div>
        <div className="button-container">
          {projectData && projectData.length >= 6 * (projectPage + 1) && (
            <button
              className={`view-button`}
              onClick={() => {
                setProjectPage((prev) => prev + 1);
              }}
            >
              더보기 +
            </button>
          )}
        </div>
      </div>
      <div className="line" />

      {/* story 검색 부분 */}
      <div className="pq-section-wrapper">
        <div className="pq-section">
          <div className="card-title">PQ 스토리</div>

          {storyData && storyData.length > 0 && (
            <div className="story-list-wrapper">
              {storyData.map((item, idx) => {
                return (
                  <StoryCard
                    key={idx}
                    data={item}
                    ChangeLike={ChangeLike}
                    isLike={user_story && user_story[item.key] ? true : false}
                  />
                );
              })}
            </div>
          )}
                     {!storyData && <div className="story-list-wrapper">
                      {Array(3).fill(0).map((item,idx)=>{
                        return <LoadingStoryCard key={idx}/>
                      })}
                     </div>
            }

          {storyData && storyData.length === 0 && (
            <div className="search-emty">
              <EmtyList title="검색 결과가 없습니다" sub={``} />
            </div>
          )}
          <div className="button-container">
            {storyData && storyData.length >= 6 * (pqPage + 1) && (
              <button
                className={`view-button`}
                onClick={() => {
                  setStoryPage((prev) => prev + 1);
                }}
              >
                더보기 +
              </button>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};
export default Search;
export const AllSearchListCategory = ["전체", "오더메이드", "PQ 펀딩"];
