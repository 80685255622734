export const SignInitial = () => {
  return {
    email: "",
    name: "",
    password: "",
    passwordcheck: "",
    policy: [true, true, true],
  };
};

export const InfoIntial = () => {
  return {
    email: "",
    image: "",
    nickname: "",
    quote: "",
  };
};

export const PaymentIntial = () => {
  return {
    profile: {
      name: "",
      tel: "",
    },
    address: {
      basic: "",
      detail: "",
      zonecode: "",
    },
    etc: "",
    donation: 0,
    files: [],
  };
};

export const RewardIntial = () => {
  return {
    context: "",
    count: "",
    delivery: "",
    productCost: "",
  };
};
export const ProjectInitial = () => {
  return {
    amount: {
      goal: 0,
      now: 0,
    },
    config: {
      deliveryType: "PQ",
      projectType: "PT10",
    },
    editor: "",
    images: {
      main: "",
      thumbnail: "",
    },
    subImages: ["", "", "", "", "", ""],
    period: {
      finish: "",
      start: "",
    },
    refund: "",
    rejectMessage: "",
    rewards: {
      content: [],
      period: "",
      type: [{ title: "굿즈" }],
    },
    title: "",
    key: "",
  };
};

export const PqboxInitial = () => {
  return {
    title: "",
    category: 0,
    period: {
      finish: "",
      start: "",
    },
    images: {
      thumbnail: "",
    },
    editor: "",
    key: "",
  };
};

export const StoryInitial = () => {
  return {
    title: "", //제목
    content: "", // 내용
    category: 0, // 카테코리
    images: [undefined, undefined, undefined, undefined, undefined], //이미지
  };
};
