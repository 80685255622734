import "./css/index.css";
import { ReactComponent as GreyArrow } from "../../../svg/greyarrow.svg";
import { useState } from "react";
type Props = {
  img: string[];
};
const ImgBanner = ({ img }: Props) => {
  const [isSelectedImg, setIsSelectedImg] = useState<number>(0);
  const SelectedImg = (idx: number) => {
    if (idx >= 0 && idx < img.length) {
      setIsSelectedImg(idx);
    } else {
      if (idx >= img.length) {
        setIsSelectedImg(0);
      } else {
        setIsSelectedImg(img.length - 1);
      }
    }
  };
  return (
    <>
      <div className="detail-img-container">
        <img src={img[isSelectedImg]} alt="" />
      </div>
      {/* 슬라이드 이미지 */}
      <div className="slide-img-container">
        {img.map((item, idx) => (
          <button
            onClick={() => SelectedImg(idx)}
            key={idx}
            className="slide-wide-container"
          >
            <img src={item} alt="" />
          </button>
        ))}
      </div>
      {/* 슬라이드 */}
      <div className="slide-btn-container">
        <div>
          {isSelectedImg + 1} / {img.length}
        </div>
        <div className="btn">
          <button onClick={() => SelectedImg(isSelectedImg - 1)}>
            <GreyArrow className="left-btn" />
          </button>
          <button onClick={() => SelectedImg(isSelectedImg + 1)}>
            <GreyArrow />
          </button>
        </div>
      </div>
    </>
  );
};
export default ImgBanner;
