import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import "./css/index.css";
import { PaymentType, PurposeType } from "../../interface/interface";
import { telform } from "../../lib/lib";
type Props = {
  data: PaymentType;
  type: string;
  dispatch: React.Dispatch<{
    payload: any;
    type: string;
  }>;
};

const AdressInput = ({ type, dispatch, data }: Props) => {
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const __openAddress = useCallback(() => {
    setIsSearch(true);
    const daum = (window as any).daum;
    new daum.Postcode({
      oncomplete: function (data: any) {
        setIsSearch(false);
        dispatch({
          type: "basic",
          payload: data.roadAddress,
        });
        dispatch({
          type: "zonecode",
          payload: data.zonecode,
        });
      },
      width: "100%",
      minHeight: "100%",
    }).embed(document.getElementById("postcode"));
  }, [dispatch]);

  return (
    <div className="input-section">
      <div className="title">
        배송정보 <span>(필수)</span>
      </div>

      <div className="input-main">
        <div className="search-wrapper">
          <div className="input-search">
            <div className="main-title">주소</div>
            <input
              type={type}
              placeholder="주소 검색"
              className="adress-main"
              value={data.address.basic}
              readOnly
              onClick={__openAddress}
            />
          </div>

          <div className="input-search">
            <input
              type={type}
              placeholder="우편번호"
              className="adress-number"
              value={data.address.zonecode}
              readOnly
            />
          </div>

          <img src="/assets/common/search.svg" alt="" />
        </div>

        <div className="input-search">
          <div className="main-title">상세주소</div>
          <input
            className="adress-sub"
            placeholder="상세주소 입력"
            value={data.address.detail}
            onChange={(e) => {
              dispatch({
                type: "detail",
                payload: e.target.value,
              });
            }}
          />
        </div>
        <div className="input-search">
          <div className="main-title">이름</div>
          <input
            className="adress-sub"
            placeholder="이름을 입력해주세요"
            value={data.profile.name}
            onChange={(e) => {
              dispatch({
                type: "name",
                payload: e.target.value,
              });
            }}
          />
        </div>
        <div className="input-search">
          <div className="main-title">받는사람 연락처</div>
          <input
            type="text"
            className="adress-sub"
            placeholder="숫자만 입력"
            value={data.profile.tel}
            onChange={(e) => {
              dispatch({
                type: "tel",
                payload: telform(e.target.value),
              });
            }}
          />
        </div>
        <div className="input-search">
          <div className="main-title">
            요청사항{" "}
            <span>(창작자에게 요청하고 싶은 것이 있다면 적어주세요.)</span>
          </div>
          <input
            type="text"
            className="adress-sub"
            placeholder="내용 입력"
            value={data.etc}
            onChange={(e) => {
              dispatch({
                type: "etc",
                payload: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={`post-wrapper ${isSearch ? "post-active" : ""}`}>
        <div
          className="back"
          onClick={() => {
            setIsSearch(false);
          }}
        />
        <div className="post-section">
          <img
            src="/assets/common/cancel.svg"
            alt=""
            onClick={() => {
              setIsSearch(false);
            }}
          />
          <div id="postcode"></div>
        </div>
      </div>
    </div>
  );
};

export default AdressInput;
