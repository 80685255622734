import React, { ChangeEvent, useCallback } from "react";
import "./css/index.css";
import ImageBox from "../../components/Story/ImageBox";
import CategoryBox from "../../components/Story/CategoryBox";
import { StoryWriteType } from "../../interface/interface";
type Props = {
  data: StoryWriteType;
  UpdateData: (type: string, value: string | number, index?: number) => void;
  SubmitStory: () => void;
  type: "write" | "fix";
};

const StoryWrite = ({ data, UpdateData, SubmitStory, type }: Props) => {
  const { category, title, content, images } = data;
  const TitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    UpdateData("title", value);
  };
  const DescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    UpdateData("content", value);
  };
  const isCheck = useCallback(() => {
    if (
      title.length > 0 &&
      content.length > 0 &&
      images.filter((item) => item).length > 0
    ) {
      return true;
    }
    return false;
  }, [data]);
  return (
    <main className="story-write-page">
      <h1 className="page-title">
        스토리 {type === "write" ? "작성" : "수정"}
      </h1>
      <div className="category-container">
        <div className="sub-title">카테고리</div>
        <div className="category-menu-container">
          <CategoryBox
            category={category_layouts}
            nowCategory={category}
            UpdateData={UpdateData}
          />
        </div>
      </div>
      <div className="form-container">
        <div className="input-container">
          <div className="sub-title">제목</div>
          <input
            type="text"
            placeholder="제목"
            className="title-input"
            value={title}
            onChange={TitleChange}
          />
        </div>
        <div className="input-container">
          <div className="sub-title">내용</div>
          <textarea
            value={content}
            onChange={DescriptionChange}
            placeholder="내용 입력"
            className="description-input"
          />
        </div>
        <div className="image-container">
          <div className="sub-title">첨부 이미지</div>
          <div className="explanation">
            <span>· jpg, png만 등록 가능 / 5mb 이하 / 최대 5개 등록 가능</span>
            <span>· 첫번째 이미지가 썸네일로 지정됩니다.</span>
            <span>· 정사각형 사진 업로드를 권장합니다.</span>
          </div>
          <div className="image-upload">
            <div className="wide-container">
              {images.map((item, idx) => {
                return (
                  <ImageBox
                    item={item}
                    key={idx}
                    idx={idx}
                    UpdateData={UpdateData}
                    dataKey={data.key}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <button
          disabled={!isCheck()}
          onClick={SubmitStory}
          className={`submit-button ${!isCheck() && "disabled-button"}`}
        >
          스토리 {type === "write" ? "작성" : "수정"}
        </button>
      </div>
    </main>
  );
};
export default StoryWrite;

const category_layouts = ["급구", "판매", "후기", "수요조사", "홍보", "기타"];
