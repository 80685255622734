import React, { Dispatch, SetStateAction, useState } from "react";
import "../css/index.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import { AuthProfileType } from "../../../../reducers/auth";
import { CommentInter } from "../../../../interface/interface";

type Props = {
  ReplyComment: (text: string, data: CommentInter) => void;
  data: CommentInter;
  setIsReply: Dispatch<SetStateAction<string | undefined>>;
};

const ReplyCard = ({ ReplyComment, data }: Props) => {
  const profile = useSelector<RootState, AuthProfileType>(
    (state) => state.auth.profile
  );
  const [replyText, setReplyText] = useState<string>("");
  return (
    <div className="comment-card reply-card">
      <img
        src="/assets/comment/reply-arrow.svg"
        alt=""
        className="reply-arrow"
      />
      <div className="comment-top-section">
        <div className="profile-section">
          <div className="circle">
            <img
              src={
                profile.image
                  ? profile.image
                  : "https://firebasestorage.googleapis.com/v0/b/projectquestion-2d800.appspot.com/o/config%2Fplaceholder%2Fbasic_profile.svg?alt=media&token=c285b7c5-5f49-4264-b1e8-40ff46ac731c"
              }
              alt=""
            />
          </div>
          <div className="author">{profile.nickname}</div>
        </div>
      </div>
      <div className="reply-input-wrapper">
        <textarea
          value={replyText}
          onChange={(e) => {
            setReplyText(e.target.value);
          }}
          className="fix-input"
        />
        <div className="comment-write-bottom">
          <button
            className="submit-btn"
            onClick={() => {
              if (replyText.length > 0) {
                ReplyComment(replyText, data);
              }
            }}
          >
            등록
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReplyCard;
