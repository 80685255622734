import React, { useState } from "react";
import "./css/index.css";
type Props = {
  length: number;
  SubmitComment: (value: string, isScret?: boolean) => Promise<boolean>;
  type: "notice" | "reward" | "story";
};

const CommentWrite = ({ length, SubmitComment, type }: Props) => {
  const [isSceret, setisSceret] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  return (
    <section className="comment-write-section">
      <div className="title">
        댓글 <span>{length}</span>
      </div>
      <textarea
        placeholder="내용을 입력해주세요"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      ></textarea>
      <div className="comment-write-bottom">
        {" "}
        <button
          className="secret-btn"
          onClick={() => {
            setisSceret((prev) => !prev);
          }}
        >
          <div className="circle">
            {isSceret && (
              <img src="/assets/common/black-check.svg" alt="check" />
            )}
          </div>
          <div className="secret-btn-title">
            {type === "reward" ? "창작자" : "작성자"}만 볼 수 있도록 합니다
          </div>
        </button>
        <button
          className="submit-btn"
          onClick={() => {
            if (value.length > 0) {
              SubmitComment(value, isSceret).then((res)=>{
                if (res) {
                  setValue("")
                  setisSceret(false)
                }
              });
            }
          }}
        >
          등록
        </button>
      </div>
    </section>
  );
};

export default CommentWrite;
