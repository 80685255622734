export const cstid =
  process.env.REACT_APP_SITE === "production" ? "projectq" : "test";
export const custKey =
  process.env.REACT_APP_SITE === "production"
    ? "ab37ced82532a8afa602c2675c835505c0de2e320b02ff4ee9864b07d49cd0cc"
    : "abcd1234567890";
export const paymentUrl =
  process.env.REACT_APP_SITE === "production"
    ? "https://cpay.payple.kr/php/auth.php"
    : "https://democpay.payple.kr/php/auth.php";
export const reffor = "https://projectquestion.com/";
//https://projectquestion.com/
//http://localhost:3000/

//  운영(리얼)환경

// -      운영 URL : https://cpay.payple.kr/php/auth.php

// -      Cst_id : projectq

// -      custKey : ab37ced82532a8afa602c2675c835505c0de2e320b02ff4ee9864b07d49cd0cc

// -      취소(환불) key : d021379615926d6cfdbf7242dcb4ce05d98ff67b2bec070b5ca0a38f21c81787

// 2.    테스트 환경

// -      운영 URL : https://democpay.payple.kr/php/auth.php

// -      Cst_id : test

// -      custKey : abcd1234567890

// -      취소(환불) key : a41ce010ede9fcbfb3be86b24858806596a9db68b79d138b147c3e563e1829a0
