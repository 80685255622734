import NoticeTop from "./components/NoticeTop";
import NoticeBody from "./components/NoticeBody";
import "./css/index.css";
import { NoticeInterface, UserAgentType } from "../../common/common.inerface";

type Props = {
  data?: NoticeInterface[];
  userAgent: UserAgentType;
  SearchData: (filter: any) => void;
  GetNotice?: (id?: string) => void;
  isStop: boolean;
};

const Notice = ({ data, userAgent, SearchData, GetNotice, isStop }: Props) => {
  return (
    <main className="notice">
      <div className="wrapper">
        <NoticeTop SearchData={SearchData} userAgent={userAgent} />
        <NoticeBody
          data={data}
          userAgent={userAgent}
          type="notice"
          GetNotice={GetNotice}
          isStop={isStop}
        />
      </div>
    </main>
  );
};

export default Notice;
