import React, { useCallback, useEffect, useState } from "react";
import { ProjectType, PurposeType } from "../interface/interface";
import { GetProjectList } from "../api/project_api";

type Props = {
  purpose: PurposeType;
  prjtState?: "ready";
};

const UseList = ({ purpose, prjtState }: Props) => {
  const [data, setData] = useState<ProjectType[] | undefined>(undefined);
  const [page, setPage] = useState<number>(0);
  const [RewardFilter, setRewardFilter] = useState<undefined | "PT10" | "PT01">(
    undefined
  );
  const [category, setCategory] = useState<number>(0);
  const [isPq, setIsPq] = useState<boolean>(false);
  const [Sort, setSort] = useState<number>(0);

  const GetProject = useCallback(
    async (params?: any) => {
      try {
        const { state, data } = await GetProjectList({
          purpose,
          number: 9,
          page: params?.page || page,
          reward: params?.RewardFilter,
          isPq: params?.isPq,
          sort: params?.sort,
          state: prjtState,
          category: params?.category,
        });
        if (state) {
          if (page === 0) {
            setData(data);
          } else {
            setData((prevData) =>  prevData ? [...prevData, ...data] : data);
          }
        }
      } catch (error) {
        console.log("에러다", error);
      }
    },
    [page, prjtState]
  );

  useEffect(() => {
    setPage(0);
  setData(undefined)
    const obj = {
      isPq: isPq ? isPq : undefined,
      sort: Sort ? Sort : undefined,
      category: category ? ListCategory[category] : undefined,
      RewardFilter,
    };
    GetProject(obj);

    return () => {};
  }, [isPq, Sort, RewardFilter, category]);

  useEffect(() => {
    GetProject();
    return () => {};
  }, [GetProject]);

  return {
    GetProject,
    data,
    page,
    setPage,
    setRewardFilter,
    RewardFilter,
    isPq,
    setIsPq,
    Sort,
    setSort,
    category,
    setCategory,
  };
};

export default UseList;

export const ListCategory = [
  "전체",
  "굿즈",
  "행사",
  "아트",
  "콘텐츠",
  "캠페인",
  "기타",
];
