import axios from "axios";

export const clientApi = axios.create({
  baseURL:
    process.env.REACT_APP_STATE === "test" ? "" : process.env.REACT_APP_BASEURL,
});

// clientApi.interceptors.request.use((config) => {
//   // const token = window.localStorage.getItem("accessToken");
//   // if (token) {
//   //   const accessToken = JSON.parse(token).token;
//   //   config.headers["Authorization"] = accessToken;
//   // }
//   config.headers["Access-Control-Allow-Origin"] = "*";
//    config.headers["Access-Control-Allow-Origin"] = "*";
//   return config;
// });

export const NetworkError = {
  state: false,
  data: undefined,
  msg: "네트워크 연결을 확인해주세요",
};
