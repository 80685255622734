import React from "react";
import "./css/index.css";
import CommonInput from "../../../Common/CommonInput/CommonInput";
import CommonFile from "../../../Common/CommonInput/CommonFile";
import ThumbnailLook from "../../../WriteProject/BasicInfo/components/ThumbnailLook";
import { BasicLayoutsType, PqboxType } from "../../../../interface/interface";

type Props = {
  title: string;
  sub?: string;
  type: "basic" | "image";
  layouts: BasicLayoutsType[];
  Update: (type: string, payload: any, idx?: number) => void;
  data: PqboxType;
};

const PqboxBasic = ({ title, type, layouts, Update, data, sub }: Props) => {
  return (
    <section className="pqboxbasic-info-section">
      <div className="title">{title}</div>
      {sub && <div className="sub">{sub}</div>}
      <div className="info-section-content">
        <div className={`info-section-left ${type}-left`}>
          {layouts.map(
            (
              { title, type, placeholder, value, patchType, subTitle, error },
              idx
            ) => {
              if (type === "input") {
                return (
                  <CommonInput
                    title={title}
                    placeholder={placeholder}
                    data={value}
                    clearText={subTitle}
                    category={patchType}
                    Update={Update}
                    key={`${type}-${patchType}-${idx}`}
                    errorType={error}
                  />
                );
              }
              if (type === "file") {
                return (
                  <CommonFile
                    title={title}
                    placeholder={placeholder}
                    type={patchType}
                    value={value}
                    Update={Update}
                    idx={idx}
                    key={`${type}-${patchType}-${idx}`}
                    path={`/pqbox/${data.key}`}
                    error={error}
                  />
                );
              }
              return <div key={idx}>없음</div>;
            }
          )}
        </div>
        <div className="info-section-right">
          <ThumbnailLook data={data} type="pqbox" />
        </div>
      </div>
    </section>
  );
};

export default PqboxBasic;
