import { SignInterface } from "../common/common.inerface";
import {
  PaymentType,
  PqboxType,
  ProjectType,
  RewardType,
  StoryWriteType,
  UserType,
} from "../interface/interface";
import { AuthProfileType } from "../reducers/auth";
import { RewardIntial } from "./initial";

export interface UserAddInter extends UserType {
  content: string;
}

export function SignReducer(
  state: SignInterface,
  { payload, type, index }: { payload: any; type: string; index?: number }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "email":
      return { ...state, email: payload };
    case "name":
      return { ...state, name: payload };
    case "password":
      return { ...state, password: payload };
    case "passwordcheck":
      return { ...state, passwordcheck: payload };
    case "policy":
      return { ...state, policy: payload };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

export function InfoReducer(
  state: AuthProfileType,
  { payload, type }: { payload: any; type: string }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "email":
      return { ...state, email: payload };
    case "nickname":
      return { ...state, nickname: payload };
    case "quote":
      return { ...state, quote: payload };
    case "image":
      return { ...state, image: payload };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

export function ProjetcReducer(
  state: ProjectType,
  { payload, type, idx }: { payload: any; type: string; idx?: number }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "key":
      return { ...state, key: payload };
    case "title":
      return { ...state, title: payload };
    case "refund":
      return { ...state, refund: payload };
    case "editor":
      return { ...state, editor: payload };
    case "goal":
      return {
        ...state,
        amount: {
          ...state.amount,
          goal: payload,
        },
      };
    case "thumbnail":
      return {
        ...state,
        images: {
          ...state.images,
          thumbnail: payload,
        },
      };
    case "image":
      return { ...state, image: payload };
    case "subimage":
      let arr = state.subImages;
      if (typeof idx === "number") {
        arr[idx] = payload;
      }
      return { ...state, subImages: arr };
    case "period/start":
      return {
        ...state,
        period: {
          ...state.period,
          start: payload,
        },
      };
    case "period/finish":
      return {
        ...state,
        period: {
          ...state.period,
          finish: payload,
        },
      };
    case "rewards/period":
      return {
        ...state,
        rewards: {
          ...state.rewards,
          period: payload,
        },
      };
    case "rewards/category":
      let catArr = state.rewards.type;
      catArr[0].title = payload;
      return {
        ...state,
        rewards: {
          ...state.rewards,
          type: catArr,
        },
      };
    case "rewards/content":
      return {
        ...state,
        rewards: {
          ...state.rewards,
          content: payload,
        },
      };
    case "rewards/category/value":
      let etcArr = state.rewards.type;
      etcArr[0].value = payload;
      return {
        ...state,
        rewards: {
          ...state.rewards,
          type: etcArr,
        },
      };
    case "prjt/type":
      return {
        ...state,
        config: {
          ...state.config,
          projectType: payload,
        },
      };
    case "delivery/type":
      return {
        ...state,
        config: {
          ...state.config,
          deliveryType: payload,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

export function RewardReducer(
  state: RewardType,
  { payload, type }: { payload: any; type: string }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "context":
      return { ...state, context: payload };
    case "count":
      return { ...state, count: payload };
    case "delivery":
      return { ...state, delivery: payload };
    case "productCost":
      return { ...state, productCost: payload };
    case "reset":
      return {
        context: "",
        count: "",
        delivery: "",
        productCost: "",
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

export function PaymentReducer(
  state: PaymentType,
  { payload, type }: { payload: any; type: string }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "donation":
      return {
        ...state,
        donation: payload,
      };
    case "files":
      return {
        ...state,
        files: payload,
      };
    case "etc":
      return {
        ...state,
        etc: payload,
      };

    case "name":
      return {
        ...state,
        profile: {
          ...state.profile,
          name: payload,
        },
      };

    case "tel":
      return {
        ...state,
        profile: {
          ...state.profile,
          tel: payload,
        },
      };

    case "basic":
      return {
        ...state,
        address: {
          ...state.address,
          basic: payload,
        },
      };
    case "detail":
      return {
        ...state,
        address: {
          ...state.address,
          detail: payload,
        },
      };

    case "zonecode":
      return {
        ...state,
        address: {
          ...state.address,
          zonecode: payload,
        },
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

export function PqboxReducer(
  state: PqboxType,
  { payload, type }: { payload: any; type: string }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "title":
      return { ...state, title: payload };
    case "key":
      return { ...state, key: payload };
    case "category":
      return { ...state, category: payload };
    case "start":
      return {
        ...state,
        period: {
          ...state.period,
          start: payload,
        },
      };
    case "finish":
      return {
        ...state,
        period: {
          ...state.period,
          finish: payload,
        },
      };
    case "thumbnail":
      return {
        ...state,
        images: {
          ...state.images,
          thumbnail: payload,
        },
      };
    case "editor":
      return { ...state, editor: payload };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

export function StoryReducer(
  state: StoryWriteType,
  { payload, type, index }: { payload: any; type: string; index?: number }
) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "key":
      return { ...state, key: payload };
    case "category":
      return { ...state, category: payload };
    case "title":
      return { ...state, title: payload };
    case "content":
      return { ...state, content: payload };
    case "images":
      const arr = state.images;
      if (typeof index === "number") {
        arr[index] = payload;
      }
      return { ...state, images: arr };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}
