import React, { useCallback, useEffect, useState } from "react";
import "./css/index.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { LineAddType } from "../../../reducers/data";
import { clientApi } from "../../../api/config";

export var reg = new RegExp(
  /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi
);

function LineAdd() {
  const AddData = useSelector<RootState, LineAddType[]>(
    (state) => state.data.banner.line
  );
  const [now, setNow] = useState(0);

  const ClickLineBanner = useCallback(async (idx: number) => {
    clientApi.put("line/click", {
      idx,
    });
  }, []);

  useEffect(() => {
    const swipe = setTimeout(() => {
      setNow((prev) => (prev >= AddData.length - 1 ? 0 : prev + 1));
    }, 2800);
    return () => {
      clearTimeout(swipe);
    };
  }, [AddData, now]);
  return (
    <div
      className={`line-add `}
      style={
        AddData.length > 0 ? { background: AddData[now].bg_color } : undefined
      }
    >
      <div className="wrapper">
        <div
          className="banner-wrapper"
          style={{
            gridTemplateRows: `repeat(${AddData.length},100%)`,
            transform: `translateY(-${now * 100}%)`,
          }}
        >
          {AddData.map(
            (
              {
                ctts_tit,
                ctts_ctts,
                ctts_link,
                btn_label,
                ad_thumbnail,
                bg_color,
              },
              idx
            ) => {
              return (
                <div className="line-banner" key={idx}>
                  <div className="left">
                    <div className="title">{ctts_tit}</div>
                    <div className="sub">{ctts_ctts}</div>
                    <a
                      href={ctts_link}
                      target={"_blank"}
                      rel="noreferrer"
                      onClick={() => {
                        ClickLineBanner(idx);
                      }}
                    >
                      {btn_label}
                    </a>
                  </div>
                  <img src={ad_thumbnail} alt="" />
                </div>
              );
            }
          )}
        </div>
        <div className="index-wrapper">
          {AddData.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`circle ${now === idx ? "cl-active" : ""}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LineAdd;
