import React from "react";
import "./css/index.css";
import { useNavigate } from "react-router-dom";

const ProjectOpenSection = () => {
  const navigate = useNavigate();
  return (
    <section className="projectOpen-section">
      <div className="section">
        <div className="title">아이디어보다 중요한 건, 오퍼레이션!</div>
        <div className="text">
          아이디어, 혼자만 생각하고 계신가요? <br />
          아이디어가 있다면 프로젝트퀘스천에서 오퍼레이션 해보세요.
          <br />
          프로젝트퀘스천은 당신의 새로운 프로젝트를 기다리고 있습니다.
          <br />
        </div>
        <a
          className="guid-btn"
          href="https://drive.google.com/file/d/1qKxJxhzaYQvo_gHMxSFfyc7N_862GeSR/view?usp=sharing"
          target={"_blank"}
          rel="noopener noreferrer"
        >
          <div className="text">PQ 가이드</div>
          <img src="/assets/common/whitearrow.svg" alt="" />
        </a>
        <button
          className="open-btn"
          onClick={() => {
            navigate("/write/select");
          }}
        >
          <div className="text">PQ 프로젝트 오픈하기</div>
          <img src="/assets/common/blackarrow.svg" alt="" />
        </button>
        <div className="bulb">
          <img src="/assets/main/open-bulb.svg" alt="" />
        </div>
        <div className="lettering">
          <img src="/assets/main/lettering.svg" alt="" />
        </div>
      </div>
    </section>
  );
};

export default ProjectOpenSection;
