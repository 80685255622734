import React from "react";
import Ftsize from "./Ftsize";
import Ftstyle from "./Ftstyle";
import Ftalign from "./Ftalign";
import Insert from "./Insert";
import { AppDispatch } from "../../..";
import { PurposeType } from "../../../interface/interface";

type Props = {
  setIsUp: React.Dispatch<
    React.SetStateAction<{
      status: boolean;
      type: string;
    }>
  >;
  dispatch: AppDispatch;
  dataKey: string;
    purpose:PurposeType |"rewards";
};

/** 에디터 헤더 */
function EdiHeader({ setIsUp, dataKey, dispatch,purpose }: Props) {
  return (
    <div className="edit-header">
      <Ftsize />
      <div className="line" />
      <Ftstyle />
      <div className="line" />
      <Ftalign />
      <div className="line" />
      <Insert setIsUp={setIsUp} dispatch={dispatch} dataKey={dataKey} purpose={purpose}/>
    </div>
  );
}

export default EdiHeader;
