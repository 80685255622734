import React from "react";
import "./css/index.css";
import { PurposeType, RewardType } from "../../interface/interface";
import { makeComma } from "../../lib/lib";
type Props = {
  data: RewardType;
  type: "write" | "detail";
  RemoveCard?: (idx: number) => void;
  index: number;
  RewardData?: RewardType;
  ChangeRewardData?: (data: RewardType) => void;
  ChangeCount?: (index: number, count: number) => void;
  isNot?: boolean;
  purpose: PurposeType;
  ArrowRewards?: (idx: number, type: "up" | "down") => void;
  isLast?: boolean;
  SelectFixReward?: (index: number) => void;
};

const RewardCard = ({
  data: { count, context, productCost, delivery, payment },
  type,
  RemoveCard,
  index,
  RewardData,
  ChangeRewardData,
  ChangeCount,
  isNot,
  purpose,
  ArrowRewards,
  isLast,
  SelectFixReward,
}: Props) => {
  // 남은 수량
  const numCount =
    typeof count === "number" && Number(count) > 0
      ? payment
        ? count - Object.values(payment).length
        : count
      : 1;
  return (
    <div
      className={`reward-card ${type}-reward-card ${
        RewardData && "select-reward-card"
      }`}
    >
      {type === "detail" && !isNot && numCount > 0 && (
        <button
          className="check-circle"
          onClick={() => {
            if (ChangeRewardData) {
              ChangeRewardData({
                count: 1,
                context,
                productCost,
                delivery,
                index,
              });
            }
          }}
        >
          {RewardData && (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="10"
                cy="10"
                r="10"
                fill={purpose === "projects" ? "var(--main)" : "var(--red600)"}
              />
              <path
                d="M5 10.1136L9.21053 13.75L15 7.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          )}
        </button>
      )}
      {type === "write" && RemoveCard && ArrowRewards && (
        <>
          <div className="reward-arrow-section">
            {!isLast && (
              <button
                className="reward-change-arrow"
                onClick={() => {
                  ArrowRewards(index, "down");
                }}
              >
                <img src="/assets/project/write/reward-arrow.svg" alt="변환" />
              </button>
            )}
            {index > 0 && (
              <button
                className="reward-change-arrow up-arrow"
                onClick={() => {
                  ArrowRewards(index, "up");
                }}
              >
                <img src="/assets/project/write/reward-arrow.svg" alt="변환" />
              </button>
            )}
          </div>
          {!payment && (
            <>
              <button
                className="fix-btn"
                onClick={() => {
                  if (SelectFixReward) {
                    SelectFixReward(index);
                  }
                }}
              >
                수정
              </button>
              <button
                className="cancel-btn"
                onClick={() => {
                  if (typeof index === "number") {
                    RemoveCard(index);
                  }
                }}
              >
                <img src="/assets/common/grey-cancel.svg" alt="삭제" />
              </button>
            </>
          )}
        </>
      )}
      <div className="price">
        {makeComma(productCost)}원
        {count ? (
          <div className={`count-box ${purpose}-count-box`}>
            {numCount}개 남음
          </div>
        ) : undefined}
      </div>
      <div className="context">{context}</div>
      <div className="reward-card-bottom">
        <div className="reward-card-line">
          <div className="reward-card-tag">배송비</div>
          <div className="reward-card-content">
            {delivery ? `${makeComma(delivery)}원` : "배송불필요"}
          </div>
        </div>
        <div className="reward-card-line">
          <div className="reward-card-tag">제한수량</div>
          <div className="reward-card-content">
            {count > 0 ? `${count}개` : "제한 없음"}
          </div>
        </div>
      </div>
      {RewardData && ChangeCount && (
        <div className="select-reward-content">
          <div className="count-section">
            <div className="tag">수량</div>
            <div className="count">
              <button
                onClick={() => {
                  if (RewardData.count > 1) {
                    ChangeCount(index, RewardData.count - 1);
                  }
                }}
              >
                <img src="/assets/detail/minus.svg" alt="빼기" />
              </button>
              <input
                type="number"
                value={RewardData.count}
                style={
                  count > 0 && numCount <= RewardData.count
                    ? {
                        borderColor:
                          purpose === "ordermade"
                            ? "var(--red600)"
                            : "var(--main)",
                      }
                    : undefined
                }
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 1) {
                    if (value > numCount) {
                      if (count > 0) {
                        ChangeCount(index, numCount);
                      } else {
                        ChangeCount(index, value);
                      }
                    } else {
                      ChangeCount(index, value);
                    }
                  } else {
                    ChangeCount(index, 1);
                  }
                }}
              />
              <button
                onClick={() => {
                  if (count < 1) {
                    ChangeCount(index, RewardData.count + 1);
                  }
                  if (RewardData.count < numCount) {
                    ChangeCount(index, RewardData.count + 1);
                  }
                }}
              >
                <img src="/assets/detail/plus.svg" alt="더하기" />
              </button>
            </div>
          </div>
          {count > 0 && numCount <= RewardData.count ? (
            <div
              className="count-text"
              style={{
                color:
                  purpose === "ordermade" ? "var(--red600)" : "var(--main)",
              }}
            >
              최대 수량입니다
            </div>
          ) : undefined}
        </div>
      )}
    </div>
  );
};

export default RewardCard;
