import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectType, PurposeType } from "../interface/interface";
import { clientApi } from "../api/config";
import { useDispatch, useSelector } from "react-redux";
import { LoadingChange, ToastChange } from "../reducers/config";
import { RootState } from "../reducers";

const UsePqBox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, purpose } = useParams() as { id: string; purpose: PurposeType };
  const uid = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const [RewardList, setRewardList] = useState<ProjectType[]>([]);
  const [isManager, setIsManager] = useState<boolean>(false)
  const GetPqbox = useCallback(() => {
    if (id && uid) {
      dispatch(LoadingChange(true))
      clientApi
        .get("/pqbox/list", {
          params: {
            id,
            purpose,
            uid,
          },
        })
        .then(({ data: { state, data, msg ,isManager} }) => {
          if (state) {
            setRewardList(data);
            setIsManager(isManager)
          } else {
            dispatch(
              ToastChange({
                active: true,
                content: msg,
              })
            );
            // navigate("/");
          }
                dispatch(LoadingChange(false))
        }).catch(()=>{
                     dispatch(LoadingChange(false))
        });
    }
  }, [id, purpose, uid]);

  const Search = useCallback(
    (value: string, category?: number) => {
      if (id && uid) {
        clientApi
          .get("/pqbox/list", {
            params: {
              id,
              purpose,
              category: category ? arr[category] : undefined,
              value,
              uid,
            },
          })
          .then(({ data: { state, data } }) => {
            if (state) {
              setRewardList(data);
            }
          });
      }
    },
    [id, purpose, uid]
  );
  const RemoveReward = useCallback(
    async (rewardId: string) => {
      const {
        data: { state },
      } = await clientApi.delete("/pqbox/remove", {
        params: {
          id,
          rewardId,
        },
      });
      if (state) {
        GetPqbox();
        dispatch(
          ToastChange({ active: true, content: "리워드를 삭제하였습니다" })
        );
      }
    },
    [id, GetPqbox]
  );
  const NavManager = useCallback(
    () => {
     navigate(`/pqbox/management/${purpose}/${id}`) 
    },
    [purpose,id,navigate],
  )
  

  useEffect(() => {
    GetPqbox();
    return () => {};
  }, [GetPqbox]);

  return { RewardList, id, purpose, RemoveReward, Search ,isManager,NavManager};
};

export default UsePqBox;

const arr = ["", "funding", "finish"];
