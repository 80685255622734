import React from 'react'
import { ProjectType } from '../../../interface/interface'

type Props = {
  data:ProjectType;
  prjtData:ProjectType;
}

const PqboxDetailTop = ({data,prjtData}: Props) => {
  return (
      <div className='pqbox-detail-top'
      style={{
        background:` linear-gradient(0deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 100%), url(${data.images.thumbnail}), lightgray 50% / cover no-repeat`
      }}
      >
      <div className='pqbox-detail-wrapper'>
        <div className='pqbox-detail-top-tag'>{prjtData.purpose ==="projects" ?"PQ펀딩":"오더메이드"}</div>
<div className='pqbox-detail-top-title'>{data.title}</div>
    <div className="pqbox-detail-author">
    <div className='profile-circle' 
    style={{backgroundImage:`url(${data.profile.image})`}}
    />
    <div className='profile-nickname'>{data.profile.author}</div>
          </div>
      </div>
      </div>
  )
}

export default PqboxDetailTop