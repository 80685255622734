import React, { useState } from "react";

type Props = {
  data: any;
};
const NoticeDetailBody = ({ data }: Props) => {
  return (
    <div className="body">
      {data.value.image && (
        <div className="img_wrapper">
          <img src={data.value.image.url} alt="" />
        </div>
      )}
      <div className="notice_content">{data.value.content}</div>
    </div>
  );
};

export default NoticeDetailBody;
