import React from "react";
import "./css/index.css"
import Skeleton from "react-loading-skeleton";
type Props = {};

const LoadingStoryCard = (props: Props) => {
  return (
    <div className="story-card">
      <div className="story-card-top">
        <div className="story-card-img">
          <Skeleton  />
        </div>
    <div className="category loading-category">
        <Skeleton  />
    </div>
        <div className="title loading-title"> <Skeleton  /></div>
        <div className="content loading-content"> <Skeleton  /></div>
        <div className="card-time loading-card-time"><Skeleton  /></div> 
      </div>
    </div>
  );
};

export default LoadingStoryCard;
