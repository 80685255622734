import React, { useCallback, useEffect, useState } from "react";
import "./css/index.css";
import { FileReaderBase64, Fileread, Resizing } from "../../../lib/lib";
import { PurposeType } from "../../../interface/interface";
import { ref, getMetadata } from "@firebase/storage";
import { Fstorage } from "../../../config/firebaseApp";
type Props = {
  title?: string;
  placeholder?: string;
  Update: (type: string, payload: any, idx?: number) => void;
  type: string;
  value: string;
  idx?: number;
  error?: string;
  path: string;
  purpose?: PurposeType;
  width?: number;
  height?: number;
  sub?:string;
};

const CommonFile = ({
  title,
  placeholder,
  Update,
  type,
  value,
  idx,
  error,
  path,
  purpose,
  width,
  height,
  sub
}: Props) => {
  const [FileName, setFileName] = useState<string>("")
  const getImageFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const pal = e.target.files[0];
        if (width && height) {
          const file = await Resizing(pal, width, height);
          const url = await FileReaderBase64(file, `${path}/${pal.name}`);
          Update(type, url, idx);
        } else {
          const file = await Fileread(pal);
          const url = await FileReaderBase64(file, `${path}/${pal.name}`);
          Update(type, url, idx);
        }
      }
    },
    [Update, type, idx, path, width, height]
  );
  useEffect(() => {
    if (value) {
      getMetadata(ref(Fstorage, value)).then((res) => {
setFileName(res.name)
      }).catch((err)=>{
console.log(err)
      });
    }

    return () => {};
  }, [value]);

  return (
    <div className={`common-input ${error !== undefined && `input-error`}`}>
      {title && <div className="common-input-title">{title}
      {sub && <span>{sub}</span>}
      </div>}
      <label
        className={`common-file-label ${value && "file-insert"}`}
        htmlFor={`common-file-label${type}-${idx}`}
        style={
          purpose === "ordermade" && value
            ? { backgroundColor: "var(--red100)" }
            : undefined
        }
      >
        <div
          className="title"
          style={
            purpose === "ordermade" && value
              ? { color: "var(--red600)" }
              : undefined
          }
        >
          {FileName ? FileName:placeholder}
        </div>
        <img src="/assets/project/write/file-upload.svg" alt="업로드" />
      </label>
      <input
        id={`common-file-label${type}-${idx}`}
        type="file"
        accept=".jpg, .png"
        size={5000000}
        style={{
          display: "none",
        }}
        onChange={getImageFile}
      />
    </div>
  );
};

export default CommonFile;
