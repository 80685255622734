import React, { useCallback, useEffect, useState } from "react";
import Notice from "../Notice";
import {
  NoticeInterface,
  UserAgentType,
} from "../../../common/common.inerface";
import { clientApi } from "../../../api/config";
type Props = {
  userAgent: UserAgentType;
};
const NoticeContainer = ({ userAgent }: Props) => {
  const [data, setData] = useState<NoticeInterface[] | undefined>(undefined);
  const [originData, setOriginData] = useState<NoticeInterface[]>([]);
  const [isStop, setIsStop] = useState<boolean>(false);
  const SearchData = useCallback(
    (filter: any) => {
      const { searchType, value } = filter;
      let clone = originData.slice();
      if (searchType === "title") {
        clone = clone.filter((item) => item.title.includes(value));
      }
      setData(clone);
    },
    [originData]
  );
  const GetNotice = useCallback(async (id?: string) => {
    try {
      const {
        data: { state, result,msg },
      } = await clientApi.get("/notice/get", {
        params: {
          number: 10,
          id,
        },
      });
      if (state) {
        setData((res) =>  res ? [...res, ...result] : result);
        setOriginData([...result])
        if (result.length < 10) {
          setIsStop(true);
        }
      }
    } catch (error) {
      console.log(error);
      console.log("에러다");
    }
  }, []);

  useEffect(() => {
    GetNotice();
    return () => {};
  }, [GetNotice]);

  return (
    <Notice
      data={data}
      userAgent={userAgent}
      SearchData={SearchData}
      GetNotice={GetNotice}
      isStop={isStop}
    />
  );
};

export default NoticeContainer;
