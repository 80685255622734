import React, { RefObject } from "react";
import { PqboxType } from "../../../../interface/interface";
import Editor from "../../../../components/Editor/Editor";

type Props = {
  data: PqboxType;
  ScreenRef: RefObject<HTMLDivElement>;
};

const PqboxContent = ({ data, ScreenRef }: Props) => {
  return (
    <div className="pqbox-content-page">
      <div className="write-pqbox-title">
        <span>03</span>
        내용작성
      </div>
      <Editor ScreenRef={ScreenRef} dataKey={data.key} purpose="rewards" />
    </div>
  );
};

export default PqboxContent;
