import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import "./css/index.css";
import {
  CardType,
  PaymentType,
  ProjectType,
  RewardType,
} from "../../interface/interface";
import AdressInput from "../../components/AdressInput/AdressInput";
import { makeComma } from "../../lib/lib";
import PaymentConfirm from "./components/PaymentConfirm";
import PaymentCardSection from "./components/PaymentCardSection";
import { UserAgentType } from "../../common/common.inerface";
import { deleteObject, ref } from "@firebase/storage";
import PaymentFile from "../../components/Common/CommonInput/PaymentFile";
import { Fstorage } from "../../config/firebaseApp";
import PaymentRewardSection from "./components/PaymentRewardSection";
import Popup from "../../components/Popup/Popup";
type Props = {
  data: ProjectType;
  __initCard: () => void;
  dispatch: React.Dispatch<{
    payload: any;
    type: string;
  }>;
  cards: any;
  state: PaymentType;
  RewardData: RewardType[];
  nowCard: CardType | undefined;
  setNowCard: Dispatch<SetStateAction<CardType | undefined>>;
  userAgent: UserAgentType;
  Valid: () => { state: boolean; msg?: string };
  SubmitPayment: () => void;
  uid?: string;
  email: string;
  setRewardData: Dispatch<SetStateAction<RewardType[]>>;
};

const ProjectPayment = ({
  data,
  __initCard,
  nowCard,
  setNowCard,
  dispatch,
  state,
  cards,
  RewardData,
  userAgent,
  Valid,
  SubmitPayment,
  uid,
  setRewardData,
  email,
}: Props) => {
  const startDate = new Date();
  const finishDate = new Date(data.period.finish);
  const timeDiff = finishDate.getTime() - startDate.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dday = Math.ceil(timeDiff / oneDay);
  const percent = (data.amount.now / data.amount.goal) * 100;
  const [popupType, setpopupType] = useState<string | undefined>(undefined);
  const FileUplodae = useCallback(
    (type: string, payload: any, idx?: number) => {
      if (state.files) {
        let clone = state.files.slice();
        if (clone.length < 5) {
          if (typeof idx === "number") {
            clone[idx] = payload;
          } else {
            clone.push(payload);
          }
          dispatch({
            type,
            payload: clone,
          });
        }
      }
    },
    [state, dispatch]
  );

  const RemoveFile = useCallback(
    (idx: number) => {
      if (state.files) {
        let clone = state.files.slice();
        deleteObject(
          ref(
            Fstorage,
            `/${data.purpose}/${data.key}/${uid}/${clone[idx].name}`
          )
        );
        clone.splice(idx, 1);
        dispatch({
          type: "files",
          payload: clone,
        });
      }
    },
    [state, dispatch, uid, data]
  );

  return (
    <main className={`projectpayment-page ${data.purpose}-payment-page`}>
      <div className="wrapper">
        <div className="left">
          <div className="projectpayment-info">
            <div className="left">
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${data.images.thumbnail})`,
                  width: `205px`,
                  height: `115px`,
                  backgroundPosition: `center`,
                  backgroundSize: `cover`,
                }}
              />
            </div>
            <div className="right">
              <div className="catagory">
                {data.rewards.type[0].value
                  ? data.rewards.type[0].value
                  : data.rewards.type[0].title}
              </div>
              <div className="title">{data.title}</div>
              {data.purpose === "projects" && (
                <div className="etc-wrapper">
                  <div className="percent">{percent.toFixed(0)}% 달성 </div>
                  <div className="days">{dday}일 남음</div>
                </div>
              )}
              {data.purpose === "ordermade" && <OrderSection data={data} />}
            </div>
          </div>
          <PaymentRewardSection
            userAgent={userAgent}
            purpose={data.purpose}
            RewardData={RewardData}
            data={data}
            setRewardData={setRewardData}
            email={email}
          />
          <div className="projectpayment-support">
            <div className="title">추가후원도 가능합니다 👍</div>
            <div className="text">
              *원하는 추가 후원금을 입력해주시면 프로젝트 성공 확률이 높아집니다.
              <br />
              *창작자와 협의 후 추가지불이 이뤄져야 하는 경우 이곳에 입력해주세요.
            </div>
            <input
              placeholder="숫자만 입력"
              type="text"
              value={state.donation ? makeComma(state.donation) : 0}
              onChange={(e) => {
                dispatch({
                  type: "donation",
                  payload: parseInt(e.target.value.replace(/[^0-9]/g, "")),
                });
              }}
            />
            <div className="text-won">원</div>
          </div>
          <AdressInput type="text" dispatch={dispatch} data={state} />
          {data.purpose === "ordermade" && (
            <div className="order-file-wrapper">
              <PaymentFile
                title={"첨부파일 (최대 5개)"}
                placeholder={
                  "주문에 필요한 파일이 있다면 업로드해주세요 / 5mb이하/ jpg,png"
                }
                type={"files"}
                value={""}
                Update={FileUplodae}
                path={`/${data.purpose}/${data.key}/${uid}`}
                purpose={data.purpose}
              />
              {state.files?.map((item, idx) => {
                return (
                  <PaymentFile
                    key={idx}
                    title={""}
                    placeholder={item.name}
                    type={"files"}
                    value={item.url}
                    Update={FileUplodae}
                    path={`/${data.purpose}/${data.key}/${uid}`}
                    purpose={data.purpose}
                    RemoveFile={RemoveFile}
                    idx={idx}
                  />
                );
              })}
            </div>
          )}
          <PaymentCardSection
            cards={cards}
            __initCard={__initCard}
            nowCard={nowCard}
            setNowCard={setNowCard}
            purpose={data.purpose}
          />
        </div>
        <div className="right">
          <PaymentConfirm
            data={data}
            reward={RewardData}
            donation={state.donation}
            Valid={Valid}
            setpopupType={setpopupType}
          />
        </div>
        <Popup
          type={popupType}
          Active={SubmitPayment}
          setCancel={setpopupType}
          data={state}
        />
      </div>
    </main>
  );
};

export default ProjectPayment;

const OrderSection = ({ data }: { data: ProjectType }) => {
  const orderCount = data.rewards.content.reduce((a, b) => a + b.count, 0);
  const payCount = data.rewards.content.reduce((a, b) => {
    const lenght = b.payment ? Object.values(b.payment).length : 0;
    return a + lenght;
  }, 0);
  return (
    <div className="etc-wrapper">
      <div className="percent">
        {" "}
        {orderCount - payCount > 0
          ? `${orderCount - payCount}개 남음`
          : "오더 성공"}
      </div>
      <div className="days">{payCount}개 달성</div>
    </div>
  );
};
