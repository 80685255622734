import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";
import EmtyList from "../../components/EmtyList/EmtyList";
import { StoryType } from "../../interface/interface";
import CategoryFilter from "../../components/Catagory/CategoryFilter";
import StoryListSearch from "../../components/Story/StoryListSearch/StoryListSearch";
import StoryCard from "../../components/Story/StoryCard/StoryCard";
import LoadingStoryCard from "../../components/Story/StoryCard/LoadingStoryCard";
import { UserAgentType } from "../../common/common.inerface";
import { authStoryType } from "../../reducers/auth";
type Props = {
  data: StoryType[] | undefined;
  setNowCategory: Dispatch<SetStateAction<number>>;
  nowCategory: number;
  userAgent: UserAgentType;
  ChangeSearchState: (type: "like" | "search", value: string | boolean) => void;
  isLike: boolean;
  ChangeLike: (key: string) => void;
  user_story?: authStoryType;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  type: "my" | "list";
  MoreStory: () => void;
};

const Story = ({
  data,
  setNowCategory,
  nowCategory,
  userAgent,
  ChangeSearchState,
  isLike,
  ChangeLike,
  user_story,
  page,
  setPage,
  type,
  MoreStory,
}: Props) => {
  return (
    <main className="story-list-page">
      <div className="page-title">
        {type === "list" ? "PQ 스토리" : "스토리 작성목록"}
      </div>
      <div className="story-page-filter-section">
        <CategoryFilter
          categories={story_category_layouts}
          nowCategory={nowCategory}
          onCategoryChange={setNowCategory}
          // purpose="story"
        />
        <StoryListSearch
          userAgent={userAgent}
          ChangeSearchState={ChangeSearchState}
          isLike={isLike}
        />
      </div>
      {data && (
        <>
          {data.length < 1 ? (
            <div className="story-emty-wrapper">
              <EmtyList title="콘텐츠 준비중 입니다 :)" sub="" />
            </div>
          ) : (
            <>
              <div className="story-list-wrapper">
                {data.map((item, idx) => {
                  return (
                    <StoryCard
                      key={idx}
                      data={item}
                      ChangeLike={ChangeLike}
                      isLike={user_story && user_story[item.key] ? true : false}
                    />
                  );
                })}
              </div>
              <div className="view-page">
                {data.length >= 9 * (page + 1) && (
                  <button className={`view-button`} onClick={MoreStory}>
                    더보기 +
                  </button>
                )}
              </div>
            </>
          )}
        </>
      )}
      {data === undefined && (
        <div className="story-list-wrapper">
          {Array(3)
            .fill(0)
            .map((item, idx) => {
              return <LoadingStoryCard key={idx} />;
            })}
        </div>
      )}
    </main>
  );
};

export default Story;

export const story_category_layouts = [
  "전체",
  "급구",
  "판매",
  "후기",
  "수요조사",
  "홍보",
  "기타",
];
