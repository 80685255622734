import React from "react";
import PqboxList from "../PqboxList";
import UsePqBox from "../../../hooks/UsePqBox";
import { UserAgentType } from "../../../common/common.inerface";

type Props = {
  userAgent: UserAgentType;
};

const PqBoxUserContainer = ({ userAgent }: Props) => {
  const { id, purpose, RewardList, Search, isManager, NavManager } = UsePqBox();
  return (
    <PqboxList
      projectId={id}
      purpose={purpose}
      RewardList={RewardList}
      isManager={isManager}
      Search={Search}
      userAgent={userAgent}
      type="user"
      NavManager={NavManager}
    />
  );
};

export default PqBoxUserContainer;
