import React from "react";
import "./css/index.css";
import Skeleton from "react-loading-skeleton";

const NoticeCardSk = () => {
  return (
    <div className="notice-card sk-notice">
      <div className="card">
        <div className="top">
          <div className="title">
            <Skeleton />
          </div>
        </div>
        <div className="profile">
          <div className="date">
            <Skeleton />
          </div>
          <div className="name date">
            <Skeleton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeCardSk;
