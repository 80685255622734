import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { CardType } from "../../../interface/interface";
import PayCard from "../../PayCard/PayCard";
import { AuthType } from "../../../reducers/auth";
import UseCard from "../../../hooks/UseCard";

type Props = {
  initialCard: CardType;
  Active: (data?: any) => void;
};

const ChangeCardPopup = ({ initialCard, Active }: Props) => {
  const { card, uid } = useSelector<RootState, AuthType>((state) => state.auth);
  const { __initCard } = UseCard({
    uid: uid ? uid : "",
  });
  const [nowCard, setnowCard] = useState<CardType | undefined>(initialCard);
  return (
    <div className="change-card-popup">
      <div className="title">결제 카드 변경</div>
      <div className="pay-card-wrapper">
        {card?.map((item, idx) => {
          return (
            <PayCard
              key={idx}
              data={item}
              setNowCard={setnowCard}
              nowCard={nowCard}
            />
          );
        })}
      </div>
      {card && card.length < 4 && (
        <button className="add-button" onClick={__initCard}>
          <img src="/assets/common/add.svg" alt="" />
          <div className="text">새로운 카드를 등록 후 결제에 사용합니다.</div>
        </button>
      )}
      <ul>
        <li>
          결제는 바로 이루어지는 것이 아닌 펀딩 성공일 이후 자동 결제 됩니다.
        </li>
        <li>
          프로젝트퀘스천은 카드번호를 암호화해 카드사로 전달하기 때문에
          안전합니다.
        </li>
        <li>
          결제 이후 카드정보 변경이 필요하시다면 프로젝트퀘스천으로
          문의부탁드립니다.
        </li>
        <li>
          결제가 되기 전 카드의 잔액이나 상태를 한번 더 확인해주시기 바랍니다.
        </li>
      </ul>
      <button
        className="card-change-btn"
        onClick={() => {
          Active(nowCard);
        }}
      >
        변경하기
      </button>
    </div>
  );
};

export default ChangeCardPopup;
