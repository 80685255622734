import React from "react";
import "./css/index.css";
import { ProjectType, PurposeType } from "../../../interface/interface";
import { Link } from "react-router-dom";

import EmtyList from "../../EmtyList/EmtyList";
import ProjectCard from "../../ProjectCard/ProjectCard";
import ProjectCardSk from "../../ProjectCard/ProjectCardSk";

type Props = {
  title: string;
  type: "funding" | "open" | "order" | "pqbox" ;
  purpose: PurposeType;
  data?: ProjectType[];
  scrapList?: { key: string; purpose: PurposeType }[];
  ScrapProject?: (key: string, purpose: PurposeType) => void;
};

const MainList = ({
  title,
  type,
  purpose,
  data,
  ScrapProject,
  scrapList,
}: Props) => {
  return (
    <section className="main-list-section">
      <div className="title-section">
        <b>
          {title}
          {type === "pqbox" && (
            <span>
              {" "}
              | 업데이트 {purpose === "ordermade" ? "오더" : "펀딩"} 리워드
            </span>
          )}
        </b>
        <Link to={`/${purpose}/${type}/list`}>전체보기</Link>
      </div>
      {!data && 
              <div className="list-section">
          {Array(3).fill(0).map((item, idx) => {
            return (
              <ProjectCardSk
                key={idx}
              />
            );
          })}
        </div>
      }
      {data && data.length > 0 && (
        <div className="list-section">
          {data.map((item, idx) => {
            return (
              <ProjectCard
                key={idx}
                data={item}
                type={type}
                ScrapProject={ScrapProject}
                isScrap={
                  scrapList &&
                  scrapList.filter(({ key }) => key === item.key).length > 0
                }
              />
            );
          })}
        </div>
      )}
      {data && data.length < 1 &&      <div className="main-list-emty-wrapper">
          <EmtyList
            title="현재 프로젝트 오픈 준비중입니다."
            sub={`더 빠르게 새로운 콘텐츠를
만나보실 수 있도록 준비 중입니다 :)`}
          />
        </div>

      }
    </section>
  );
};

export default MainList;
