// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "@firebase/storage";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import {  getDatabase } from "firebase/database";
const appState =
  process.env.REACT_APP_SITE === "production" ? "production" : "test";
var firebaseConfig = {
  apiKey: "AIzaSyAJm2AUpe4PfgGbt722fYsmPJicbz0_5JE",
  authDomain: "projectquestion-2d800.firebaseapp.com",
  projectId: "projectquestion-2d800",
  storageBucket: `projectquestion-2d800${
    appState !== "test" ? ".appspot.com" : "-test"
  }`,
  databaseURL: process.env.REACT_APP_STATE ==="test" ? "https://projectquestion-test.firebaseio.com/" : "https://projectquestion-production.firebaseio.com/",
  messagingSenderId: "981521138720",
  appId: "1:981521138720:web:40c994b8e6cf5383bca845",
  measurementId: "G-JBQ6B996MQ",
};
// Initialize Firebase
// firebaseApp.initializeApp(firebaseConfig);
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const Fauth = getAuth(app);
export const Fstorage = getStorage(app);
export const Fdb = getDatabase(app);
getPerformance(app);
