import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./css/index.css";
import ProfileSection from "./components/ProfileSection";
import { UserAgentType } from "../../common/common.inerface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { AllAuth, AuthType } from "../../reducers/auth";
import { Fauth } from "../../config/firebaseApp";
import UseUser from "../../hooks/UseUser";
import { clientApi } from "../../api/config";
import { SetPopular } from "../../reducers/data";
type Props = {
  userAgent: UserAgentType;
};

const Header = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const nowpath = useLocation().pathname;
  const userInfo = useSelector<RootState, AuthType>((state) => state.auth);
  const { GetMyInfo } = UseUser();
  const [subNav, setSubNav] = useState<
    { title: string; link: string; type?: string }[]
  >([]);
  const [isEtc, setIsEtc] = useState<boolean>(false);
  const ChangeNav = useCallback(() => {
    document.getElementById("root")?.scrollTo(0, 0);
    console.log(nowpath);
    if (
      nowpath === "/about" ||
      nowpath === "/ask" ||
      nowpath.includes("notice") ||
      nowpath.includes("policy") ||
      isEtc
    ) {
      setSubNav(etc_layouts);
      if (!isEtc) {
        setIsEtc(true);
      }
      return;
    }
    if (nowpath.includes("funding") || nowpath.includes("projects")) {
      setSubNav(funding_layouts);
      setIsEtc(false);
      return;
    }
    if (nowpath.includes("order")) {
      setIsEtc(false);
      setSubNav(order_layouts);
      return;
    }

    setIsEtc(false);
    setSubNav([]);

    return;
  }, [nowpath, isEtc]);

  useEffect(() => {
    ChangeNav();
    return () => {};
  }, [ChangeNav]);

  useEffect(() => {
    Fauth.onAuthStateChanged((user) => {
      if (user && user.uid) {
        GetMyInfo(user.uid, user.emailVerified);
      } else {
        dispatch(
          AllAuth({
            uid: "",
            profile: {
              email: "",
              image: "",
              nickname: "",
              quote: "",
            },
            config: {
              policy: [false, false, false],
            },
          })
        );
      }
    });
    clientApi.get("/popular").then(({ data: { state, data } }) => {
      if (state) {
        dispatch(SetPopular(data));
      }
    });
    return () => {};
  }, [dispatch]);
  if (nowpath.includes("write")) {
    return <></>;
  }

  return (
    <header className="header-section">
      <div className="header-wrapper">
        <Link
          className="left-logo"
          to="/"
          onClick={() => {
            if (isEtc) {
              setIsEtc(false);
            }
          }}
        >
          <img src="/assets/header/logo.svg" alt="프로젝트 퀘스천" />
        </Link>
        {userAgent === "pc" && (
          <nav className="header-nav-section">
            {header_layouts.map(({ title, link, type }, idx) => {
              return (
                <Link
                  key={idx}
                  to={link}
                  className="header-nav"
                  onClick={() => {
                    if (type === "btn") {
                      setIsEtc(true);
                      setSubNav(etc_layouts);
                    } else if (isEtc) {
                      setIsEtc(false);
                    }
                  }}
                >
                  {title}
                </Link>
              );
            })}
            <div
              className={`nav-bottom-bar ${
                isEtc
                  ? "etc"
                  : nowpath === "/funding" || nowpath.includes("funding")
                  ? "funding"
                  : nowpath.includes("order")
                  ? "order"
                  : nowpath.includes("story")
                  ? "story"
                  : "not"
              }-bar`}
            />
          </nav>
        )}
        <ProfileSection userAgent={userAgent} userInfo={userInfo} />
      </div>
      {userAgent !== "pc" && (
        <nav className="header-nav-section">
          <div className="header-nav-wrapper">
            {header_layouts.map(({ title, link, type }, idx) => {
              return (
                <Link
                  key={idx}
                  to={link}
                  className="header-nav"
                  onClick={() => {
                    if (type === "btn") {
                      setIsEtc(true);
                      setSubNav(etc_layouts);
                    } else if (isEtc) {
                      setIsEtc(false);
                    }
                  }}
                >
                  {title}
                </Link>
              );
            })}
            <div
              className={`nav-bottom-bar ${
                isEtc
                  ? "etc"
                  : nowpath.includes("funding")
                  ? "funding"
                  : nowpath.includes("order")
                  ? "order"
                  : nowpath.includes("story")
                  ? "story"
                  : nowpath === "/about" ||
                    nowpath === "/guide" ||
                    nowpath === "/ask" ||
                    nowpath.includes("notice")
                  ? "etc"
                  : "not"
              }-bar`}
            />
          </div>
        </nav>
      )}
      {subNav.length > 0 ? (
        <div className="sub-nav">
          <div className="sub-nav-wrapper">
            {subNav.map(({ link, title, type }, idx) => {
              return (
                <Link
                  to={link}
                  key={`${link}-${type}`}
                  className={nowpath.includes(link) ? "active-link" : ""}
                  style={
                    link === nowpath &&
                    (nowpath === "/" || nowpath.includes("order"))
                      ? {
                          color: "var(--red600)",
                        }
                      : undefined
                  }
                >
                  {title}
                </Link>
              );
            })}
          </div>
        </div>
      ) : undefined}
    </header>
  );
};

export default Header;

const header_layouts = [
  {
    title: "오더메이드",
    link: "/ordermade",
    type: "link",
  },
  {
    title: "PQ펀딩",
    link: "/funding",
    type: "link",
  },
  {
    title: "PQ스토리",
    link: "/story",
    type: "link",
  },
  {
    title: "더보기",
    link: "/about",
    type: "btn",
  },
];

const funding_layouts = [
  {
    title: "전체 프로젝트",
    link: "/projects/funding/list",
  },
  {
    title: "오픈 예정",
    link: "/projects/open/list",
  },
  {
    title: "PQ박스",
    link: "/projects/pqbox/list",
  },
];
const order_layouts = [
  {
    title: "전체 프로젝트",
    link: "/ordermade/order/list",
  },
  {
    title: "오픈 예정",
    link: "/ordermade/open/list",
  },
  {
    title: "PQ박스",
    link: "/ordermade/pqbox/list",
  },
];
const etc_layouts = [
  {
    title: "About",
    link: "/about",
  },
  {
    title: "공지",
    link: "/notice",
  },
  {
    title: "문의",
    link: "/ask",
  },
  {
    title: "약관",
    link: "/policy",
  },
];
