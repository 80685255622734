import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: (type?: string) => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
  type: string;
  data?: any;
};

const RejectPopup = ({ Active, type, setCancel, data }: Props) => {
  console.log(data);
  return (
    <div className="reject-popup">
      <div className="title">프로젝트 거절사유 확인하기</div>
      <div className="sub">
        프로젝트 거절사유 확인 후 <br />
        다시 프로젝트를 수정해주세요
      </div>
      <div className="reject-content">{data.rejectMessage}</div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active(type);
          }}
        >
          수정중 상태로 전환
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          닫기
        </button>
      </div>
    </div>
  );
};

export default RejectPopup;
