import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PqboxManagerContainer from "../page/PqboxList/container/PqboxManagerContainer";
import PqboxWriteContainer from "../page/PqboxWrite/container/PqboxWriteContainer";
import PqboxFixContainer from "../page/PqboxWrite/container/PqboxFixContainer";
import { UserAgentType } from "../common/common.inerface";
import PqBoxUserContainer from "../page/PqboxList/container/PqBoxUserContainer";
import PqboxDetailContainer from "../page/PqboxDetail/container/PqboxDetailContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { AuthType } from "../reducers/auth";
import { ToastChange } from "../reducers/config";
import Loading from "../components/Loading/Loading";
import PqboxPreviewContainer from "../page/PqboxDetail/container/PqboxPreviewContainer";

type Props = {
  userAgent: UserAgentType;
};

const RewardRoutes = ({ userAgent }: Props) => {
    const dispatch = useDispatch();
  const UserInfo = useSelector<RootState, AuthType>((state) => state.auth);
  useEffect(() => {
    if (UserInfo.uid !== undefined && !UserInfo.uid) {
      // navigate("/login");
      dispatch(
        ToastChange({ active: true, content: "로그인 후에 접근 가능합니다." })
      );
    }
    return () => {};
  }, [UserInfo]);

  if (UserInfo.uid === undefined) {
    return <Loading initialLoading={true} />;
  }
  return (
    <Routes>
      <Route
        path="/management/:purpose/:id"
        element={<PqboxManagerContainer userAgent={userAgent} />}
      />
      <Route
        path="/list/:purpose/:id"
        element={<PqBoxUserContainer userAgent={userAgent} />}
      />
      <Route
        path="/detail/:purpose/:prjtId/:id"
        element={<PqboxDetailContainer userAgent={userAgent} />}
      />
            <Route
        path="/preview/:purpose/:prjtId/:id"
        element={<PqboxPreviewContainer  />}
      />
      <Route path="/write/*" element={<PqboxWriteContainer />} />
      <Route path="/:id/fix/*" element={<PqboxFixContainer />} />
    </Routes>
  );
};

export default RewardRoutes;

