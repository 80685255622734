import React from "react";
import { Link } from "react-router-dom";
import "./css/index.css";
function Page_404() {
  return (
    <div className="page-404">
      <div className="wrapper">
        <div className="symbol">
          <img src="/assets/404/dkt.png"
            srcSet="/assets/404/dkt@2x.png 2x, /assets/404/dkt@3x.ppng 3x"
          alt="" />
        </div>
        <div className="slogan txt-bold">요청한 페이지를 찾을 수 없습니다</div>
        <div className="slogan2">
          {` 찾으려는 페이지의 주소가 잘못 입력되었거나,
          주소의 변경 혹은 삭제로 인해 사용하실 수 없습니다.
          입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.`}
        </div>
          <a href="/">메인으로</a>
      </div>
    </div>
  );
}

export default Page_404;
