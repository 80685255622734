import React, { useState } from "react";
import "./css/index.css";
import Dropdown from "../../Dropdown/Dropdown";
type Props = {
  Search: (value: string, category?: number) => void;
};

const PqboxSearch = ({ Search }: Props) => {
  const [nowState, setNowState] = useState<number | undefined>(undefined);
  const [Keyword, setKeyword] = useState<string>("");
  return (
    <div className="pqbox-search-section">
      {layouts.map(({ tag, arr, placeholder, type }, idx) => {
        return (
          <div className="search-line" key={idx}>
            <div className="search-tag">{tag}</div>
            {type === "select" && (
              <Dropdown
                arr={arr}
                placeholder={placeholder}
                now={nowState}
                setNow={setNowState}
              />
            )}
            {type === "search" && (
              <input
                placeholder="제목 입력"
                className="search-input"
                value={Keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
            )}
          </div>
        );
      })}
      <button
        className="search-btn"
        onClick={() => {
          Search(Keyword, nowState);
        }}
      >
        검색
      </button>
    </div>
  );
};

export default PqboxSearch;

const layouts = [
  {
    tag: "상태",
    arr: ["전체", "오픈중", "오픈 마감"],
    placeholder: "전체",
    type: "select",
  },
  {
    tag: "검색",
    arr: [],
    placeholder: "전체",
    type: "search",
  },
];
