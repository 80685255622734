import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAgentType } from "../../../common/common.inerface";
import { AuthType } from "../../../reducers/auth";
import { signOut } from "@firebase/auth";
import { Fauth } from "../../../config/firebaseApp";
import UserNavSection from "./UserNavSection/UserNavSection";

type Props = {
  userAgent: UserAgentType;
  userInfo: AuthType | undefined;
};

const ProfileSection = ({ userAgent, userInfo }: Props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const SignOut = useCallback(async () => {
    await signOut(Fauth);
    navigate("/");
  }, []);
  return (
    <div className="header-profile-section">
      {userInfo && userInfo.uid ? (
        <button
          className="header-user-section"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div
            className="profile-img"
            style={{
              backgroundImage: `url(${userInfo.profile.image})`,
            }}
          />
          {userAgent !== "mb" && (
            <div className="profile-nickname">{userInfo.profile.nickname}</div>
          )}
        </button>
      ) : (
        <div className="header-login-nav-section">
          <Link to={"/login"}>로그인</Link>
          {userAgent !== "mb" && (
            <Link to={"/login"} state={true}>
              회원가입
            </Link>
          )}
        </div>
      )}
      {userAgent !== "mb" && userInfo?.uid && (
        <div className="header-profile-btn-section">
          <Link className="project-btn" to={"/write/select"}>
            <img
              src="/assets/header/prjt-btn.png"
              srcSet="/assets/header/prjt-btn@2x.png 2x, /assets/header/prjt-btn@3x.png 3x"
              alt="프로젝트 만들기"
            />
          </Link>
        </div>
      )}
      {userInfo && (
        <UserNavSection
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          SignOut={SignOut}
          userInfo={userInfo}
          userAgent={userAgent}
        />
      )}
    </div>
  );
};

export default ProfileSection;
