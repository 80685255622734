import Signup from "../Signup";
import { useCallback, useEffect, useReducer } from "react";
import { SignReducer } from "../../../lib/reducer";
import { SignInitial } from "../../../lib/initial";
import {
  CheckNicknameApi,
  EmailSignUp,
  SocialSignUp,
} from "../../../api/user_api";
import { UserAgentType } from "../../../common/common.inerface";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoadingChange, ToastChange } from "../../../reducers/config";
import { AllAuth } from "../../../reducers/auth";
const SocialSignupContainer = ({ userAgent }: { userAgent: UserAgentType }) => {
  const reduxPatch = useDispatch();
  const navigate = useNavigate();
  const social_info = useLocation().state;
  const [state, dispatch] = useReducer(SignReducer, undefined, SignInitial);
  const CheckNickname = useCallback(async (nickname: string) => {
    return await CheckNicknameApi(nickname);
  }, []);
  const SubmitSignup = useCallback(async () => {
    reduxPatch(LoadingChange(true));
    const { provider, uid } = social_info;
    let obj = Object.assign({ provider, uid }, state);
    const result = await SocialSignUp(obj);
    if (result.state) {
      reduxPatch(AllAuth(result.data));
      navigate("/");
    } else {
      reduxPatch(ToastChange({ active: true, content: result.msg }));
    }
    reduxPatch(LoadingChange(false));
  }, [state, navigate, reduxPatch, social_info]);
  useEffect(() => {
    if (social_info) {
      const { email } = social_info;
      dispatch({
        type: "dispatch/all",
        payload: {
          email: email,
          name: "",
          password: "social12345!",
          passwordcheck: "social12345!",
          policy: [true, true,true],
        },
      });
    } else {
      navigate("/");
      reduxPatch(ToastChange({ active: true, content: "잘못된 접근입니다" }));
    }

    return () => {};
  }, [social_info]);

  return (
    <Signup
      dispatch={dispatch}
      data={state}
      CheckNickname={CheckNickname}
      userAgent={userAgent}
      SubmitSignup={SubmitSignup}
      type="social"
    />
  );
};

export default SocialSignupContainer;
