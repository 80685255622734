import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { clientApi } from "../api/config";
import { authStoryType } from "../reducers/auth";
import { StoryType } from "../interface/interface";
import { useNavigate, useParams } from "react-router-dom";
import { ToastChange } from "../reducers/config";
import { onValue, ref } from "firebase/database";
import { Fdb } from "../config/firebaseApp";

const UseStoryDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const uid = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const user_story = useSelector<RootState, authStoryType | undefined>(
    (state) => state.auth.story
  );
  const [DetailData, setDetailData] = useState<StoryType | undefined>(
    undefined
  );
  const ChangeLike = useCallback(
    async (key: string) => {
      if (uid) {
        const {
          data: { state, data },
        } = await clientApi.post("/story/like", {
          key,
          uid,
        });
        // dispatch(UpdateStory(data))
      }
    },
    [uid, dispatch]
  );
  const GetDetail = useCallback(async () => {
    if (params.id) {
      const {
        data: { state, data },
      } = await clientApi.get("/story/detail", {
        params: {
          key: params.id,
        },
      });
      if (state) {
        setDetailData(data);
      }
    }
  }, [params]);
  const RemoveStory = useCallback(async () => {
    if (params.id && uid) {
      const {
        data: { state, msg },
      } = await clientApi.delete("/story/remove", {
        params: {
          key: params.id,
          uid,
        },
      });
      if (state) {
        navigate("/story");
      }
      dispatch(ToastChange({ active: true, content: msg }));
    }
  }, [params, uid]);
  useEffect(() => {
    let refs: any = undefined;
    if (params.id) {
      refs = onValue(ref(Fdb, `/story/${params.id}/like`), (snapshot) => {
        setDetailData((prev) => {
          if (prev) {
            let clone = Object.assign({}, prev);
            clone.like = snapshot.exists() ? snapshot.val() : 0;
            return clone;
          }
          return prev;
        });
      });
    }

    return () => {
      if (refs) {
        refs();
      }
    };
  }, [params]);

  return { ChangeLike, user_story, DetailData, GetDetail, RemoveStory };
};

export default UseStoryDetail;
