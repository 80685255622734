import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: () => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
  data: any;
};

const PaymentSubmitPopup = ({ Active, setCancel, data }: Props) => {
  return (
    <div className="remove-popup">
      <div className="title">입력하신 정보가 맞습니까?</div>
      <div className="sub">
        휴대폰번호 : {data.profile.tel} <br /> 주소 : {data.address.basic}{" "}
        {data.address.detail}
      </div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active();
          }}
        >
          확인
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default PaymentSubmitPopup;
