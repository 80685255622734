import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: (type?: string) => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
};

const FixPopup = ({ Active, setCancel }: Props) => {
  return (
    <div className="save-popup">
      <div className="title">심사 회수 요청하시겠습니까?</div>
      <div className="sub">
        심사 회수 시 프로젝트퀘스쳔 관리자의<br/> 승인에 따라 프로젝트의 내용을
        수정할 수 있습니다
      </div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active();
          }}
        >
          심사회수 요청
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default FixPopup;
