import React from "react";
import "./css/index.css";
import { RewardDetailType } from "../../hooks/UsePqboxDetail";
import PqboxDetailTop from "../../components/Pqbox/PqboxDetailTop/PqboxDetailTop";
import Screen from "../../components/Screen/Screen";
type Props = {
  data: RewardDetailType;
};

const PqboxPreview = ({ data: { data, prjtData } }: Props) => {
  return (
    <main
      className="pqbox-detail-page"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      onSelect={(e) => {
        e.preventDefault();
      }}
    >
      <PqboxDetailTop data={data} prjtData={prjtData} />
      <div className="pqbox-detail-content">
        <div className="pqbox-detail-wrapper">
          <Screen data={data.editor} />
        </div>
      </div>
      <div className="pqbox-detail-bottom-period">
        <div className="pqbox-detail-wrapper">
          <div className="title">공개 마감일</div>
          <div className="period">{data.period.finish} 까지 공개</div>
        </div>
      </div>
    </main>
  );
};

export default PqboxPreview;
