import React from "react";
import "./css/index.css"
type Props = {
  CheckStep: () => void;
  title: string;
  type: "next" | "back" | "order" |"gradient" ;
};

const StepBtn = ({ CheckStep, title, type }: Props) => {
  return (
    <button className={`step-btn ${type}-step-btn`} onClick={CheckStep}>
      {title}
    </button>
  );
};

export default StepBtn;
