import React from "react";
import "./css/index.css";
import {
  ProjectType,
  PurposeType,
  SliderType,
} from "../../../interface/interface";
import Banner from "../../Banner/Banner";
import { UserAgentType } from "../../../common/common.inerface";
import { Link } from "react-router-dom";
import EmtyList from "../../EmtyList/EmtyList";
import Skeleton from "react-loading-skeleton";
type Props = {
  data: SliderType[];
  prjtData?: ProjectType[];
  userAgent: UserAgentType;
  type: "funding" | "order";
  purpose: PurposeType;
};

const BannerSection = ({ data, prjtData, userAgent, type, purpose }: Props) => {
  return (
    <section className="main-banner-section">
      {/* 배너 보류 */}
      <div className="slider-section">
        <Banner AddData={data} userAgent={userAgent} type="main" />
      </div>
      <div className="banner-prjt-section">
        <div className="title-section">
          <div className="title">
            인기{type === "funding" ? "펀딩" : "오더"}
          </div>
          <Link to={`/${purpose}/${type}/list`}>전체보기</Link>
        </div>
        {!prjtData && (
          <div className="banner-prjt-list-wrapper">
            {Array(4)
              .fill(0)
              .map((item, idx) => {
                return (
                  <div key={idx} className="banner-prjt-card">
                    <div className="img-wrapper"><Skeleton/></div>
                    <div className="content">
                      <div className="author"><Skeleton/></div>
                      <div className="title"><Skeleton/></div>
                      <div className="time"><Skeleton/></div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {prjtData && prjtData.length > 0 && (
          <div className="banner-prjt-list-wrapper">
            {prjtData.map(
              (
                {
                  images: { thumbnail },
                  profile: { author },
                  title,
                  period: { finish },
                  key,
                },
                idx
              ) => {
                return (
                  <Link
                    to={`/${type}/detail/${key}`}
                    key={idx}
                    className="banner-prjt-card"
                  >
                    <div className="img-wrapper">
                      <img src={thumbnail} alt={title} loading="lazy" />
                    </div>
                    <div className="content">
                      <div className="author">{author}</div>
                      <div className="title">{title}</div>
                      <div className="time">{finish} 마감</div>
                    </div>
                  </Link>
                );
              }
            )}
          </div>
        )}
        {prjtData && prjtData.length < 1 && (
          <div className="banner-emty">
            <EmtyList
              title="현재 프로젝트 오픈 준비중입니다."
              sub={`더 빠르게 새로운 콘텐츠를
만나보실 수 있도록 준비 중입니다 :)`}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default BannerSection;
