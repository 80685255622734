import {
  ProjectParamsType,
  PurposeType,
  RewardType,
} from "../interface/interface";
import { NetworkError, clientApi } from "./config";
import { ReturnType } from "./user_api";

const GetProjectList = (params: ProjectParamsType): Promise<ReturnType> => {
  return clientApi
    .get("/project/list", {
      params,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const GetProjecDetail = (
  purpose: PurposeType,
  key: string,
  view?: "inside" | "outside"
): Promise<ReturnType> => {
  return clientApi
    .get("/project/detail", {
      params: {
        purpose,
        key,
        view,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};

const PaymentProjectApi = (
  database: any,
  rewardidx: RewardType[]
): Promise<ReturnType> => {
  return clientApi
    .post("/gatekeeping/upload", {
      database,
      rewardidx,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      console.log(err)
      return NetworkError;
    });
};

const PaymentFixApi = (
  data: any,
  rewardidx: RewardType[],
  gateKey: string
): Promise<ReturnType> => {
  return clientApi
    .post("/gatekeeping/fix", {
      data,
      rewardidx,
      gateKey,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      console.log(err)
      return NetworkError;
    });
};
export { GetProjectList, GetProjecDetail, PaymentProjectApi, PaymentFixApi };
