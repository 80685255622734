import React, { useState } from "react";
import PqboxList from "../PqboxList";
import UsePqBox from "../../../hooks/UsePqBox";
import { UserAgentType } from "../../../common/common.inerface";
import Popup from "../../../components/Popup/Popup";

type Props = {
  userAgent: UserAgentType;
};

const PqboxManagerContainer = ({ userAgent }: Props) => {
  const { id, purpose, RewardList, RemoveReward, Search, isManager } =
    UsePqBox();
  const [popupType, setPopupType] = useState<
    | {
        type: string;
        data: any;
      }
    | undefined
  >(undefined);

  return (
    <>
      <PqboxList
        projectId={id}
        purpose={purpose}
        RewardList={RewardList}
        isManager={isManager}
        Search={Search}
        setPopupType={setPopupType}
        userAgent={userAgent}
        type="manager"
      />
      <Popup
        type={popupType?.type}
        setCancel={setPopupType}
        Active={() => {
          if (popupType && popupType.type === "delete-popup") {
            RemoveReward(popupType.data.key);
            setPopupType(undefined);
          }
        }}
        data={popupType?.data}
      />
    </>
  );
};

export default PqboxManagerContainer;
