import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as ArrowSvg } from "./svg/arrow.svg";
import "./css/index.css";
import { SliderType } from "../../interface/interface";
import { UserAgentType } from "../../common/common.inerface";
import Skeleton from "react-loading-skeleton";
export var reg = new RegExp(
  /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi // eslint-disable-line
);

function Banner({
  AddData,
  userAgent,
  type,
}: {
  AddData: SliderType[];
  userAgent: UserAgentType;
  type: "home" | "main";
}) {
  const [now, setNow] = useState<number>(1);
  const [DownX, setDownX] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<any>(undefined);
  const __swipe = useCallback(() => {
    if (AddData.length > 0) {
      const id = setTimeout(() => {
        setNow((prev) => (prev >= AddData.length ? 1 : prev + 1));
      }, 3500);
      setIntervalId(id);
    }
  }, [AddData]);
  const __left = useCallback(() => {
    if (now <= 1) {
      setNow(AddData.length);
    } else {
      setNow(now - 1);
    }
    if (intervalId) {
      clearTimeout(intervalId);
    }
  }, [now, AddData, intervalId]);
  const __right = useCallback(() => {
    if (now >= AddData.length) {
      setNow(1);
    } else {
      setNow(now + 1);
    }
    if (intervalId) {
      clearTimeout(intervalId);
    }
  }, [now, AddData, intervalId]);

  useEffect(() => {
    __swipe();
    return () => {};
  }, [__swipe, now]);
  return (
    <div
      className={`banner-section 

      `}
    >
      <div className="wrapper">
        <div className="content-wrapper">
          <div
            className="banner-wrapper"
            onTouchStart={(e) => {
              setDownX(e.targetTouches[0].clientX);
            }}
            onTouchEnd={(e) => {
              const moveX = e.changedTouches[0].clientX;
              if (moveX < DownX) {
                if (DownX - moveX > 40) {
                  __right();
                }
              } else {
                if (moveX - DownX > 40) {
                  __left();
                }
              }
            }}
          >
            <div className="img-wrapper">
              <div
                className="img-slider"
                style={{
                  gridTemplateColumns: `repeat(${AddData.length},100%)`,
                  transform: `translateX(-${(now - 1) * 100}%)`,
                }}
              >
                {AddData.length > 0 ? (
                  AddData.map(({ imageUrl, url }, idx) => {
                    return (
                      <a
                        key={idx}
                        className="back-img"
                        href={url}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <img src={imageUrl} alt="banner" />
                      </a>
                    );
                  })
                ) : (
                  <div className="back-img">
                    <Skeleton />
                  </div>
                )}
              </div>
              {userAgent !== "mb" && type !== "home" && (
                <div className="slide-wrapper">
                  <div className="index-wrapper">
                    {now}/{AddData.length}
                  </div>
                  <div className="btn-wrapper">
                    <ArrowSvg className="arrow left" onClick={__left} />
                    <ArrowSvg className="arrow right" onClick={__right} />
                  </div>
                </div>
              )}
              {type === "home" &&(
                <div className="slide-wrapper">
                  <div className="btn-wrapper">
                    {AddData.map((_, idx) => {
                      return(
                        <div key={idx} className={`pagination-basic ${now  === idx + 1 && 'pagination' }`} />
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="content">
              <div
                className="content-slider"
                style={{
                  gridTemplateColumns: `repeat(${AddData.length},100%)`,
                  transform: `translateX(-${(now - 1) * 100}%)`,
                }}
              >
                {AddData.length > 0 ? (
                  AddData.map(
                    (
                      { title, subtitle, url, category, categoryColor },
                      idx
                    ) => {
                      return (
                        <a
                          key={idx}
                          className="slide-content"
                          href={url}
                          rel="noreferrer"
                          target={"_blank"}
                        >
                          {type !== "home" && (
                            <div
                              className="category"
                              style={{ background: categoryColor }}
                            >
                              {category}
                            </div>
                          )}
                          <div className="title">{title}</div>
                          <div className="sub">{subtitle}</div>
                        </a>
                      );
                    }
                  )
                ) : (
                  <div className="slide-content">
                    {type !== "home" && (
                      <div className="category-sk">
                        <Skeleton className="category" />
                      </div>
                    )}
                    <div className="title">
                      {" "}
                      <Skeleton />
                    </div>
                  </div>
                )}
              </div>
              {userAgent === "mb" && (
                <div className="slide-wrapper">
                  <div className="index-wrapper">
                    {now}/{AddData.length}
                  </div>
                  <div className="btn-wrapper">
                    <ArrowSvg className="arrow left" onClick={__left} />
                    <ArrowSvg className="arrow right" onClick={__right} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
