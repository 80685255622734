import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: () => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
};

const CommentReportPopup = ({ Active, setCancel }: Props) => {
  return (
    <div className="remove-popup">
      <div className="title">해당 댓글을 신고하시겠습니까?</div>
      <div className="sub">
댓글을 신고하시겠습니까? <br/>
신고 시 철회할 수 없습니다.
      </div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active();
          }}
        >
         신고하기
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default CommentReportPopup;
