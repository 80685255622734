import React from "react";
import { UserAgentType } from "../../../common/common.inerface";

type Props = {
  userAgent: UserAgentType;
  SearchData: (filter: any) => void;
};

const NoticeTop = ({ SearchData }: Props) => {

  return (
    <div className="top-wrapper">
      <div className="title">공지사항</div>
      <div className="top_right">
        <div className="input_wrapper">
          <input  onChange={(e) => SearchData({ searchType: "title", value: e.target.value })} />
          <img src="/assets/notice/searchicon.svg" alt="검색" />
        </div>
      </div>
    </div>
  );
};

export default NoticeTop;
