import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CommentInter } from "../../../../interface/interface";
import { formatDate } from "../../../../lib/lib";

type Props = {
  data: CommentInter;
  myUid?: string;
  FixText: (
    data: CommentInter,
    text: string,
    isSceret?: boolean
  ) => Promise<boolean>;
  OnPoopup: (
    data: CommentInter,
    type: "comment-remove" | "comment-report"
  ) => void;
  isMaker: boolean;
  setIsReply: Dispatch<SetStateAction<string | undefined>>;
  type: "notice" | "reward" | "story";
};

const CommentCard = ({
  data,
  myUid,
  FixText,
  OnPoopup,
  isMaker,
  setIsReply,
  type,
}: Props) => {
  const {
    profile: { image, author, uid },
    text,
    timestamp,
    isSceret,
    isBlind,
  } = data;
  const [isMe, setIsMe] = useState<boolean>(false);
  const [isFix, setIsFix] = useState<boolean>(false);
  const [fixText, setFixText] = useState(text);
  const [isSec, setisSec] = useState<boolean>(isSceret ? isSceret : false);
  useEffect(() => {
    if (uid === myUid) {
      setIsMe(true);
    } else {
      setIsMe(false);
    }

    return () => {};
  }, [myUid, uid]);

  return (
    <div className="comment-card">
      <div className="comment-top-section">
        <div className="profile-section">
          <div className="circle">
            <img
              src={
                image
                  ? image
                  : "https://firebasestorage.googleapis.com/v0/b/projectquestion-2d800.appspot.com/o/config%2Fplaceholder%2Fbasic_profile.svg?alt=media&token=c285b7c5-5f49-4264-b1e8-40ff46ac731c"
              }
              alt=""
            />
          </div>
          <div className="author">{author}</div>
        </div>
        <div className="right-btn-wrapper">
          {myUid ? (
            isMe ? (
              isFix ? (
                <button
                  onClick={() => {
                    setIsFix(false);
                  }}
                >
                  취소
                </button>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setIsFix(true);
                    }}
                  >
                    수정
                  </button>
                  <button
                    onClick={() => {
                      OnPoopup(data, "comment-remove");
                    }}
                  >
                    삭제
                  </button>
                </>
              )
            ) : (
              <>
                {isMaker && (
                  <button
                    onClick={() => {
                      setIsReply(data.myKey);
                      // OnPoopup(data, "comment-report");
                    }}
                  >
                    답글
                  </button>
                )}
                <button
                  onClick={() => {
                    OnPoopup(data, "comment-report");
                  }}
                >
                  신고
                </button>
              </>
            )
          ) : undefined}
        </div>
      </div>
      {isFix ? (
        <>
          <textarea
            value={fixText}
            onChange={(e) => {
              setFixText(e.target.value);
            }}
            className="fix-input"
          />
          <div className="comment-write-bottom">
            <button
              className="secret-btn"
              onClick={() => {
                setisSec((prev) => !prev);
              }}
            >
              <div className="circle">
                {isSec && (
                  <img src="/assets/common/black-check.svg" alt="check" />
                )}
              </div>
              <div className="secret-btn-title">
                {type === "reward" ? "창작자" : "작성자"}만 볼 수 있도록 합니다
              </div>
            </button>
            <button
              className="submit-btn"
              onClick={() => {
                if (fixText.length > 0) {
                  if (fixText !== text || isSec !== isSceret) {
                    FixText(data, fixText, isSec);
                  }
                  setIsFix(false);
                }
              }}
            >
              수정
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="comment-content">
            {isBlind ? (
              "블라인드 처리된 댓글입니다."
            ) : !isMe && isSceret && !isMaker ? (
              <span>
                {" "}
                {type === "reward" ? "창작자" : "작성자"}만 볼 수 있는
                댓글입니다
              </span>
            ) : (
              text
            )}
          </div>
          <div className="comment-timestamp">
            {formatDate(timestamp, "-")}
            {isMe && isSceret && <span>창작자만 보기</span>}
          </div>
        </>
      )}
    </div>
  );
};

export default CommentCard;
