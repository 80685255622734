import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: (type?: string) => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
  type: string;
};

const PreparePopup = ({ Active, setCancel, type }: Props) => {
  return (
    <div className="save-popup">
      <div className="title">재심사를 신청하시겠습니까?</div>
      <div className="sub">심사중엔 내용 수정이 불가능합니다.</div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active(type);
          }}
        >
          심사신청
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default PreparePopup;
