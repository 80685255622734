import React, { useEffect, useState } from "react";
import { CommentInter } from "../../../../interface/interface";
import { formatDate } from "../../../../lib/lib";

type Props = {
  data: CommentInter;
  myUid?: string;
  OnPoopup: (
    data: CommentInter,
    type: "comment-remove" | "comment-report"
  ) => void;
  FixText: (
    data: CommentInter,
    text: string,
    isSceret?: boolean
  ) => Promise<boolean>;
};

const Replycomt = ({ data, myUid, OnPoopup, FixText }: Props) => {
  const {
    profile: { image, author, uid },
    text,
    timestamp,
    isBlind,
  } = data;
  const [isMe, setIsMe] = useState<boolean>(false);
  const [isFix, setIsFix] = useState<boolean>(false);
  const [fixText, setFixText] = useState(text);
  useEffect(() => {
    if (uid === myUid) {
      setIsMe(true);
    } else {
      setIsMe(false);
    }

    return () => {};
  }, [myUid, uid]);

  return (
    <div className="comment-card reply-comment">
      <img
        src="/assets/comment/reply-arrow.svg"
        alt=""
        className="reply-arrow"
      />
      <div className="comment-right">
        <div className="comment-top-section">
          <div className="profile-section">
            <div className="circle">
              <img
                src={
                  image
                    ? image
                    : "https://firebasestorage.googleapis.com/v0/b/projectquestion-2d800.appspot.com/o/config%2Fplaceholder%2Fbasic_profile.svg?alt=media&token=c285b7c5-5f49-4264-b1e8-40ff46ac731c"
                }
                alt=""
              />
            </div>
            <div className="author">{author}</div>
          </div>
          <div className="right-btn-wrapper">
            {myUid ? (
              isMe ? (
                isFix ? (
                  <button
                    onClick={() => {
                      setIsFix(false);
                    }}
                  >
                    취소
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        setIsFix(true);
                      }}
                    >
                      수정
                    </button>
                    <button
                      onClick={() => {
                        OnPoopup(data, "comment-remove");
                      }}
                    >
                      삭제
                    </button>
                  </>
                )
              ) : (
                <>
                  <button
                    onClick={() => {
                      OnPoopup(data, "comment-report");
                    }}
                  >
                    신고
                  </button>
                </>
              )
            ) : undefined}
          </div>
        </div>
        {isFix ? (
          <>
            <textarea
              value={fixText}
              onChange={(e) => {
                setFixText(e.target.value);
              }}
              className="fix-input"
            />
            <div className="comment-write-bottom">
              <button
                className="submit-btn"
                onClick={() => {
                  if (fixText.length > 0) {
                    if (fixText !== text) {
                      FixText(data, fixText);
                    }
                    setIsFix(false);
                  }
                }}
              >
                수정
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="comment-content">
              {isBlind ? "블라인드 처리된 댓글입니다." : text}
            </div>
            <div className="comment-timestamp">
              {formatDate(timestamp, "-")}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Replycomt;
