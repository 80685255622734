import { PurposeType } from "../interface/interface";
import { clientApi } from "./config";
export const NetworkError = {
  state: false,
  data: undefined,
  msg: "네트워크 연결을 확인해주세요",
};

const NoticeGetApi = (number: number) => {
  return clientApi
    .get("/notice/get", {
      params: {
        number,
      },
    })
    .then(({ data: { state, result, msg } }) => {
      if (state) {
        return {
          state: true,
          data: result,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: msg,
        };
      }
    })
    .catch((error) => {
      return NetworkError;
    });
};
const BannerGetApi = () => {
  return clientApi
    .get("/banner")
    .then(({ data: { state, data, msg } }) => {
      if (state) {
        return {
          state: true,
          data,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: msg,
        };
      }
    })
    .catch((error) => {
      return NetworkError;
    });
};
const PolicyGetApi = () => {
  return clientApi
    .get("/policy")
    .then(({ data: { state, data, msg } }) => {
      if (state) {
        return {
          state: true,
          data,
          msg: "요청 성공",
        };
      } else {
        return {
          state: false,
          data: undefined,
          msg: msg,
        };
      }
    })
    .catch((error) => {
      return NetworkError;
    });
};


export { NoticeGetApi, BannerGetApi, PolicyGetApi };
