import React from "react";
import "./css/index.css";
import { ProjectType, StoryType } from "../../../interface/interface";
import { story_category_layouts } from "../../../page/Story/Story";
import { Link } from "react-router-dom";
import { StoryTime } from "../../../lib/lib";
import StoryLike from "../StoryLike/StoryLike";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
type Props = {
  data: StoryType;
  ChangeLike: (key: string) => void;
  isLike: boolean;
};

const StoryCard = ({ data, ChangeLike, isLike }: Props) => {
    const uid = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const {
    images,
    category,
    title,
    content,
    like,
    profile: { nickname },
    timestamp,
    key,
  } = data;
  return (
    <div className="story-card">
      <Link to={`/story/${key}`} className="story-card-top">
        <div className="story-card-img">
          <img src={images[0]} alt="" />
        </div>
        <div className="category">{story_category_layouts[category + 1]}</div>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
        <div className="card-time">{StoryTime(timestamp)}</div>
      </Link>
      <div className="story-card-bottom">
        <StoryLike
          like={like ? Object.keys(like).length : 0}
          ChangeLike={ChangeLike}
          id={key}
          isLike={uid && like && like[uid] ? true : false}
        />
        <div className="author">{nickname}</div>
      </div>
    </div>
  );
};

export default StoryCard;
