import React from "react";
import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { SliderType } from "../../../interface/interface";
import Banner from "../../Banner/Banner";
import { Link } from "react-router-dom";
type Props = {
  userAgent: UserAgentType;
};

const HomeBanner = ({ userAgent }: Props) => {
  const projectBanner = useSelector<RootState, SliderType[]>(
    (state) => state.data.banner.slider
  );
  const orderBanner = useSelector<RootState, SliderType[]>(
    (state) => state.data.banner.orderslider
  );
  return (
    <section className="home-banner-section">
      <div className="home-wrapper">
        <div className="home-banner-wrapper">
          <div className="home-banner-title">
            <b>오더메이드</b> 프로젝트
          </div>
          {/* 오더메이드 배너 */}
          <Banner AddData={orderBanner} userAgent={"pc"} type="home" />
          <Link
            to={"/ordermade/order/list"}
            className="home-banner-link order-banner-link"
          >
            오더메이드 전체보기
          </Link>
        </div>
        <div className="home-banner-wrapper">
          <div className="home-banner-title">
            <b>PQ 펀딩</b> 프로젝트
          </div>
          {/* 펀딩 배너 */}
          <Banner AddData={projectBanner} userAgent={"pc"} type="home" />
          <Link
            to={"/projects/funding/list"}
            className="home-banner-link funding-banner-link"
          >
            PQ 펀딩 전체보기
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HomeBanner;
