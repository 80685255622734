import React, { useEffect, useReducer } from "react";
import StoryWrite from "../StoryWrite";
import { UseStoryWrite } from "../../../hooks/UseStoryWrite";
type Props = {};

const StoryWriteContainer = (props: Props) => {
  const { SubmitStory, state, UpdateData } = UseStoryWrite("write");

  return (
    <StoryWrite
      data={state}
      UpdateData={UpdateData}
      SubmitStory={SubmitStory}
      type="write"
    />
  );
};

export default StoryWriteContainer;
