import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import PaymentPolicy from "../../../components/PaymentPolicy/PaymentPolicy";
import { ProjectType, RewardType } from "../../../interface/interface";
import {
  ChangePaymentDay,
  GetMaxElement,
  TextChangePaymentDay,
  makeComma,
} from "../../../lib/lib";

type Props = {
  data: ProjectType;
  reward: RewardType[];
  donation: number;
  Valid: () => { state: boolean; msg?: string };
  setpopupType: Dispatch<SetStateAction<string | undefined>>;
};

const PaymentConfirm = ({
  data,
  reward,
  donation,
  Valid,
  setpopupType,
}: Props) => {
  const [showErr, setShowErr] = useState<string | undefined>(undefined);
  const [individualCheck, setIndividualCheck] = useState([false, false]);
  const isAll = individualCheck.every((item) => item);
  const paymentDay = ChangePaymentDay(data.period.finish);
  const deliveryData = reward && GetMaxElement(reward, "delivery");
  const price =
    reward.reduce((prev, next) => {
      const { productCost, count } = next;
      const result = productCost * count;
      return prev + result;
    }, 0) +
    (donation ? donation : 0) +
    (deliveryData ? deliveryData.delivery : 0);
  const FundingCheck = useCallback(() => {
    const { state, msg } = Valid();
    if (!state && msg) {
      setShowErr(msg);
      return;
    }
    if (state) {
      setpopupType("payment-submit");
    }
  }, [Valid, setpopupType]);

  return (
    <div className="payment-confirmation">
      <div className="title">최종 결제정보 확인 </div>
      <div className="payment-box">
        <div className="pay-wrapper-box">
          <div className="pay-wrapper">
            <div className="text">배송비 </div>
            <div className="pay">
              {deliveryData ? makeComma(Number(deliveryData.delivery)) : 0}원
            </div>
          </div>
          <div className="pay-wrapper">
            <div className="text">최종금액 </div>
            <div className="pay">{makeComma(Number(price))}원</div>
          </div>
          <div className="pay-wrapper">
            <div className="text">최종결제일 </div>
            <div className="pay">{paymentDay}</div>
          </div>
        </div>
        <div className="text-caution">
          <div className="dot-wrapper">
            <div className="dot"></div>
            <div className="text">
              {" "}
              프로젝트 성공시, {TextChangePaymentDay(data.period.finish)}에{" "}
              <br /> 등록된 정보로 자동으로 결제가 시도됩니다.
            </div>
          </div>
          <div className="dot-wrapper">
            <div className="dot"></div>
            <div className="text">
              {" "}
              프로젝트 실패시 결제가 진행되지 않으며
              <br />
              결제가 실패할 경우 최종결제일 +1일에 재시도됩니다.
            </div>
          </div>
        </div>
        <PaymentPolicy
          individualCheck={individualCheck}
          setIndividualCheck={setIndividualCheck}
          purpose={data.purpose}
        />
      </div>
      <button
        className="funding-btn"
        disabled={!isAll}
        style={
          !isAll
            ? {
                backgroundColor: `var(--grey400)`,
              }
            : undefined
        }
        onClick={() => {
          if (isAll) {
            FundingCheck();
          }
        }}
      >
        {data.purpose === "projects" ? "펀딩" : "오더"} 참여하기
      </button>
      {showErr && (
        <button className="err-btn shake">
          <div className="nextBtn">{showErr}</div>
        </button>
      )}
    </div>
  );
};

export default PaymentConfirm;
