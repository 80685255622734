import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type StateType = {
  productionPath: "test" | "production";
  popup: Popupinterface;
  toast: Toastface;
  isMenu: boolean;
  header: string;
  isLoading: boolean;
};
export interface Toastface {
  active: boolean;
  content: string;
  type?: "error";
}
export interface Popupinterface {
  active: boolean;
  type: string;
  data: any;
}
const initialState: StateType = {
  productionPath: "test",
  popup: {
    active: false,
    type: "report",
    data: "",
  },
  toast: {
    active: false,
    content: "",
  },
  isMenu: false,
  header: "",
  isLoading: false,
};

const configReducer = createSlice({
  name: "config",
  initialState,
  reducers: {
    MenuChange: (state, { payload }: { payload: boolean }) => {
      state.isMenu = payload;
    },
    PopupChange: (state, action: PayloadAction<Popupinterface>) => {
      state.popup = action.payload;
    },
    HeaderChange: (state, { payload }: { payload: string }) => {
      state.header = payload;
    },
    ToastChange: (state, { payload }: { payload: Toastface }) => {
      state.toast = payload;
    },
    LoadingChange: (state, { payload }: { payload: boolean }) => {
      state.isLoading = payload;
    },
  },
});

export const {
  MenuChange,
  PopupChange,
  HeaderChange,
  ToastChange,
  LoadingChange,
} = configReducer.actions;

export default configReducer.reducer;
