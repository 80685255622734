import React from "react";
import PqboxPreview from "../PqboxPreview";
import UsePqboxDetail from "../../../hooks/UsePqboxDetail";
import Loading from "../../../components/Loading/Loading";

const PqboxPreviewContainer = () => {
  const { DetailData } = UsePqboxDetail({ type: "preview" });
  if (DetailData) {
    return <PqboxPreview data={DetailData} />;
  }
  return <Loading initialLoading={true} />;
};

export default PqboxPreviewContainer;
