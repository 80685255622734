import React from "react";
import UsePqboxWrite from "../../../hooks/UsePqboxWrite";
import PqboxWrite from "../PqboxWrite";

type Props = {};

const PqboxFixContainer = (props: Props) => {
  const { ProjectData, data, Update, ScreenRef, Insert, Save } =
    UsePqboxWrite();
  console.log(data);
  return (
    <PqboxWrite
      type="fix"
      ProjectData={ProjectData}
      data={data}
      Update={Update}
      ScreenRef={ScreenRef}
      Insert={Insert}
      Save={Save}
    />
  );
};

export default PqboxFixContainer;
