import React, { useCallback, useEffect } from "react";
import { MyInfoApi } from "../api/user_api";
import { useDispatch } from "react-redux";
import { AllAuth } from "../reducers/auth";
import axios from "axios";

type ReturnType = {
  GetMyInfo: (
    uid: string,
    emailVerified?: boolean
  ) => Promise<{ state: boolean; msg: string }>;
};

const UseUser = (): ReturnType => {
  const dispatch = useDispatch();
  const GetMyInfo = useCallback(
    async (uid: string, emailVerified?: boolean) => {
      const { state, data, msg } = await MyInfoApi(uid);
      if (state) {
        if (emailVerified === false) {
          if (
            data.config.provider === "kakao" ||
            data.config.provider === "naver"
          ) {
            dispatch(AllAuth(Object.assign({ uid }, data)));
            return {
              state: true,
              msg: `${data.profile.nickname}님 환영합니다`,
            };
          } else {
            dispatch(
              AllAuth({
                uid: "",
                profile: {
                  email: "",
                  image: "",
                  nickname: "",
                  quote: "",
                },
                config: {
                  policy: [false, false, false],
                },
              })
            );
            return {
              state: true,
              msg: `이메일 인증이 필요합니다`,
            };
          }
        } else {
          dispatch(AllAuth(Object.assign({ uid }, data)));
          return { state: true, msg: `${data.profile.nickname}님 환영합니다` };
        }
      } else {
        dispatch(
          AllAuth({
            uid: "",
            profile: {
              email: "",
              image: "",
              nickname: "",
              quote: "",
            },
            config: {
              policy: [false, false, false],
            },
          })
        );
        return { state: false, msg: "유저 정보를 가져오는데 실패하였습니다" };
      }
    },
    [dispatch]
  );
  useEffect(() => {
    return () => {};
  }, []);

  return { GetMyInfo };
};

export default UseUser;
