import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: (type?: string) => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
  type: string;
};

const SaveDeletePopup = ({ Active, setCancel, type }: Props) => {
  return (
    <div className="save-popup">
      <div className="title">임시저장을 삭제하시겠습니까?</div>
      <div className="sub">삭제된 임시저장은 영구적으로 삭제됩니다.</div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active(type);
          }}
        >
          삭제하기
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default SaveDeletePopup;
