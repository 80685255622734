import { useCallback, useState } from "react";
import CommentList from "../../../components/Comment/CommentList/CommentList";
import CommentWrite from "../../../components/Comment/CommentWrite/CommentWrite";
import { CommentInter } from "../../../interface/interface";
import { formatDate } from "../../../lib/lib";
import { AuthType } from "../../../reducers/auth";
import NoticeDetailBody from "./NoticeDetailBody";
import "./css/index.css";
import Popup from "../../../components/Popup/Popup";

type Props = {
  data: any;
  CommentData: CommentInter[];
  SubmitComment: (value: string, isScret?: boolean) => Promise<boolean>;
  auth: AuthType;
  FixText: (
    data: CommentInter,
    text: string,
    isSceret?: boolean
  ) => Promise<boolean>;
  RemoveComment: (data: CommentInter) => void;
  ReportComment: (data: CommentInter) => void;
  ReplyComment: (text: string, data: CommentInter) => void;
};

const NoticeDetail = ({
  data,
  CommentData,
  SubmitComment,
  auth,
  FixText,
  RemoveComment,
  ReportComment,
  ReplyComment,
}: Props) => {
  const [popupType, setPopupType] = useState<
    "comment-remove" | "comment-report" | undefined
  >(undefined);
  const [PopupData, setPopupData] = useState<CommentInter | undefined>(
    undefined
  );

  const OnPoopup = useCallback(
    (data: CommentInter, type: "comment-remove" | "comment-report") => {
      setPopupData(data);
      setPopupType(type);
    },
    []
  );
  const ActivePopup = useCallback(() => {
    if (popupType === "comment-remove" && PopupData) {
      RemoveComment(PopupData);
    }
    if (popupType === "comment-report" && PopupData) {
      ReportComment(PopupData);
    }
    setPopupType(undefined);
  }, [popupType, PopupData, ReportComment, RemoveComment]);

  return (
    <main className="notice_detail">
      {data && (
        <div className="wrapper">
          <div className="title">{data.value.title}</div>
          <div className="head">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/projectquestion-2d800.appspot.com/o/config%2Fplaceholder%2FKakaoTalk_Photo_2024-02-14-14-28-03.png?alt=media&token=591febf4-38c5-43bf-ae3f-af2d4b3205e6"
              alt="프로필"
            />
            <div className="name_wrapper">
              <div className="name">프로젝트퀘스천</div>
              <div className="name_sub">관리자</div>
            </div>

            <div className="timestamp">
              {formatDate(data.value.timestamp, "-")}
            </div>
          </div>
          <div className="greybar" />
          <NoticeDetailBody data={data} />
          <div className="comment-section">
            {auth.uid && (
              <CommentWrite
                length={CommentData.length}
                type="notice"
                SubmitComment={SubmitComment}
              />
            )}
            <CommentList
              type="notice"
              data={CommentData}
              myUid={auth.uid}
              FixText={FixText}
              OnPoopup={OnPoopup}
              isMaker={false}
              ReplyComment={ReplyComment}
            />
          </div>
        </div>
      )}
      {popupType && (
        <Popup Active={ActivePopup} type={popupType} setCancel={setPopupType} />
      )}
    </main>
  );
};

export default NoticeDetail;
