import React, { useCallback, useEffect, useState } from "react";
import { CommentInter } from "../interface/interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { AuthProfileType, AuthType } from "../reducers/auth";
import { clientApi } from "../api/config";
import { LoadingChange, ToastChange } from "../reducers/config";
import { useParams } from "react-router-dom";

type Props = { type: "notice" | "reward" | "story"; id: string };

const UseComment = ({ type, id }: Props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const auth = useSelector<RootState, AuthType>((state) => state.auth);
  const [CommentData, setCommentData] = useState<CommentInter[]>([]);
  const GetComment = useCallback(async () => {
    if (type && id) {
      const {
        data: { state, data },
      } = await clientApi.get("/comment/list", {
        params: {
          id,
          type,
        },
      });
      if (state) {
        setCommentData(data);
      }
    }
  }, [type, id]);
  const SubmitComment = useCallback(
    async (value: string, isSceret?: boolean) => {
      dispatch(LoadingChange(true));
      const obj = {
        isBlind: false,
        isDeleted: false,
        isSceret: isSceret ? true : false,
        profile: {
          author: auth.profile.nickname,
          email: auth.profile.email,
          uid: auth.uid,
          image: auth.profile.image,
        },
        state: "comment",
        text: value,
        timestamp: Date.now(),
        whereTo: id,
        purpose: type === "reward" && params.purpose ? params.purpose : "",
        prjtId: type === "reward" && params.prjtId ? params.prjtId : "",
      };
      const {
        data: { state },
      } = await clientApi.post("/comment/write", {
        type,
        id,
        obj,
      });
      if (state) {
        GetComment();
        dispatch(
          ToastChange({ active: true, content: "댓글을 작성하였습니다" })
        );
      }
      dispatch(LoadingChange(false));
      return state;
    },
    [id, type, auth, params]
  );
  const ReplyComment = useCallback(
    async (value: string, data: CommentInter) => {
      dispatch(LoadingChange(true));
      const obj = {
        isBlind: false,
        isDeleted: false,
        profile: {
          author: auth.profile.nickname,
          email: auth.profile.email,
          uid: auth.uid,
          image: auth.profile.image,
        },
        state: "reply",
        text: value,
        timestamp: Date.now(),
        repoter: 0,
        OriginalTo: id,
        whereTo: data.myKey,
        purpose: type === "reward" && params.purpose ? params.purpose : "",
        prjtId: type === "reward" && params.prjtId ? params.prjtId : "",
      };
      const {
        data: { state },
      } = await clientApi.post("/reply/write", {
        type,
        id,
        obj,
      });
      if (state) {
        GetComment();
        dispatch(
          ToastChange({ active: true, content: "답글을 작성하였습니다" })
        );
      }
      dispatch(LoadingChange(false));
    },
    [id, type, auth]
  );
  const FixText = useCallback(
    async (data: CommentInter, text: string, isSceret?: boolean) => {
      dispatch(LoadingChange(true));
      const {
        data: { state, msg },
      } = await clientApi.post("/comment/fix", {
        data,
        text,
        type,
        isSceret,
      });
      if (state) {
        GetComment();
      }
      dispatch(ToastChange({ active: true, content: msg }));
      dispatch(LoadingChange(false));
      return state;
    },
    [type, GetComment]
  );
  const RemoveComment = useCallback(
    async (data: CommentInter) => {
      dispatch(LoadingChange(true));
      const { myKey, whereTo } = data;
      const {
        data: { state, msg },
      } = await clientApi.delete("/comment/remove", {
        params: {
          myKey,
          id: whereTo,
          type,
        },
      });
      if (state) {
        GetComment();
      }
      dispatch(ToastChange({ active: true, content: msg }));

      dispatch(LoadingChange(false));
    },
    [type, GetComment]
  );
  const ReportComment = useCallback(
    async (data: CommentInter) => {
      console.log(data);
      dispatch(LoadingChange(true));
      const {
        data: { state, msg },
      } = await clientApi.put("/comment/report", {
        data,
        type,
        repoter: auth.uid,
      });
      if (state) {
        GetComment();
      }
      dispatch(ToastChange({ active: true, content: msg }));

      dispatch(LoadingChange(false));
    },
    [type, GetComment, auth]
  );

  useEffect(() => {
    GetComment();

    return () => {};
  }, [GetComment]);

  return {
    CommentData,
    SubmitComment,
    FixText,
    RemoveComment,
    ReportComment,
    ReplyComment,
  };
};

export default UseComment;
