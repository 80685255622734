import React, { useCallback } from "react";
import { PurposeType } from "../interface/interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { ScrapApi } from "../api/user_api";
import { ToastChange } from "../reducers/config";
import { UpdateScrap } from "../reducers/auth";

const UseScrap = () => {
  const dispatch = useDispatch();
  const uid = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const scrapList = useSelector<
    RootState,
    undefined | { key: string; purpose: PurposeType }[]
  >((state) => state.auth.scrap);
  const ScrapProject = useCallback(
    (key: string, purpose: PurposeType) => {
      if (uid) {
        ScrapApi(uid, key, purpose).then(({ state, data, msg }) => {
          if (state) {
            dispatch(ToastChange({ active: true, content: msg }));
            dispatch(UpdateScrap(data));
          }
        });
      }
    },
    [uid]
  );

  return { ScrapProject, scrapList };
};

export default UseScrap;
