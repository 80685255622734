import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import "./css/index.css";
import { UserAgentType } from "../../common/common.inerface";
import FilterDropDown from "./components/FilterDropDown";
import CategoryFilter from "../Catagory/CategoryFilter";
import { ListCategory } from "../../hooks/UseList";
import { PurposeType } from "../../interface/interface";

type Props = {
  userAgent: UserAgentType;
  type: string;
  title?: string;
  isPq: boolean;
  setCategory?: any;
  category: number;
  purpose?: PurposeType;
  setIsPq: Dispatch<SetStateAction<boolean>>;
  list: {
    nowData: number;
    list: {
      title: string;
      type?: string;
    }[];
    Update: Dispatch<SetStateAction<any>>;
  }[];
};

const ProjectTop = ({ userAgent, title, type, setIsPq, setCategory, category, isPq, purpose, list }: Props) => {
  const [showPqBoxText, setShowPqBoxText] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPqBoxText(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="project-list-top">
      <div className="project-top-wrapper">
        <div className="title-wrapper">
          
          {title && userAgent !== 'mb' && <div className="title">{title}</div>}
          {userAgent === "mb" && (
            <div className="mb-title-wrapper">
              {title && <div className="title">{title}</div>}
              <div
                className={`pq-box ${isPq && "check-box"}`}
                onClick={() => {
                  setIsPq(!isPq);
                }}
              >

                <div className="left">
                  <div className="check">
                    {isPq && <img src="/assets/join/active-check.svg" alt="체크" />}
                  </div>
                  <div className="text">PQ박스 모아보기</div>
                </div>
                <div className="right">
                  <div className="hover">
                    ?
                    <div className="pq-box-hover">
                      <span>PQ박스란?</span>
                      PQ 펀딩/오더메이드의 <br />
                      콘텐츠 리워드를 <br /> 확인할 수 있는 곳입니다
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          )}
          <CategoryFilter
            categories={ListCategory}
            onCategoryChange={setCategory}
            nowCategory={category}
            purpose={purpose}
          />
        </div>
        
        <div className="project-list-top-right">
          {userAgent !== "mb" && (
            <>
              <div className="filter-btn-wrapper">
                {list.map(({ nowData, list, Update }, idx) => {
                  return (
                    <FilterDropDown
                      nowData={nowData}
                      list={list}
                      Update={Update}
                      key={idx}
                    />
                  );
                })}
              </div>

            </>
          )}
          {userAgent !== "mb" && (
            <div
              className={`pq-box ${isPq && "check-box"}`}
              onClick={() => {
                setIsPq(!isPq);
              }}
            >
              <div className="left">
                <div className="check">
                  {isPq && <img src="/assets/join/active-check.svg" alt="체크" />}
                </div>
                <div className="text">PQ박스 모아보기</div>
              </div>
              <div className="right">
                <div className="hover">
                  ?
                  <div className="pq-box-hover">
                    <span>PQ박스란?</span>
                    PQ 펀딩/오더메이드의 <br />
                    콘텐츠 리워드를 <br /> 확인할 수 있는 곳입니다
                  </div>
                </div>
              </div>
            </div>
          )}
          {showPqBoxText && (
            <div className="pq-box-text">
              PQ박스에서 확인하는 리워드 콘텐츠 😲
            </div>
          )}
        </div>
      </div>
      {userAgent === "mb" && (
        <div className="filter-btn-wrapper">
          {list.map(({ nowData, list, Update }, idx) => {
            return (
              <FilterDropDown
                nowData={nowData}
                list={list}
                Update={Update}
                key={idx}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProjectTop;

export const rewardCategory: {
  title: string;
  type?: string;
}[] = [
    {
      title: "전체보기",
      type: "",
    },
    {
      title: "무조건 리워드만 보기",
      type: "PT10",
    },
    {
      title: "성공시 리워드만 보기",
      type: "PT01",
    },
  ];
export const stateCategory: {
  title: string;
  type?: string;
}[] = [
    {
      title: "전체보기",
      type: "",
    },
    {
      title: "진행중 프로젝트",
      type: "funding",
    },
    {
      title: "종료된 프로젝트",
      type: "finish",
    },
  ];

export const sortArr: {
  title: string;
}[] = [
    {
      title: "최신순",
    },
    {
      title: "인기순",
    },
    {
      title: "마감임박순",
    },
  ];
