import React, { RefObject, useEffect } from "react";
import "./css/index.css";
import PqboxWriteTop from "./components/PqboxWriteTop/PqboxWriteTop";
import { PqboxType, ProjectType } from "../../interface/interface";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PqboxBasicInfo from "./components/PqboxBasicInfo/PqboxBasicInfo";
import { useDispatch } from "react-redux";
import PqboxPeriod from "./components/PqboxPeriod/PqboxPeriod";
import PqboxContent from "./components/PqboxContent/PqboxContent";
type Props = {
  type: "write" | "fix";
  ProjectData?: ProjectType;
  data: PqboxType;
  Update: (type: string, payload: any) => void;
  ScreenRef: RefObject<HTMLDivElement>;
  Insert: () => void;
  Save: () => void;
};

const PqboxWrite = ({
  type,
  ProjectData,
  data,
  Update,
  ScreenRef,
  Insert,
  Save,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation().pathname.split("/");
  const nowpath = location[location.length - 1];
  useEffect(() => {
    if (nowpath === "content" && data.editor && ScreenRef.current) {
      ScreenRef.current.innerHTML = data.editor;
    }
    return () => {};
  }, [nowpath, ScreenRef, data.editor]);

  return (
    <main className="pqbox-write-page">
      <div className="wrapper">
        <PqboxWriteTop
          ProjectData={ProjectData}
          nowpath={nowpath}
          InsertProject={Insert}
          Temporary={Save}
          navigate={navigate}
          data={data}
        />
      </div>
      <div className="grey-line" />
      <div className="wrapper">
        <div className="pqbox-nav-section">
          {nav_layouts.map(({ title, path }, idx) => {
            return (
              <div
                key={idx}
                className={`write-nav ${nowpath === path && `now-nav`}`}
              >
                <p> {title}</p>
              </div>
            );
          })}
        </div>
        <Routes>
          <Route
            path="/"
            element={
              <PqboxBasicInfo
                data={data}
                Update={Update}
                dispatch={dispatch}
                navigate={navigate}
              />
            }
          />
          <Route
            path="/period"
            element={
              <PqboxPeriod
                data={data}
                ProjectData={ProjectData}
                Update={Update}
                dispatch={dispatch}
                navigate={navigate}
              />
            }
          />
          <Route
            path="/content"
            element={<PqboxContent data={data} ScreenRef={ScreenRef} />}
          />
        </Routes>
      </div>
    </main>
  );
};

export default PqboxWrite;

const nav_layouts = [
  {
    title: "1. 기본정보입력",
    path: "write",
  },
  {
    title: "2. 리워드 종류/기간",
    path: "period",
  },
  {
    title: "3. 내용작성",
    path: "content",
  },
];
