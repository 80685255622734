import "./css/index.css";
import { formatDate } from "../../../lib/lib";
import { Link } from "react-router-dom";
import { StoryType } from "../../../interface/interface";
import { Dispatch, SetStateAction } from "react";
import StoryLike from "../StoryLike/StoryLike";
type Props = {
  uid: string | undefined;
  team: string;
  ChangeLike: (key: string) => void;
  datetime: number;
  like: number;
  id: string;
  isLike: boolean;
  userId: string | undefined;
  data: StoryType;
  setPopupType: Dispatch<SetStateAction<      "comment-remove" | "comment-report" | "delete-popup" | undefined>>;
};
const FeadbackOptions = ({
  uid,
  datetime,
  team,
  ChangeLike,
  like,
  id,
  isLike,
  userId,
  data,
  setPopupType
}: Props) => {

  return (
    <>
      <div className="author-info">
        <span className="author-name">{team}</span>
        <span className="grey-text">{formatDate(datetime, "-")}</span>
      </div>
      <div className="edit-container">
        {uid === userId && (
          <>
            <Link to={"/story/fix"} state={data} className="grey-text">
              수정
            </Link>
            <button className="grey-text delete"
          onClick={()=>{
            setPopupType("delete-popup")
          }}
            >삭제</button>
          </>
        )}
      <StoryLike like={like} ChangeLike={ChangeLike} id={id} isLike={isLike} type="detail"/>
      </div>
    </>
  );
};

export default FeadbackOptions;
