import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  ProjectType,
  PurposeType,
  RewardType,
} from "../../../interface/interface";
import PaymentReward from "./PaymentReward";
import { UserAgentType } from "../../../common/common.inerface";
import RewardCard from "../../../components/RewardCard/RewardCard";

type Props = {
  purpose: PurposeType;
  RewardData: RewardType[];
  userAgent: UserAgentType;
  data: ProjectType;
  setRewardData: Dispatch<SetStateAction<RewardType[]>>;
  email: string;
};

const PaymentRewardSection = ({
  purpose,
  RewardData,
  userAgent,
  data,
  setRewardData,
  email,
}: Props) => {
  const [isFix, setisFix] = useState<boolean>(false);
  const [prjtReward, setPrjtReward] = useState<RewardType[]>([]);
  const ChangeCount = useCallback(
    (index: number, count: number) => {
      let clone = RewardData.slice().map((item) => {
        if (item.index === index) {
          return Object.assign(item, { count });
        }
        return item;
      });
      setRewardData(clone);
    },
    [RewardData,setRewardData]
  );

  const ChangeRewardData = useCallback(
    (data: RewardType) => {
      let clone = RewardData.slice();
      const check =
        clone.filter((item) => item.index === data.index).length > 0;
      if (check) {
        setRewardData(clone.filter((item) => item.index !== data.index));
      } else {
        clone.push(data);
        setRewardData(clone);
      }
    },
    [RewardData,setRewardData]
  );
  useEffect(() => {
    let arr = JSON.parse(JSON.stringify(data.rewards.content));
    const changeData = arr.map((item: any) => {
      let payment = Object.assign({}, item.payment);
      for (const key in item.payment) {
        if (Object.prototype.hasOwnProperty.call(item.payment, key)) {
          const element = item.payment[key];
          if (email === element.email) {
            delete payment[key];
          }
        }
      }
      return Object.assign(item, { payment });
    });
    setPrjtReward(changeData);
    return () => {};
  }, [data, email]);

  return (
    <div className="projectpayment-reward">
      <div className="title">
        선택한 리워드
        <button
          className="option-btn"
          style={{
            backgroundColor:
              purpose === "projects" ? "var(--main)" : "var(--red600)",
          }}
          onClick={() => {
            setisFix((prev) => !prev);
          }}
        >
          {isFix ? "수정 완료" : "옵션 수정"}
        </button>
      </div>
      {isFix
        ? prjtReward.map((item, idx) => {
            return (
              <RewardCard
                key={idx}
                data={item}
                type="detail"
                index={idx}
                purpose={purpose}
                RewardData={RewardData?.filter((item) => item.index === idx)[0]}
                ChangeCount={ChangeCount}
                ChangeRewardData={ChangeRewardData}
              />
            );
          })
        : RewardData.map((item, idx) => {
            const { count, payment } = data.rewards.content.filter(
              (data, idx) => idx === item.index
            )[0];
            const number =
              count > 0
                ? payment
                  ? count - Object.values(payment).length
                  : count
                : 1;
            return (
              <PaymentReward
                key={idx}
                data={item}
                userAgent={userAgent}
                number={number}
              />
            );
          })}
    </div>
  );
};

export default PaymentRewardSection;
