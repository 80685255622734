import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";
import { CardType, PurposeType } from "../../interface/interface";

type Props = {
  data: CardType;
  nowCard: CardType | undefined;
  setNowCard: Dispatch<SetStateAction<CardType | undefined>>;
  purpose?: PurposeType;
};

const PayCard = ({ data, nowCard, setNowCard, purpose }: Props) => {
  return (
    <button
      className={`pay-card-box ${
        nowCard && data.PCD_PAYER_ID === nowCard.PCD_PAYER_ID && "pay-card-on"
      }`}
      onClick={() => setNowCard(data)}
      style={
        nowCard &&
        data.PCD_PAYER_ID === nowCard.PCD_PAYER_ID &&
        purpose === "ordermade"
          ? {
              backgroundColor: "var(--red600)",
            }
          : undefined
      }
    >
      <div className="bank">{data.PCD_PAY_CARDNAME}</div>
      <div className="wrapper">
        <div className="number">{data.PCD_PAY_CARDNUM}</div>
        <div className={`check `}>
          {nowCard && data.PCD_PAYER_ID === nowCard.PCD_PAYER_ID && (
            <img src="/assets/common/black-check.svg" alt="" />
          )}
        </div>
      </div>
    </button>
  );
};

export default PayCard;
