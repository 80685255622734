import React, { useState } from "react";
import "./css/index.css";
import { CommentInter } from "../../../interface/interface";
import CommentCard from "./components/CommentCard";
import ReplyCard from "./components/ReplyCard";
import Replycomt from "./components/Replycomt";
type Props = {
  data: CommentInter[];
  myUid?: string;
  FixText: (
    data: CommentInter,
    text: string,
    isSceret?: boolean
  ) => Promise<boolean>;
  OnPoopup: (
    data: CommentInter,
    type: "comment-remove" | "comment-report"
  ) => void;
  isMaker: boolean;
  ReplyComment: (text: string, data: CommentInter) => void;
  type: "notice" | "reward" | "story";
};

const CommentList = ({
  data,
  myUid,
  FixText,
  OnPoopup,
  isMaker,
  ReplyComment,
  type
}: Props) => {
  const [isReply, setIsReply] = useState<string | undefined>(undefined);
  return (
    <div className="comment-list-section">
      {data.map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            <CommentCard
              data={item}
              myUid={myUid}
              FixText={FixText}
              OnPoopup={OnPoopup}
              isMaker={isMaker}
              setIsReply={setIsReply}
              type={type}
            />
            {isReply === item.myKey && (
              <ReplyCard
                ReplyComment={ReplyComment}
                data={item}
                setIsReply={setIsReply}
              />
            )}
            {item.reply &&
              item.reply.map((item) => {
                return (
                  <Replycomt
                    key={idx}
                    data={item}
                    myUid={myUid}
                    OnPoopup={OnPoopup}
                    FixText={FixText}
                  />
                );
              })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CommentList;
