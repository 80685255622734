import React, { Dispatch, SetStateAction, useState } from "react";
import "./css/index.css";
type Props = {
  arr: string[];
  now?: number;
  placeholder: string;
  setNow: Dispatch<SetStateAction<number | undefined>>;
};

const Dropdown = ({ arr, now, placeholder, setNow }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className="drop-down-comp">
      <div
        className={`drop-down-wrapper ${isOpen && "drop-down-open"}`}
        style={
          isOpen
            ? {
                height: `${36 * arr.length}px`,
              }
            : undefined
        }
      >
        <button
          className="now-drop-down"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          {typeof now === "number" ? arr[now] : placeholder}
          <img src="/assets/common/fillter-arrow.svg" alt="arrow" />
        </button>
        {arr.map((item, idx) => {
          return (
            <button
              key={idx}
              onClick={() => {
                setIsOpen(false);
                setNow(idx);
              }}
            >
              {item}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
