import React from "react";
import { NoticeInterface } from "../../common/common.inerface";
import {  formatDateKr } from "../../lib/lib";
import "./css/index.css";
type Props = {
  __nav: (item: NoticeInterface) => void;
  data: NoticeInterface;
};

const NoticeCard = ({ __nav, data }: Props) => {
  return (
    <button className="notice-card" onClick={() => __nav(data)}>
      <div className="card">
        <div className="top">
          <div className="title">{data.title}</div>
        </div>
        <div className="profile">
          <div className="date">{formatDateKr(data.timestamp)}</div>
          <div className="name date">운영관리팀</div>
        </div>
      </div>
    </button>
  );
};

export default NoticeCard;
