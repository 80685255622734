import React, { useCallback } from "react";
import Link from "./Link";
import UploadFile from "./UploadFile";
import Youtube from "./Youtube";
import { popupinter } from "../interface/editorinterface";
import CardNews from "./CardNews";
import Video from "./Video";

function Popup({
  isUp: { status, type },
  setIsUp,
  dataKey,
}: {
  isUp: popupinter;
  setIsUp: React.Dispatch<
    React.SetStateAction<{
      status: boolean;
      type: string;
    }>
  >;
  dataKey: string;
}) {
  const __close = useCallback(() => {
    setIsUp({
      status: false,
      type: "",
    });
  }, [setIsUp]);
  return status ? (
    <div className="popup-pack">
      <div className="back-black" />
      <div
        className="popup-main"
        style={type === "VIDEO" ? { width: "663px" } : undefined}
      >
        {type === "LINK" ? (
          <Link __close={__close} />
        ) : type === "FILE" ? (
          <UploadFile __close={__close} temKey={dataKey} />
        ) : type === "NEWS" ? (
          <CardNews __close={__close} temKey={dataKey} />
        ) : (
          // type ==="VIDEO" ?
          // <Video __close={__close} temKey={dataKey}/>
          // :

          <Youtube __close={__close} />
        )}
      </div>
    </div>
  ) : (
    <div />
  );
}

export default Popup;
