import React, { useState } from "react";
import "./css/index.css";
import { UserAgentType } from "../../../common/common.inerface";
type Props = {
  userAgent: UserAgentType;
  NavSearch: (text: string) => void;
};

const HomeSearch = ({ userAgent, NavSearch }: Props) => {
  const [value, setValue] = useState<string>("");
  return (
    <section className="home-search-section">
      <div className="search-title">아이디어를 발견하세요!</div>
      <div className="search-input-wrapper">
        <form
          className="search-input"
          onSubmit={(e) => {
            e.preventDefault();
            NavSearch(value);
          }}
        >
          <input
            type="text"
            placeholder="검색어를 입력해주세요"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
          <button className="search-btn">
            <img src="/assets/home/search-btn.svg" alt="검색" />
          </button>
        </form>
      </div>
    </section>
  );
};

export default HomeSearch;
