import { createSlice } from "@reduxjs/toolkit";
import {
  GatekeepingType,
  ProjectType,
  PurposeType,
  SliderType,
} from "../../interface/interface";

export type PolicyType =
  | "terms"
  | "private"
  | "offer"
  | "marketing"
  | "maker"
  | "donate";

export type PolicyStateType = {
  [key in PolicyType]: string;
};

type StateType = {
  gateKeeping: GatekeepingType[];
  banner: {
    line: LineAddType[];
    slider: SliderType[];
    orderslider: SliderType[];
  };
  scrapList: ProjectType[];
  popular: {
    projects: ProjectType[] | undefined;
    ordermade: ProjectType[] | undefined;
  };
  inquiry: MyInquiryType[];
  policy: PolicyStateType;
};
export interface LineAddType {
  ad_thumbnail: string;
  bg_color: string;
  btn_label: string;
  click: number;
  ctts_ctts: string;
  ctts_link: string;
  ctts_tit: string;
}

export interface MyInquiryType {
  isAnswer: boolean;
  key: string;
  prjtKey: string;
  purpose: PurposeType;
  prjt: ProjectType;
}

const initialState: StateType = {
  gateKeeping: [],
  banner: {
    line: [],
    slider: [],
    orderslider: [],
  },
  scrapList: [],
  popular: {
    projects: undefined,
    ordermade: undefined,
  },
  inquiry: [],
  policy: {
    terms: "",
    private: "",
    offer: "",
    marketing: "",
    maker: "",
    donate: "",
  },
};

const dataReducer = createSlice({
  name: "data",
  initialState,
  reducers: {
    // Remove_Comment_Id: (
    //   state,
    //   { payload }: { payload: string | undefined }
    // ) => {
    //   state.removeComment = payload;
    // },
    // ScrapInit: (state, { payload }: { payload: any }) => {
    //   state.scrap = payload;
    // },
    SetGateKeeping: (state, { payload }: { payload: GatekeepingType[] }) => {
      state.gateKeeping = payload;
    },
    SetScrapList: (state, { payload }: { payload: ProjectType[] }) => {
      state.scrapList = payload;
    },
    SetPolicy: (state, { payload }: { payload: PolicyStateType }) => {
      state.policy = payload;
    },
    SetMyInquiryList: (state, { payload }: { payload: MyInquiryType[] }) => {
      state.inquiry = payload;
    },
    SetBanner: (
      state,
      {
        payload,
      }: {
        payload: {
          line: LineAddType[];
          slider: SliderType[];
          orderslider: SliderType[];
        };
      }
    ) => {
      state.banner = payload;
    },
    SetPopular: (
      state,
      {
        payload,
      }: {
        payload: {
          projects: ProjectType[];
          ordermade: ProjectType[];
        };
      }
    ) => {
      state.popular = payload;
    },
  },
});

export const {
  SetGateKeeping,
  SetBanner,
  SetScrapList,
  SetPopular,
  SetMyInquiryList,
  SetPolicy,
} = dataReducer.actions;

export default dataReducer.reducer;
