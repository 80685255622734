import React, { useState, RefObject } from "react";
import "./css/index.css";
import EdiHeader from "./components/EdiHeader";
import Screen from "./components/Screen";
import Popup from "./components/Popup";
import { useDispatch } from "react-redux";
import { PurposeType } from "../../interface/interface";

type Props = {
  ScreenRef: RefObject<HTMLDivElement>;
  dataKey: string;
  purpose: PurposeType | "rewards";
};

/** 에디터 */
function Editor({ ScreenRef, dataKey, purpose }: Props) {
  const dispatch = useDispatch();

  const [isUp, setIsUp] = useState({
    status: false,
    type: "",
  });
  return (
    <div className="editor">
      <div className="editor-wrapper">
        <EdiHeader
          setIsUp={setIsUp}
          dispatch={dispatch}
          dataKey={dataKey}
          purpose={purpose}
        />
        <Screen ScreenRef={ScreenRef} />
      </div>
      <Popup isUp={isUp} setIsUp={setIsUp} dataKey={dataKey} />
    </div>
  );
}

export default Editor;
