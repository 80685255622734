import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: () => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
};

const RefundPopup = ({ Active, setCancel }: Props) => {
  return (
    <div className="save-popup">
      <div className="title">펀딩을 철회하시겠습니까?</div>
      <div className="sub">펀딩 철회시 다시 펀딩에 참여 가능합니다</div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active();
          }}
        >
          철회하기
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default RefundPopup;
