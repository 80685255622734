import React from "react";
import PqboxWrite from "../PqboxWrite";
import UsePqboxWrite from "../../../hooks/UsePqboxWrite";

type Props = {};

const PqboxWriteContainer = (props: Props) => {
  const { ProjectData, data, Update, ScreenRef, Insert,Save } = UsePqboxWrite();
  return (
    <PqboxWrite
      type="write"
      ProjectData={ProjectData}
      data={data}
      Update={Update}
      ScreenRef={ScreenRef}
      Insert={Insert}
      Save={Save}
    />
  );
};

export default PqboxWriteContainer;
