import React from "react";
import "./css/index.css";
import LineAdd from "../../components/main/LineAdd/LineAdd";
import ProjectOpenSection from "../../components/main/ProjectOpenSection/ProjectOpenSection";
import HomeSearch from "../../components/Home/HomeSearch/HomeSearch";
import HomeBanner from "../../components/Home/HomeBanner/HomeBanner";
import { UserAgentType } from "../../common/common.inerface";
import MainStoryList from "../../components/main/MainList/MainStoryList";
import { StoryType } from "../../interface/interface";
import { authStoryType } from "../../reducers/auth";
type Props = {
  userAgent: UserAgentType;
  ChangeLike: (key: string) => void;
  user_story?: authStoryType;
  StoryData: StoryType[];
  NavSearch:(text:string)=>void;
};

const Home = ({ userAgent, ChangeLike, user_story, StoryData,NavSearch }: Props) => {
  return (
    <main className="home-page">
      <HomeSearch userAgent={userAgent} NavSearch={NavSearch}/>
      <HomeBanner userAgent={userAgent} />
      <LineAdd />
      <div className="home-wrapper">
        <MainStoryList
          ChangeLike={ChangeLike}
          user_story={user_story}
          data={StoryData}
        />
      </div>
      <ProjectOpenSection />
    </main>
  );
};

export default Home;
