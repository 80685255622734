import {
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { SignInterface } from "../common/common.inerface";
import { Fauth } from "../config/firebaseApp";
import { NetworkError, clientApi } from "./config";
import { AuthProfileType } from "../reducers/auth";
import {
  CardType,
  ProjectStateType,
  PurposeType,
  RewardType,
} from "../interface/interface";

export type ReturnType = {
  state: boolean;
  data?: any;
  msg: string;
  err?: any;
};

const MyInfoApi = (uid: string): Promise<ReturnType> => {
  return clientApi
    .get("/user/my/info", {
      params: {
        uid,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const MyGateekeepingApi = (uid: string): Promise<ReturnType> => {
  return clientApi
    .get("/user/gatekeeping", {
      params: {
        uid,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const AuthorApi = (uid: string): Promise<ReturnType> => {
  return clientApi
    .get("/user/info", {
      params: {
        uid,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const SaveRemoveApi = (uid: string, type: PurposeType): Promise<ReturnType> => {
  return clientApi
    .delete("/user/save/remove", {
      data: {
        uid,
        type,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};

const CheckEmailApi = (email: string): Promise<ReturnType> => {
  return clientApi
    .get("/user/check/email", {
      params: {
        email,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const CheckNicknameApi = (nickname: string): Promise<ReturnType> => {
  return clientApi
    .get("/user/check/nickname", {
      params: {
        nickname,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const CheckProvider = (email: string): Promise<ReturnType> => {
  return clientApi
    .get("/user/check/provider", {
      params: {
        email,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const EmailSignUp = (info: SignInterface): Promise<ReturnType> => {
  return clientApi
    .post("/user/new", {
      data: info,
    })
    .then(async ({ data }) => {
      if (data.state) {
        const signUser = await signInWithEmailAndPassword(
          Fauth,
          info.email,
          info.password
        );
        await sendEmailVerification(signUser.user);
        return data;
      }
      return data;
    })
    .catch((err) => {
      console.log(err);
      return NetworkError;
    });
};

const ScrapApi = (
  uid: string,
  key: string,
  purpose: PurposeType
): Promise<ReturnType> => {
  return clientApi
    .post("/user/scrap", {
      uid,
      key,
      purpose,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return NetworkError;
    });
};
const SocialSignUp = (info: SignInterface): Promise<ReturnType> => {
  return clientApi
    .post("/user/social/new", {
      data: info,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return NetworkError;
    });
};
const UserProfileApi = (
  info: AuthProfileType,
  policy: boolean[],
  key: string
): Promise<ReturnType> => {
  return clientApi
    .post("/user/info/change", {
      data: info,
      policy,
      key,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const UserProjectChangeApi = (
  pid: string,
  uid: string,
  state: ProjectStateType,
  purpose: PurposeType
): Promise<ReturnType> => {
  return clientApi
    .post("/state/change", {
      pid,
      uid,
      state,
      purpose,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const GetMySponsor = (pid: string): Promise<ReturnType> => {
  return clientApi
    .get("/user/sponsor", {
      params: {
        pid,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const GetMyScrapData = (
  list: { purpose: PurposeType; key: string }[]
): Promise<ReturnType> => {
  return clientApi
    .get("/user/scrap/prjt", {
      params: {
        list,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const GetMyInquiryApi = (uid: string): Promise<ReturnType> => {
  return clientApi
    .get("/user/inquiry", {
      params: {
        uid,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const GetMyGraph = (key: string, purpose: PurposeType): Promise<ReturnType> => {
  return clientApi
    .get("/user/graph", {
      params: {
        key,
        purpose,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
const CancelPayment = (
  usrId: string,
  key: string,
  purpose: PurposeType,
  email: string,
  projectid: string,
  amount: number,
  rewardidx: RewardType[]
): Promise<ReturnType> => {
  return clientApi
    .post("/payment/cancel", {
      usrId,
      pid: key,
      email,
      amount,
      projectid,
      purpose,
      rewardidx,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      console.log(err);
      return NetworkError;
    });
};
const ChangeCard = (
  card: CardType,
  key: string,
  uid: string
): Promise<ReturnType> => {
  return clientApi
    .put("/payment/card", {
      card,
      key,
      uid,
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch((err) => {
      return NetworkError;
    });
};
export {
  CheckEmailApi,
  CheckNicknameApi,
  EmailSignUp,
  MyInfoApi,
  SocialSignUp,
  CheckProvider,
  UserProfileApi,
  SaveRemoveApi,
  AuthorApi,
  MyGateekeepingApi,
  UserProjectChangeApi,
  GetMySponsor,
  ScrapApi,
  GetMyScrapData,
  CancelPayment,
  ChangeCard,
  GetMyInquiryApi,
  GetMyGraph,
};
