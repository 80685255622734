import React, { useCallback, useState } from "react";
import "./css/index.css";
import { RewardDetailType } from "../../hooks/UsePqboxDetail";
import PqboxDetailTop from "../../components/Pqbox/PqboxDetailTop/PqboxDetailTop";
import Screen from "../../components/Screen/Screen";
import { CommentInter } from "../../interface/interface";
import CommentWrite from "../../components/Comment/CommentWrite/CommentWrite";
import CommentList from "../../components/Comment/CommentList/CommentList";
import { AuthType } from "../../reducers/auth";
import Popup from "../../components/Popup/Popup";
type Props = {
  data: RewardDetailType;
  auth: AuthType;
  CommentData: CommentInter[];
  SubmitComment: (value: string, isScret?: boolean) => Promise<boolean>;
  FixText: (
    data: CommentInter,
    text: string,
    isSceret?: boolean
  ) => Promise<boolean>;
  RemoveComment: (data: CommentInter) => void;
  ReportComment: (data: CommentInter) => void;
  ReplyComment: (text: string, data: CommentInter) => void;
};

const PqboxDetail = ({
  data: { data, prjtData },
  CommentData,
  SubmitComment,
  auth,
  FixText,
  RemoveComment,
  ReportComment,
  ReplyComment,
}: Props) => {
  const [popupType, setPopupType] = useState<
    "comment-remove" | "comment-report" | undefined
  >(undefined);
  const [PopupData, setPopupData] = useState<CommentInter | undefined>(
    undefined
  );

  const OnPoopup = useCallback(
    (data: CommentInter, type: "comment-remove" | "comment-report") => {
      setPopupData(data);
      setPopupType(type);
    },
    []
  );
  const ActivePopup = useCallback(() => {
    if (popupType === "comment-remove" && PopupData) {
      RemoveComment(PopupData);
    }
    if (popupType === "comment-report" && PopupData) {
      ReportComment(PopupData);
    }
    setPopupType(undefined);
  }, [popupType, PopupData, ReportComment, RemoveComment]);

  return (
    <main
      className="pqbox-detail-page"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      onSelect={(e) => {
        e.preventDefault();
      }}
    >
      <PqboxDetailTop data={data} prjtData={prjtData} />
      <div className="pqbox-detail-content">
        <div className="pqbox-detail-wrapper">
          <Screen data={data.editor} />
        </div>
      </div>
      <div className="pqbox-detail-wrapper">
        <CommentWrite
          length={CommentData.length}
          SubmitComment={SubmitComment}
          type="reward"
        />
        <CommentList
          data={CommentData}
          myUid={auth.uid}
          FixText={FixText}
          OnPoopup={OnPoopup}
          isMaker={auth.uid === prjtData.profile.uid}
          ReplyComment={ReplyComment}
          type="reward"
        />
      </div>
      <div className="pqbox-detail-bottom-period">
        <div className="pqbox-detail-wrapper">
          <div className="title">공개 마감일</div>
          <div className="period">{data.period.finish} 까지 공개</div>
        </div>
      </div>
      {popupType && (
        <Popup Active={ActivePopup} type={popupType} setCancel={setPopupType} />
      )}
    </main>
  );
};

export default PqboxDetail;
