import React from "react";
import { Link } from "react-router-dom";
import EmtyList from "../../EmtyList/EmtyList";
import { StoryType } from "../../../interface/interface";
import StoryCard from "../../Story/StoryCard/StoryCard";
import { authStoryType } from "../../../reducers/auth";

type Props = {
  data: StoryType[];
  ChangeLike: (key: string) => void;
  user_story?: authStoryType;
};

const MainStoryList = ({ data, ChangeLike, user_story }: Props) => {
  return (
    <section className="main-list-section">
      <div className="title-section">
        <b>PQ 스토리</b>
        <Link to={`/story`}>전체보기</Link>
      </div>
      {data.length > 0 ? (
        <div className="story-list-section">
          {data.map((item, idx) => {
            return (
              <StoryCard
                key={idx}
                data={item}
                ChangeLike={ChangeLike}
                isLike={user_story && user_story[item.key] ? true : false}
              />
            );
          })}
        </div>
      ) : (
        <div className="main-list-emty-wrapper">
          <EmtyList title="아직 작성된 스토리가 없습니다" sub={``} />
        </div>
      )}
    </section>
  );
};

export default MainStoryList;
