import React from "react";
import PqboxDetail from "../PqboxDetail";
import { UserAgentType } from "../../../common/common.inerface";
import UsePqboxDetail from "../../../hooks/UsePqboxDetail";
import UseComment from "../../../hooks/UseComment";
import Loading from "../../../components/Loading/Loading";

type Props = {
  userAgent: UserAgentType;
};

const PqboxDetailContainer = (props: Props) => {
  const { DetailData, id, auth } = UsePqboxDetail({ type: "detail" });
  const {
    CommentData,
    SubmitComment,
    FixText,
    RemoveComment,
    ReportComment,
    ReplyComment,
  } = UseComment({ type: "reward", id: id ? id : "" });
  if (DetailData) {
    return (
      <PqboxDetail
        data={DetailData}
        auth={auth}
                CommentData={CommentData}
        SubmitComment={SubmitComment}
        FixText={FixText}
        RemoveComment={RemoveComment}
        ReportComment={ReportComment}
        ReplyComment={ReplyComment}
      />
    );
  }
  return <Loading initialLoading={true} />;
};

export default PqboxDetailContainer;
