import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";
import { ProjectType, PurposeType } from "../../../interface/interface";
import { reward_state } from "../../../lib/lib";
import { UserAgentType } from "../../../common/common.inerface";
import { NavigateFunction } from "react-router-dom";
type Props = {
  data: ProjectType;
  userAgent: UserAgentType;
  navigate: NavigateFunction;
  projectId?: string;
  purpose?: PurposeType;

  setPopupType?: Dispatch<
    SetStateAction<
      | {
          type: string;
          data: any;
        }
      | undefined
    >
  >;
};

const PqboxManageCard = ({
  data,
  userAgent,
  navigate,
  purpose,
  setPopupType,
  projectId,
}: Props) => {
  const {
    images: { thumbnail },
    title,
    period: { start, finish },
    category,
    state,
    profile: { author },
    key,
    message,
  } = data;
  const strartformat = start.split("-");
  const finishfotmat = finish.split("-");
  return (
    <div className="pqbox-manage-card">
      <div className="card-left">
        {" "}
        <img src={thumbnail} alt="" />
        {userAgent === "pc" ? (
          <>
            {" "}
            <div className="title">{title}</div>
            {/* <div className="author">{author}</div> */}
            <div className="period">
              {strartformat[0].substring(2, 4)}/{strartformat[1]}/
              {strartformat[2]} ~ {finishfotmat[0].substring(2, 4)}/
              {finishfotmat[1]}/{finishfotmat[2]}
            </div>
            <div className="category">
              <img
                src={`/assets/pqcard/${reward_category_arr[category].img}.svg`}
                alt=""
              />{" "}
              {reward_category_arr[category].title}
            </div>
            <div className={`pqbox-card-state ${state}-card`}>
              {reward_state(state)}
            </div>
          </>
        ) : (
          <>
            <div className={`pqbox-card-state ${state}-card`}>
              {reward_state(state)}
            </div>
            <div className="title">{title}</div>
            {/* <div className="author">{author}</div> */}
            <div className="period">
              {strartformat[0].substring(2, 4)}/{strartformat[1]}/
              {strartformat[2]} ~ {finishfotmat[0].substring(2, 4)}/
              {finishfotmat[1]}/{finishfotmat[2]}
            </div>
            <div className="category">
              <img
                src={`/assets/pqcard/${reward_category_arr[category].img}.svg`}
                alt=""
              />{" "}
              {reward_category_arr[category].title}
            </div>
          </>
        )}
      </div>
      <div className="right-btn-wrapper">
        <button
          className="view-btn"
          onClick={() => {
            if (state === "funding") {
              navigate(`/pqbox/detail/${purpose}/${projectId}/${key}`);
            } else {
              navigate(`/pqbox/preview/${purpose}/${projectId}/${key}`);
            }
          }}
        >
          리워드 보기
        </button>
        {state === "reject" && (
          <button
            className="reject-btn"
            onClick={() => {
              setPopupType &&
                setPopupType({
                  type: "pqbox-reject",
                  data: message,
                });
            }}
          >
            거절 사유
          </button>
        )}
        {userAgent === "pc" ? (
          <>
            {(state === "save" || state === "reject" || state === "ready") && (
              <button
                className="fix-btn"
                onClick={() => {
                  navigate(`/pqbox/${key}/fix`, {
                    state: {
                      purpose,
                      projectId,
                    },
                  });
                }}
              >
                리워드 수정
              </button>
            )}
            <img
              src="/assets/pqbox/cancel.svg"
              alt="삭제"
              onClick={() => {
                setPopupType &&
                  setPopupType({
                    type: "delete-popup",
                    data,
                  });
              }}
            />
          </>
        ) : (
          <button
            className="fix-btn"
            onClick={() => {
              setPopupType &&
                setPopupType({
                  type: "delete-popup",
                  data,
                });
            }}
          >
            리워드 삭제
          </button>
        )}
      </div>
    </div>
  );
};

export default PqboxManageCard;

export const reward_category_arr = [
  {
    img: "video",
    title: "영상",
  },
  {
    img: "image",
    title: "이미지",
  },
  {
    img: "text",
    title: "텍스트",
  },
  {
    img: "file",
    title: "파일",
  },
];
