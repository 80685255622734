import React, { useCallback, useState } from "react";
import { PqboxType } from "../../../../interface/interface";
import PqboxBasic from "../../../../components/Pqbox/PqboxWrite/PqboxBasic/PqboxBasic";
import StepBtn from "../../../../components/WriteProject/StepBtn/StepBtn";
import { AppDispatch } from "../../../..";
import { ToastChange } from "../../../../reducers/config";
import { NavigateFunction } from "react-router-dom";

type Props = {
  data: PqboxType;
  Update: (type: string, payload: any) => void;
  dispatch: AppDispatch;
  navigate: NavigateFunction;
};

const PqboxBasicInfo = ({ data, Update, dispatch, navigate }: Props) => {
  const [error, seterror] = useState<string | undefined>(undefined);
  const {
    title,
    images: { thumbnail },
  } = data;
  const info_layouts = [
    {
      title: "리워드 제목",
      type: "input",
      placeholder: "내용 입력",
      patchType: "title",
      subTitle: `${title.length}/40`,
      value: title,
      error: error === "title" ? `` : undefined,
    },
    {
      title: "대표 썸네일 이미지 등록",
      patchType: "thumbnail",
      type: "file",
      placeholder: "jpg, png만 등록 가능 / 5mb 이하",
      value: thumbnail,
      error: error === "thumbnail" ? `` : undefined,
      width: 326,
      hieght: 183,
    },
  ];

  const CheckStep = useCallback(() => {
    if (title.length < 1) {
      seterror("title");
      dispatch(
        ToastChange({
          active: true,
          content: "프로젝트 제목을 입력해주세요",
          type: "error",
        })
      );
      return;
    }
    if (thumbnail.length < 1) {
      seterror("thumbnail");
      dispatch(
        ToastChange({
          active: true,
          content: "썸네일을 등록해주세요",
          type: "error",
        })
      );
      return;
    }
    navigate("./period", {
      state: data,
    });
  }, [data, dispatch, navigate]);
  return (
    <div className="pqbox-write-basic">
      <div className="write-pqbox-title">
        <span>01</span>
        기본정보입력
      </div>
      <PqboxBasic
        title="기본 정보"
        type="basic"
        Update={Update}
        data={data}
        layouts={info_layouts}
      />
      <StepBtn CheckStep={CheckStep} title="다음으로" type={"gradient"} />
    </div>
  );
};

export default PqboxBasicInfo;
