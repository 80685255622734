import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: () => void;
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
};

const CommentRemovePopup = ({ Active, setCancel }: Props) => {
  return (
    <div className="remove-popup">
      <div className="title">댓글을 삭제하시겠습니까?</div>
      <div className="sub">
    댓글을 삭제하시겠습니까? <br/>
삭제된 댓글은 복구할 수 없습니다
      </div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active();
          }}
        >
          삭제하기
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default CommentRemovePopup;
