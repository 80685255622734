import React, { useCallback } from "react";
import "./css/index.css";
import { FileReaderBase64, Fileread } from "../../../lib/lib";
import { PurposeType } from "../../../interface/interface";
type Props = {
  title?: string;
  placeholder?: string;
  Update: (type: string, payload: any, idx?: number) => void;
  type: string;
  value: string;
  idx?: number;
  error?: string;
  path: string;
  purpose?: PurposeType;
  RemoveFile?: (idx: number) => void;
};

const PaymentFile = ({
  title,
  placeholder,
  Update,
  type,
  value,
  idx,
  error,
  path,
  purpose,
  RemoveFile,
}: Props) => {
  const getImageFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const pal = e.target.files[0];
        const file = await Fileread(pal);
        const url = await FileReaderBase64(file, `${path}/${pal.name}`);
        Update(
          type,
          {
            url,
            name: pal.name,
          },
          idx
        );
      }
    },
    [Update, type, idx, path]
  );
  return (
    <div className={`common-input ${error !== undefined && `input-error`}`}>
      {title && <div className="common-input-title">{title}</div>}
      <label
        className={`common-file-label ${value && "file-insert"}`}
        htmlFor={`common-file-label${type}-${idx}`}
        style={
          purpose === "ordermade" && value
            ? { backgroundColor: "var(--red100)" }
            : undefined
        }
      >
        <div
          className="title"
          style={
            purpose === "ordermade" && value
              ? { color: "var(--red600)" }
              : undefined
          }
        >
          {placeholder}
        </div>
        {!value && (
          <img src="/assets/project/write/file-upload.svg" alt="업로드" />
        )}
      </label>
      <input
        id={`common-file-label${type}-${idx}`}
        type="file"
        accept=".jpg, .png"
        size={5000000}
        style={{
          display: "none",
        }}
        onChange={getImageFile}
      />
      {value && RemoveFile && (
        <button
          className="remove-btn"
          onClick={() => {
            if (typeof idx === "number") {
              RemoveFile(idx);
            }
          }}
        >
          <img src="/assets/common/cancel.svg" alt="삭제" />
        </button>
      )}
    </div>
  );
};

export default PaymentFile;
