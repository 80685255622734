import React, { Dispatch, SetStateAction } from "react";

type Props = {
  setCancel?: Dispatch<SetStateAction<string | undefined>>;
  data?: any;
};

const PqboxRejectPopup = ({ setCancel, data }: Props) => {
  return (
    <div className="reject-popup">
      <div className="title">리워드 거절사유 확인하기</div>
      <div className="sub">
        리워드 거절사유 확인 후 <br />
        다시 리워드를 수정해주세요
      </div>
      <div className="reject-content">{data}</div>
      <div className="btn-wrapper">
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          닫기
        </button>
      </div>
    </div>
  );
};

export default PqboxRejectPopup;
