import React, { Dispatch, SetStateAction, useState } from "react";

type Props = {
  nowData: number;
  list: {
    title: string;
    type?: string;
  }[];
  Update: Dispatch<SetStateAction<any>>;
};

const FilterDropDown = ({ nowData, list, Update }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <div className="filter-btn-section">
        <div
          className={`filter-button ${isOpen && "open-filter"} ${
            !nowData && "color"
          }`}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <img
            src="/assets/common/fillter-arrow.svg"
            alt=""
            className={`${nowData && "none-arrow"} ${isOpen && "open-arrow"}`}
          />
          <button className="now-filter"> {list[nowData].title}</button>
          {list.map((item, idx) => {
            return (
              <button
                className={nowData === idx ? "active" : ""}
                onClick={() =>
                  typeof item.type === "string"
                    ? Update(item.type)
                    : Update(idx)
                }
                key={idx}
              >
                {item.title}
              </button>
            );
          })}
        </div>
      </div>
      {isOpen && (
        <div
          className="filter-back-section"
          onClick={() => {
            setIsOpen(false)
          }}
        />
      )}
    </>
  );
};

export default FilterDropDown;
