import React from "react";
import "./css/index.css";
import { ReactComponent as VideoSvg } from "./svg/video.svg";
import { ReactComponent as ImgSvg } from "./svg/img.svg";
import { ReactComponent as TextSvg } from "./svg/text.svg";
import { ReactComponent as FileSvg } from "./svg/file.svg";
type Props = {
  now: number;
      Update: (type: string, payload: any) => void;
};

const PqboxCategory = ({ now ,Update}: Props) => {
  return (
    <div className="pqbox-category-content-wrapper">
      {layouts.map(({ img, title }, idx) => {
        return (
          <button
            className={`category-btn ${idx === now && "active-category"}`}
            key={idx}
            onClick={() => {
      Update("category",idx)
            }}
          >
            {img}
            <div className="category-title">{title}</div>
          </button>
        );
      })}
    </div>
  );
};

export default PqboxCategory;

const layouts = [
  {
    img: <VideoSvg />,
    title: "영상",
  },
  {
    img: <ImgSvg />,
    title: "이미지",
  },
  {
    img: <TextSvg />,
    title: "텍스트",
  },
  {
    img: <FileSvg />,
    title: "파일",
  },
];
