import React from "react";
import "./css/index.css";
type Props = {
  NavWrite: () => void;
  isManager:boolean;
};

const PqboxListEmty = ({ NavWrite ,isManager}: Props) => {
  return (
    <div className="pqbox-list-emty">
      <img src="/assets/pqbox/emty.svg" alt="emty" />
      <div className="title">작성된 리워드가 없습니다</div>
      <div className="sub">{isManager ? "새로운 리워드를 작성해주세요" :"아직 작성된 리워드가 없습니다."}</div>
  {isManager && 
      <button className="new-reward-btn" onClick={NavWrite}>
        새로운 리워드 작성
      </button>}
    </div>
  );
};

export default PqboxListEmty;
