import React, { useCallback, useState } from "react";
import CommonFile from "../../Common/CommonInput/CommonFile";
import { GetSelection } from "../../../lib/lib";

type Props = {
  __close: () => void;
  temKey: string;
};

const CardNews = ({ __close, temKey }: Props) => {
  const [Data, setData] = useState<string[]>([]);
  const Update = (type: string, payload: any, idx?: number) => {
    if (idx !== undefined) {
      const clone = Data.slice();
      if (clone[idx]) {
        clone[idx] = payload;
      } else {
        clone.push(payload);
      }
      setData(clone);
    }
  };
  const InsertNews = useCallback(() => {
    const screen = document.getElementById("screen");
    let newsElement = document.createElement("div");
    newsElement.className = "news-template";
    newsElement.contentEditable = "false";
    let newsImageWrapper = document.createElement("div");
    let imgWrapper = document.createElement("div");
    let pageWrapper = document.createElement("div");
    newsImageWrapper.className = "news-image-wrapper";
    imgWrapper.className = "news-image-section";
    pageWrapper.className = "news-page-wrapper";
    Data.forEach((item, idx) => {
      let imageElement = document.createElement("img");
      let newPage = document.createElement("div");
      imageElement.src = item;
      imageElement.className = "news-image";
      if (idx === 0) {
        newPage.style.opacity = `1`;
      }
      newPage.addEventListener("click", () => {
        imgWrapper.style.transform = `translateX(-${idx * 100}%)`;
        Array.from(
          pageWrapper.children as HTMLCollectionOf<HTMLElement>
        ).forEach((item, index) => {
          if (idx === index) {
            item.style.opacity = `1`;
          } else {
            item.style.opacity = `0.2`;
          }
        });
      });
      pageWrapper.appendChild(newPage);
      imgWrapper.appendChild(imageElement);
    });
    imgWrapper.style.gridTemplateColumns = `repeat(${Data.length} , 100%)`;
    newsImageWrapper.appendChild(imgWrapper);
    newsElement.appendChild(newsImageWrapper);
    newsElement.appendChild(pageWrapper);
    const text = document.createElement("div");
    const selection = GetSelection();
    if (selection) {
      selection.insertNode(text);
      selection.insertNode(newsElement);
    } else {
      if (screen) {
        screen.appendChild(newsElement);
        screen.appendChild(text);
      }
    }
  }, [Data]);

  return (
    <div className="popup-wrapper card-popup">
      <img
        src="/assets/editor/cancel.svg"
        alt=""
        className="cancel"
        onClick={__close}
      />
      <div className="popup-title">카드뉴스 업로드</div>
      <div className="popup-sub">
        최대 10개의 이미지를 카드뉴스 형식으로 업로드합니다
      </div>
      <div className="news-popup-content">
        {Array(Data.length > 0 ? Data.length + 1 : 1)
          .fill(0)
          .map((item, idx) => {
            return (
              <CommonFile
                key={idx}
                title=""
                placeholder="jpg, png만 등록 가능 / 5mb 이하"
                type=""
                value={Data[idx] ? Data[idx] : ""}
                path=""
                idx={idx}
                Update={Update}
                width={724}
                height={406}
              />
            );
          })}
      </div>
      <button className="news-upload-btn" onClick={InsertNews}>
        업로드
      </button>
    </div>
  );
};

export default CardNews;
