import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { UserAgentType } from "../../common/common.inerface";

/** 회원가입 약관동의 */
type Props = {
  dispatch: React.Dispatch<{
    payload: any;
    type: string;
  }>;
  userAgent: UserAgentType;
  data: boolean[];
};

const JoinPolicySection = ({ dispatch, data, userAgent }: Props) => {
  const isCheck = data.every((item) => item);
  const handlePolicyChange = useCallback(
    (index: number | "all") => {
      let arr = data.slice();
      if (index === "all") {
        dispatch({
          type: "policy",
          payload: Array(3).fill(isCheck ? false : true),
        });
      } else {
        arr[index] = !arr[index];
        dispatch({
          type: "policy",
          payload: arr,
        });
      }
    },
    [dispatch, data, isCheck]
  );

  return (
    <section>
      <button
        className={`policy-wrapper all ${isCheck && "all-purple"}`}
        onClick={() => {
          handlePolicyChange("all");
        }}
      >
        <div className="left">
          <img
            src={`/assets/join/${isCheck ? "active" : "grey"}-check.svg`}
            alt="체크"
          />
          약관 전체 동의
        </div>
      </button>

      <div className="policy-wrapper">
        <button
          className="left"
          onClick={() => {
            handlePolicyChange(0);
          }}
        >
          <img
            src={`/assets/join/${data[0] ? "active" : "grey"}-check.svg`}
            alt="체크"
          />
          [필수] 이용약관 전체 동의
        </button>
        <Link to="/policy/terms" target="_blank">
          전문보기
        </Link>
      </div>
      <div className="policy-wrapper">
        <button
          className="left"
          onClick={() => {
            handlePolicyChange(1);
          }}
        >
          <img
            src={`/assets/join/${data[1] ? "active" : "grey"}-check.svg`}
            alt="체크"
          />
          [필수] 개인정보 이용 동의
        </button>
        <Link to="/policy/private" target="_blank" className="link">
          전문보기
        </Link>
      </div>
      <div className="policy-wrapper">
        <button
          className="left"
          onClick={() => {
            handlePolicyChange(2);
          }}
        >
          <img
            src={`/assets/join/${data[2] ? "active" : "grey"}-check.svg`}
            alt="체크"
          />
          [선택]{" "}
          {userAgent !== "mb" && (
            <>신규 이벤트를 놓치지 마세요. (마케팅 정보 수신 동의)</>
          )}
          {userAgent === "mb" && <>마케팅 정보 수신 동의</>}
        </button>
        <Link to="/policy/marketing" target="_blank" className="link">
          전문보기
        </Link>
      </div>
    </section>
  );
};

export default JoinPolicySection;
