import React from 'react'
import "./css/index.css"
import { NavigateFunction } from 'react-router-dom';
import { UserAgentType } from '../../../common/common.inerface';
import { ProjectType, PurposeType } from '../../../interface/interface';
import { reward_category_arr } from '../PqboxManageCard/PqboxManageCard';
import { reward_state } from '../../../lib/lib';
type Props = {
  data: ProjectType;
  userAgent: UserAgentType;
  navigate: NavigateFunction;
  projectId?: string;
  purpose?: PurposeType;
};

const PqboxUserCard = ({
  data: {
    images: { thumbnail },
    title,
    period: { start, finish },
    category,
    state,
    profile: { author },
    key,
  
  },
  userAgent,
  navigate,
  purpose,
  projectId,
}: Props) => {
    const strartformat = start.split("-");
  const finishfotmat = finish.split("-");
  return (
    <div className='pqbox-user-card'>
          <div className="card-left">
        {" "}
        <img src={thumbnail} alt="" />
        {userAgent === "pc" ? (
          <>
            {" "}
            <div className="title">{title}</div>
            <div className="author">{author}</div>
            <div className="period">
              {strartformat[0].substring(2, 4)}/{strartformat[1]}/
              {strartformat[2]} ~ {finishfotmat[0].substring(2, 4)}/
              {finishfotmat[1]}/{finishfotmat[2]}
            </div>
            <div className="category">
        <img src={`/assets/pqcard/${reward_category_arr[category].img}.svg`} alt="" /> {reward_category_arr[category].title}
            </div>
            <div className={`pqbox-card-state ${state}-card`}>
              {reward_state(state)}
            </div>
          </>
        ) : (
          <>
            <div className={`pqbox-card-state ${state}-card`}>
              {reward_state(state)}
            </div>
            <div className="title">{title}</div>
            <div className="author">{author}</div>
            <div className="period">
              {strartformat[0].substring(2, 4)}/{strartformat[1]}/
              {strartformat[2]} ~ {finishfotmat[0].substring(2, 4)}/
              {finishfotmat[1]}/{finishfotmat[2]}
            </div>
            <div className="category">
               <img src={`/assets/pqcard/${reward_category_arr[category].img}.svg`} alt="" /> {reward_category_arr[category].title}
            </div>
          </>
        )}
      </div>
      <div className="right-btn-wrapper">
        <button className="view-btn"
        disabled={state!=="funding"}
        onClick={()=>{
    if (state==="funding") {
            navigate(`/pqbox/detail/${purpose}/${projectId}/${key}`)
    }
        }}
        >리워드 보기</button>
      </div>
    </div>
  )
}

export default PqboxUserCard
