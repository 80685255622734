import React, { Dispatch, SetStateAction, useCallback } from "react";
import "./css/index.css";
import { PurposeType } from "../../../interface/interface";

type Props = {
  title?: string;
  placeholder?: string;
  setState?: Dispatch<SetStateAction<string>>;
  patch?: string;
  type?: string;
  Update?: (type: string, value: string | number) => void;
  data: any;
  category?: string;
  errorType?: string;
  clearText?: string;
  purpose?: PurposeType;
  disabled?:boolean;
};

const CommonInput = ({
  title,
  placeholder,
  type,
  data,
  Update,
  category,
  errorType,
  setState,
  clearText,
  purpose,
  disabled
}: Props) => {
  const handleChange = useCallback(
    (e: string | number) => {
      if (Update && category) {
        Update(category, e);
      }
    },
    [Update, category]
  );
  return (
    <div
      className={`common-input ${errorType !== undefined ? "input-error" : ""}`}
    >
      <div className="common-input-title">
        {title}
        {errorType && <div className="error">{errorType}</div>}
        {clearText && (
          <div
            className="clear-text"
            style={
              purpose === "ordermade" ? { color: "var(--red600)" } : undefined
            }
          >
            {clearText}
          </div>
        )}
      </div>
      <input
      disabled={disabled}
        type={type}
        placeholder={placeholder ? placeholder : ""}
        value={data}
        onChange={(e) => {
          const val = e.target.value;
          if (setState) {
            setState(val);
          } else {
            handleChange(val);
          }
        }}
      />
    </div>
  );
};

export default CommonInput;
