import React from "react";
import { RewardType } from "../../../interface/interface";
import { makeComma } from "../../../lib/lib";
import { UserAgentType } from "../../../common/common.inerface";

type Props = {
  data: RewardType;
  userAgent: UserAgentType;
  number: number;
};

const PaymentReward = ({
  data: { count, productCost, delivery, context },
  userAgent,
  number,
}: Props) => {
  return (
    <div className="reward-box">
      <div className="top">
        <div className="reward-price">{makeComma(productCost)}원</div>
        {number && <div className="reward-count">{number}개 남음</div>}
      </div>
      <div className="reward-context">{context}</div>
      <div className="reward-footer">
        <div className="left">
          {/* <div className="title-wrapper">
            <div className="title">배송비</div>
            <div className="text">
              {delivery ? `${makeComma(delivery)}원` : "배송불필요"}
            </div>
          </div> */}
          <div className="title-wrapper">
            <div className="title">수량</div>
            <div className="text">{count ? `${count}개` : "제한없음"}</div>
          </div>
          {userAgent !== "pc" && (
            <div className="title-wrapper">
              <div className="title">총액</div>
              <div className="text">{makeComma(count * productCost)}원</div>
            </div>
          )}
        </div>
        <div className="right">
          {userAgent === "pc" && (
            <div className="pay">{makeComma(count * productCost)}원</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentReward;
