import React from "react";
import "./css/index.css";
import Skeleton from "react-loading-skeleton";

const ProjectCardSk = () => {
  return (
    <div className="project-card-wrapper">
      <button className={`project-card sk-project-card`}>
        <div className="thumbnail">
          <Skeleton />
        </div>
        <div className="profill">
          <Skeleton />
        </div>
        <div className="title">
          <Skeleton />
        </div>
      </button>
    </div>
  );
};
export default ProjectCardSk;
