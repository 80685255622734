import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ProjectType, PurposeType } from "../interface/interface";
import { GetProjecDetail } from "../api/project_api";
import { PqboxInitial } from "../lib/initial";
import { PqboxReducer } from "../lib/reducer";
import { clientApi } from "../api/config";
import { useDispatch } from "react-redux";
import { ToastChange } from "../reducers/config";

const UsePqboxWrite = () => {
  const reduxPatch = useDispatch();
  const navigate = useNavigate();
  const { purpose, projectId } = useLocation().state as {
    purpose: PurposeType;
    projectId: string;
  };
  const params = useParams();
  const ScreenRef = useRef<HTMLDivElement>(null);
  const [data, dispatch] = useReducer(PqboxReducer, undefined, PqboxInitial);
  const [ProjectData, setProjectData] = useState<ProjectType | undefined>(
    undefined
  );
  const Update = useCallback(
    (type: string, payload: any) => {
      let value = payload;
      if (type === "title" && value.length > 40) {
        return;
      }
      dispatch({
        type,
        payload: value,
      });
    },
    [dispatch]
  );

  // pqbox/management/ordermade/-Neq0n2FOVRTRVpRnoVU
  const Insert = useCallback(async () => {
    if (ProjectData && data.key) {
      const obj = Object.assign(data, {
        profile: ProjectData.profile,
        editor: ScreenRef.current ? ScreenRef.current.innerHTML : data.editor,
      });

      const { data: postData } = await clientApi.post("/pqbox/submit", {
        project_key: ProjectData.key,
        data: obj,
        pushKey: data.key,
      });
      if (postData.state) {
        navigate(`/pqbox/management/${ProjectData.purpose}/${ProjectData.key}`);
      }
      reduxPatch(ToastChange({ active: true, content: postData.msg }));
    }
  }, [ProjectData, data, ScreenRef]);
  const Save = useCallback(async () => {
    if (ProjectData && data.key) {
      const obj = Object.assign(data, {
        profile: ProjectData.profile,
     editor: ScreenRef.current ? ScreenRef.current.innerHTML : data.editor,
      });
      const { data: postData } = await clientApi.post("/pqbox/save", {
        project_key: ProjectData.key,
        data: obj,
        pushKey: data.key,
      });
      if (postData.state) {
        navigate(`/pqbox/management/${ProjectData.purpose}/${ProjectData.key}`);
      }
      reduxPatch(ToastChange({ active: true, content: postData.msg }));
    }
  }, [ProjectData, data]);

  useEffect(() => {
    const val = window.sessionStorage.getItem("pqdata");
    if (val) {
      const value = JSON.parse(val);
      if (value.key === projectId || !projectId) {
        setProjectData(value);
      } else {
        if (purpose && projectId) {
          GetProjecDetail(purpose, projectId).then(({ state, data }) => {
            if (state) {
              window.sessionStorage.setItem("pqdata", JSON.stringify(data));
              setProjectData(data);
            }
          });
        }
      }
    } else {
      if (purpose && projectId) {
        GetProjecDetail(purpose, projectId).then(({ state, data }) => {
          if (state) {
            window.sessionStorage.setItem("pqdata", JSON.stringify(data));
            setProjectData(data);
          }
        });
      }
    }
    return () => {};
  }, [purpose, projectId]);

  useEffect(() => {
    if (params.id && projectId) {
      clientApi
        .get("/pqbox/detail", {
          params: {
            projectId,
            id: params.id,
          },
        })
        .then(({ data }) => {
          console.log(data);
          if (data.state) {
            Update("dispatch/all", data.data);
          }
        });
    } else if (ProjectData && !data.key) {
      clientApi.get("/push/key").then(({ data: { state, data } }) => {
        if (state) {
          dispatch({
            type: "key",
            payload: data,
          });
        }
      });
    }
    return () => {};
  }, [data.key, ProjectData, params]);

  return { ProjectData, Update, data, ScreenRef, Insert, Save };
};

export default UsePqboxWrite;
