import React, { Dispatch, SetStateAction } from "react";
import { PurposeType } from "../../interface/interface";
import "./css/index.css";
type Props = {
  categories: string[];
  onCategoryChange: Dispatch<SetStateAction<number>>;
  nowCategory: number;
  purpose?: PurposeType;
};

const CategoryFilter = ({
  categories,
  onCategoryChange,
  nowCategory,
  purpose,
}: Props) => {
  
  return (
    <div
      style={{ columnGap: "8px" }}
      className="categoryfilter"
    >
      {categories.map((category, idx) => (
        <button
          className={
            !purpose && nowCategory === idx ? "select-category-btn" : ""
          }
          key={idx}
          onClick={() => {
            onCategoryChange(idx);
          }}
          style={
            purpose && nowCategory === idx
              ? {
                  borderColor:
                    purpose === "projects"
                      ? "var(--main)"
                      : purpose === "ordermade"
                      ? "var(--red600)"
                      : "unset",
                }
              : undefined
          }
        >
          <p
            style={
              purpose && nowCategory === idx
                ? {
                    color:
                      purpose === "projects"
                        ? "var(--main)"
                        : purpose === "ordermade"
                        ? "var(--red600)"
                        : "unset",
                  }
                : undefined
            }
          >
            {category}
          </p>
        </button>
      ))}
    </div>
  );
};

export default CategoryFilter;
