import { useSelector } from "react-redux";
import StoryDetail from "../StoryDetail";
import { RootState } from "../../../reducers";
import { AuthType } from "../../../reducers/auth";
import UseStoryDetail from "../../../hooks/UseStoryDetail";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../../components/Loading/Loading";
import Popup from "../../../components/Popup/Popup";
import UseComment from "../../../hooks/UseComment";
import { CommentInter } from "../../../interface/interface";

const StoryDetailContainer = () => {
  const UserInfo = useSelector<RootState, AuthType>((state) => state.auth);
  const { ChangeLike, user_story, DetailData, GetDetail, RemoveStory } =
    UseStoryDetail();
  const {
    CommentData,
    SubmitComment,
    FixText,
    RemoveComment,
    ReportComment,
    ReplyComment,
  } = UseComment({ type: "story", id: DetailData ? DetailData.key : "" });
  const [popupType, setPopupType] = useState<
    "delete-popup" | "comment-remove" | "comment-report" | undefined
  >(undefined);
  const [PopupData, setPopupData] = useState<CommentInter | undefined>(
    undefined
  );

  const OnPoopup = useCallback(
    (data: CommentInter, type: "comment-remove" | "comment-report") => {
      setPopupData(data);
      setPopupType(type);
    },
    []
  );

  const ActivePopup = useCallback(() => {
    if (popupType === "comment-remove" && PopupData) {
      RemoveComment(PopupData);
    }
    if (popupType === "comment-report" && PopupData) {
      ReportComment(PopupData);
    }
    if (popupType === "delete-popup") {
      RemoveStory();
    }
    setPopupType(undefined);
  }, [popupType, PopupData, ReportComment, RemoveComment, RemoveStory]);

  useEffect(() => {
    GetDetail();
  }, [GetDetail]);
  if (DetailData === undefined) {
    return <Loading initialLoading={true} />;
  }
  return (
    <>
      <StoryDetail
        uid={UserInfo.uid}
        ChangeLike={ChangeLike}
        data={DetailData}
        setPopupType={setPopupType}
        user_story={user_story}
        userId={DetailData.profile.uid}
        OnPoopup={OnPoopup}
        CommentData={CommentData}
        SubmitComment={SubmitComment}
        FixText={FixText}
        ReplyComment={ReplyComment}
      />
      <Popup type={popupType} Active={ActivePopup} setCancel={setPopupType} />
    </>
  );
};

export default StoryDetailContainer;
