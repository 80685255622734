import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./css/index.css";
import { LoadingChange, ToastChange } from "../../reducers/config";
import {
  createUserWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import UseUser from "../../hooks/UseUser";
import { getQueryStringObject } from "../../lib/factory";
import { Fauth } from "../../config/firebaseApp";
import { RootState } from "../../reducers";

/** 네이버 로그인후에 돌아오는 페이지 */
function LoginLoading() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUser = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const { GetMyInfo } = UseUser();
  useEffect(() => {
    const params = getQueryStringObject();

    const { access_token, expires_in, state, token_type }: any = params;

    if (access_token && expires_in && state && token_type) {
      dispatch(LoadingChange(true));
      const naver = (window as any).naver;
      const naverLogin = new naver.LoginWithNaverId({
        clientId: "_6I4NCeDpS_q5YoIK94X",
        callbackUrl: `${window.location.origin}/login/loading`,
        isPopup: false,
        callbackHandle: true,
      });
      if (naverLogin) {
        naverLogin.init();
      }
      /** naver 로그인 상태 확인 */
      naverLogin.getLoginStatus(async function (status: any) {
        if (status) {
          const { email, id } = naverLogin.user;
          try {
            await setPersistence(Fauth, browserLocalPersistence);
            const login = await signInWithEmailAndPassword(
              Fauth,
              email,
              `NAVER${id}!`
            );
            const { state } = await GetMyInfo(login.user.uid);
            if (state) {
              navigate("/");
            } else {
              navigate("/signup/social", {
                state: {
                  email,
                  uid: login.user.uid,
                  provider: "naver",
                },
              });
            }
            window.localStorage.setItem("provider", JSON.stringify("naver"));
            dispatch(LoadingChange(false));
          } catch (err: any) {
            console.log(err);
            if (err.code === "auth/user-not-found") {
              createUserWithEmailAndPassword(Fauth, email, `NAVER${id}!`)
                .then(async (res) => {
                  if (res.user) {
                    navigate("/signup/social", {
                      state: {
                        email,
                        uid: res.user.uid,
                        provider: "naver",
                      },
                    });
                    window.localStorage.setItem(
                      "provider",
                      JSON.stringify("naver")
                    );
                  }
                })
                .catch((err) => {
                  dispatch(
                    ToastChange({
                      active: true,
                      content: "다른 방법으로 가입된 이메일입니다",
                    })
                  );
                });
              dispatch(LoadingChange(false));
              return;
            }
            if (err.code === "auth/wrong-password") {
              dispatch(
                ToastChange({
                  active: true,
                  content: "다른 방법으로 가입된 이메일입니다",
                })
              );
            }
            navigate("/login");
            dispatch(LoadingChange(false));
          }
        } else {
          console.log("AccessToken이 올바르지 않습니다.");
          dispatch(LoadingChange(false));
        }
      });
    } else {
      navigate("/404");
    }

    return () => {};
  }, [dispatch, isUser]);

  return (
    <div className="login-loading">
      <div className="wrapper">
        <div className="asset">
          <img src="/assets/login/symbol.svg" alt="symbol" />
        </div>
        <div className="title txt-bold">현재 로그인중입니다</div>
        <div className="subtitle">로그인 중입니다. 잠시만 기다려주세요</div>
      </div>
    </div>
  );
}

export default LoginLoading;
