import React from "react";
import EmailSend from "../../components/EmailSend/EmailSend";
import { useLocation } from "react-router-dom";
import Page_404 from "../../components/Error/Page404/Page_404";

const SignupFinish = () => {
  const email = useLocation().state;
  // if (!email) {
  //   return <Page_404 />;
  // }
  return <EmailSend type="signup" emailValue={email} />;
};

export default SignupFinish;
