import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  NoticeInterface,
  UserAgentType,
} from "../../../../common/common.inerface";
import NoticeDetail from "../NoticeDetail";
import { clientApi } from "../../../../api/config";
import UseComment from "../../../../hooks/UseComment";
import { useSelector } from "react-redux";
import { RootState } from "../../../../reducers";
import { AuthType } from "../../../../reducers/auth";

type Props = {
  userAgent: UserAgentType;
};
const NoticeDetailContainer = ({ userAgent }: Props) => {
  const params = useParams();
  const auth = useSelector<RootState, AuthType>((state) => state.auth);
  const [DetailData, setDetailData] = useState<NoticeInterface | undefined>();
  const {
    CommentData,
    SubmitComment,
    FixText,
    RemoveComment,
    ReportComment,
    ReplyComment,
  } = UseComment({ type: "notice", id: params.id ? params.id : "" });
  const __getContent = useCallback(async () => {
    const {
      data: { status, result },
    } = await clientApi.post("/notice/detail", {
      id: params.id,
    });

    if (status) {
      setDetailData(result);
    }
  }, [params]);
  useEffect(() => {
    __getContent();
    return () => {};
  }, [__getContent]);

  return (
    <NoticeDetail
      data={DetailData}
      CommentData={CommentData}
      SubmitComment={SubmitComment}
      auth={auth}
      FixText={FixText}
      RemoveComment={RemoveComment}
      ReportComment={ReportComment}
      ReplyComment={ReplyComment}
    />
  );
};

export default NoticeDetailContainer;
