import { createSlice } from "@reduxjs/toolkit";
import {
  CardType,
  GatekeepingType,
  ProjectType,
  PurposeType,
} from "../../interface/interface";

export type AuthProfileType = {
  image: string;
  email: string;
  nickname: string;
  quote: string;
  uid?: string;
};
export type authStoryType = {
  [key: string]: number;
};

export type AuthType = {
  uid: string | undefined;
  profile: AuthProfileType;
  projects?: ProjectType[];
  ordermade?: ProjectType[];
  save: {
    projects?: ProjectType;
    ordermade?: ProjectType;
  };
  card?: CardType[];
  scrap?: { key: string; purpose: PurposeType }[];
  gatekeeping?: GatekeepingType[];
  story?: authStoryType;
  config: {
    policy: boolean[];
  };
};
const initialState: AuthType = {
  uid: undefined,
  config: {
    policy: [true, true, false],
  },
  profile: {
    email: "",
    image: "",
    nickname: "",
    quote: "",
  },
  save: {
    projects: undefined,
    ordermade: undefined,
  },
};

const configReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    ResetAuth: () => initialState,
    AllAuth: (state, { payload }) => payload,
    AuthCard: (state, { payload }: { payload: CardType[] }) => {
      state.card = payload;
    },
    UpdateProfile: (state, { payload }: { payload: AuthProfileType }) => {
      state.profile = payload;
    },
    UpdatePolicy: (state, { payload }: { payload: boolean[] }) => {
      state.config.policy = payload;
    },
    UpdateScrap: (
      state,
      { payload }: { payload: { key: string; purpose: PurposeType }[] }
    ) => {
      state.scrap = payload;
    },
    UpdateSaveProject: (state, { payload }: { payload: ProjectType }) => {
      state.save.projects = payload;
    },
    UpdateSaveOrder: (state, { payload }: { payload: ProjectType }) => {
      state.save.ordermade = payload;
    },
    UpdateStory: (state, { payload }: { payload: authStoryType }) => {
      state.story = payload;
    },
    // UserEmail: (state, { payload }: { payload: string }) => {
    //   state.email = payload;
    // },
    // UserImg: (state, { payload }: { payload: string }) => {
    //   state.info.img = payload;
    // },
    // UpdateAuthInfo: (state, { payload }: { payload: AuthInfoType }) => {
    //   state.info = payload;
    // },
  },
});

export const {
  ResetAuth,
  AllAuth,
  UpdatePolicy,
  AuthCard,
  UpdateScrap,
  UpdateProfile,
  UpdateSaveProject,
  UpdateSaveOrder,
  UpdateStory,
} = configReducer.actions;

export default configReducer.reducer;
