import React, { useCallback } from "react";
import "./css/index.css";
import { useNavigate } from "react-router-dom";
import { ProjectType, PurposeType } from "../../interface/interface";
import { ChangeDday, ProjectStateKr } from "../../lib/lib";
import { ReactComponent as ScrapSvg } from "./svg/list-scrap.svg";
type Props = {
  data: ProjectType;
  type: "funding" | "open" | "order" | "pqbox" | "inquiry";
  isAnswer?: boolean;
  isScrap?: boolean;
  ScrapProject?: (key: string, purpose: PurposeType) => void;
};

const ProjectCard = ({
  data,
  type,
  isAnswer,
  isScrap,
  ScrapProject,
}: Props) => {
  const navigate = useNavigate();
  const percent = (data.amount.now / data.amount.goal) * 100;
  const __nav = useCallback(
    (item: ProjectType) => {
      const { key, purpose } = item;
      const link_type = purpose === "projects" ? "funding" : "order";
      navigate(
        `/${link_type}/detail/${key}${type === "inquiry" ? "/inquiry" : ""}`,
        {
          state: "inside",
        }
      );
    },
    [type]
  );

  return (
    <div className="project-card-wrapper">
      {(type === "funding" || type === "open" || type === "order") &&
        ScrapProject && (
          <button
            className={`card-scrap ${isScrap && "active-scrap"}`}
            onClick={() => {
              ScrapProject(data.key, data.purpose);
            }}
          >
            <ScrapSvg />
          </button>
        )}
      <button
        className={`project-card ${data.purpose}-project-card`}
        onClick={() => {
          __nav(data);
        }}
      >
        <div className="thumbnail">
          <img
            className="thumbnail-img"
            src={data.images.thumbnail}
            alt="썸네일"
            loading="lazy"
          />
          {data.config.deliveryType === "PQ" && (
            <img
              src="/assets/common/pqbox.svg"
              alt="pqbox"
              className="pq-box-svg"
            />
          )}
        </div>
        <div className="profill">
          {" "}
          {data.rewards.type[0].value
            ? data.rewards.type[0].value
            : data.rewards.type[0].title}
        </div>
        <div className="title">{data.title}</div>
        {type === "funding" && (
          <div className="amount">
            <div className={`percent ${data.state}-percent`}>
              {percent.toFixed(0)}% 달성{" "}
            </div>
            <div className={`days ${data.state}-days`}>
              {ProjectStateKr(data.state, data.period.finish)}
            </div>
          </div>
        )}
        {type === "open" && (
          <div className="amount">
            <div className="percent">
              {data.period.start}일 <br className="mb" />
              오픈 예정
            </div>
          </div>
        )}
        {type === "order" && <OrderSection data={data} />}
        {type === "inquiry" && (
          <div
            className="answer-text"
            style={{ color: isAnswer ? "var(--main)" : "#949494" }}
          >
            {isAnswer ? "답변완료" : "답변대기"}
          </div>
        )}
      </button>
    </div>
  );
};

export default ProjectCard;

export const OrderSection = ({ data }: { data: ProjectType }) => {
  const orderCount = data.rewards.content.reduce((a, b) => a + b.count, 0);
  const payCount = data.rewards.content.reduce((a, b) => {
    const lenght = b.payment ? Object.values(b.payment).length : 0;
    return a + lenght;
  }, 0);
  return (
    <div className="amount">
      <div
        className={`percent ${
          orderCount - payCount < 1 ? "finish" : data.state
        }-percent`}
      >
        {orderCount - payCount > 0
          ? `${orderCount - payCount}개 남음`
          : "오더 성공"}
      </div>
      <div className="wrapper">
        <div className={`days ${data.state}-days`}>{payCount}개 달성</div>
        <div className={`days ${data.state}-days`}>
          {data.state === "fail"
            ? "오더 무산"
            : data.state === "finish" || data.state === "re-finish"
            ? "오더 성공"
            : ChangeDday(data.period.finish)}
        </div>
      </div>
    </div>
  );
};
