import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CommentInter,
  GatekeepingType,
  ProjectType,
} from "../interface/interface";
import { clientApi } from "../api/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";
import { AuthType } from "../reducers/auth";
import { ToastChange } from "../reducers/config";

export interface RewardDetailType {
  data: ProjectType;
  prjtData: ProjectType;
}

const UsePqboxDetail = ({ type }: { type: "preview" | "detail" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paramas = useParams();
  const auth = useSelector<RootState, AuthType>((state) => state.auth);
  const [DetailData, setDetailData] = useState<RewardDetailType | undefined>(
    undefined
  );
  useEffect(() => {
    const { id, purpose, prjtId } = paramas;
    if (id && purpose && prjtId && auth.uid) {
      console.log(auth.uid);
      clientApi
        .get("/reward/detail", {
          params: {
            projectId: prjtId,
            id,
            purpose,
            uid: auth.uid,
          },
        })
        .then(({ data: { state, data, msg } }) => {
          if (state) {
            const { state } = data.data;
            if (state !== "funding" && type === "detail") {
              navigate(-1);
              dispatch(
                ToastChange({
                  active: true,
                  content: "공개중인 리워드가 아닙니다.",
                })
              );
            }
            setDetailData(data);
          } else {
            navigate(-1);
            dispatch(
              ToastChange({
                active: true,
                content: msg,
              })
            );
          }
        });
    }
    return () => {};
  }, [paramas, auth, type]);

  return { DetailData, id: paramas.id, auth };
};

export default UsePqboxDetail;
