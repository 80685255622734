import { combineReducers } from "@reduxjs/toolkit";
import config from "./config/index";
import auth from "./auth/index";
import data from "./data/index";
import editor from "./editor"
const rootReducer = combineReducers({
  config,
  auth,
  data,
  editor
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
