import React, { useCallback } from "react";
import "./css/index.css"
import PqboxCategory from "../../../../components/Pqbox/PqboxWrite/PqboxCategory/PqboxCategory";
import CommonInput from "../../../../components/Common/CommonInput/CommonInput";
import { PqboxType, ProjectType } from "../../../../interface/interface";
import StepBtn from "../../../../components/WriteProject/StepBtn/StepBtn";
import { AppDispatch } from "../../../..";
import { ToastChange } from "../../../../reducers/config";
import { NavigateFunction } from "react-router-dom";

type Props = {
  data:PqboxType;
    Update: (type: string, payload: any) => void;
    ProjectData?:ProjectType;
    dispatch:AppDispatch
    navigate:NavigateFunction;
};

const PqboxPeriod = ({data,Update,ProjectData,dispatch,navigate}: Props) => {
    const isPeriod = useCallback(() => {
     if (ProjectData) {
       const{period:{
        start
      }}=data
    const{ period:{
      finish
    } }=ProjectData
    const nowDate = new Date();
    nowDate.setHours(0);
    nowDate.setMinutes(0);
    if (start.length !== 10) {
      return false;
    }
    if (new Date(start).getTime() <= new Date(finish).getTime()) {
      return false;
    }
    return true;
     }
  }, [data, ProjectData]);

    const isRewards = useCallback(() => {
      const{period:{
        start,finish
      }}=data
    if (finish.length !== 10) {
      return false;
    }
    if (new Date(finish).getTime() <= new Date(start).getTime()) {
      return false;
    }
    return true;
  }, [data]);
    const PeriodUpdate = useCallback(
    (type: string, payload: any) => {
      if (payload.length < 11) {
        let value = payload
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, "$1-$2-$3")
          .replace(/(\-{1,2})$/g, "");
        Update(
          type,
          value,
        );
      }
    },
    [Update]
  );
    const BackPage = useCallback(() => {
    navigate(".", {
      state: data,
    });
  }, [navigate, isPeriod, isRewards, data]);
  const NextPage = useCallback(() => {
    if (!isPeriod()) {
      dispatch(
        ToastChange({
          active: true,
          content: "공개일 확인해주세요",
          type: "error",
        })
      );
      return;
    }
    if (!isRewards()) {
      dispatch(
        ToastChange({
          active: true,
          content: "종료일을 확인해주세요",
          type: "error",
        })
      );
      return;
    }
    navigate("./content", {
      state: data,
    });
  }, [navigate, isPeriod, isRewards, data]);
  return (
    <div className="pqbox-period-page">
      <div className="write-pqbox-title">
        <span>02</span>
        리워드 종류/기간
      </div>
      <div className="pqbox-write-content-section">
        <div className="pqbox-write-content-title">리워드 종류</div>
        <PqboxCategory now={data.category} Update={Update} />
      </div>
      <div className="pqbox-write-content-section">
        <div className="pqbox-write-content-title">리워드 기간</div>
              <div className="pqbox-period-wrapper">
                  <div className={`set-period-box ${isPeriod() && "clear-period-box"}`}>
          <div className="title">리워드 공개일</div>
          <div className="sub">
프로젝트 작성시 작성한 공개일을 기준으로 합니다
          </div>
          <div className="set-period-input-wrapper">
            <CommonInput
              title="공개일"
              placeholder="예) 20230101"
              category="start"
              data={data.period.start}
              Update={PeriodUpdate}
              clearText="마감일보다 하루 이상 일자를 입력해주세요"
            />
          </div>
        </div>
           <div className={`set-period-box ${isRewards() && "clear-period-box"}`}>
          <div className="title">리워드 종료일</div>
          <div className="sub">
리워드를 감상가능한 기한을 작성해주세요
          </div>
          <div className="set-period-input-wrapper">
            <CommonInput
              title="종료일"
              placeholder="예) 20230101"
              category="finish"
              data={data.period.finish}
              Update={PeriodUpdate}
              clearText="공개일보다 하루 이상 일자를 입력해주세요"
            />
          </div>
        </div>
              </div>
      </div>
            <div className="category-step-wrapper">
                <StepBtn CheckStep={BackPage} title="이전으로" type={"back"} />
            <StepBtn CheckStep={NextPage} title="다음으로" type={"gradient"} />
      </div>

    </div>
  );
};

export default PqboxPeriod;
