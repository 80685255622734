import React, { useCallback, useEffect, useState } from "react";
import Home from "../Home";
import { UserAgentType } from "../../../common/common.inerface";
import UseStoryDetail from "../../../hooks/UseStoryDetail";
import { clientApi } from "../../../api/config";
import { StoryType } from "../../../interface/interface";
import { useNavigate } from "react-router-dom";

type Props = {
  userAgent: UserAgentType;
};

const HomeContainer = ({ userAgent }: Props) => {
  const navigate = useNavigate();
  const { ChangeLike, user_story } = UseStoryDetail();
  const [StoryData, setStoryData] = useState<StoryType[]>([]);
  const GetStory = useCallback(async () => {
    try {
      let obj: any = {
        page: 0,
        number: userAgent === "tablet" ? 4 : 3,
      };
      const {
        data: { state, data },
      } = await clientApi.get("/story/list", {
        params: obj,
      });
      if (state) {
        if (obj.page === 0) {
          setStoryData(data);
        } else {
          setStoryData((prevData) => {
            if (prevData) {
              return [...prevData, ...data];
            }
            return [...data];
          });
        }
      } else {
        setStoryData([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [userAgent]);
  const NavSearch = useCallback(
    (text: string) => {
      navigate("/search", { state: text });
    },
    [navigate]
  );

  useEffect(() => {
    GetStory();
    return () => {};
  }, [GetStory]);

  return (
    <Home
      userAgent={userAgent}
      StoryData={StoryData}
      ChangeLike={ChangeLike}
      user_story={user_story}
      NavSearch={NavSearch}
    />
  );
};

export default HomeContainer;
