import React from "react";

type Props = {
  Active: () => void;
  DeActive?: () => void;
};

const SavePopup = ({ Active, DeActive }: Props) => {
  return (
    <div className="save-popup">
      <div className="title">
        {" "}
        임시저장된 데이터가 있습니다. <br /> 임시저장내용을 불러오시겠습니까?
      </div>
      <div className="sub">불러오지 않을 경우 임시저장내용은 삭제됩니다</div>
      <div className="btn-wrapper">
        <button className="active-btn" onClick={Active}>
          불러오기
        </button>
        <button className="deactive-btn" onClick={DeActive}>
          임시저장내용 삭제
        </button>
      </div>
    </div>
  );
};

export default SavePopup;
