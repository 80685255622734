import { Link } from "react-router-dom";
import "./css/index.css";

type Props = {
  type: "signup" | "password";
  emailValue?: string;
};
const EmailSend = ({ type, emailValue }: Props) => {
  return (
    <main className="finish-page">
      {type === "signup" && (
        <div className="email-section">
          <div className="email-logo">
            <img src="/assets/common/email.svg" alt="" />
            <div className="email-title">인증 메일이 발송되었습니다</div>
            <div className="email-line"></div>
            <div className="email-id">
              <span>{emailValue}</span>으로 <br /> 본인 인증 메일이
              발송되었습니다.
            </div>
            <div className="text">
              발송 후 24시간 이내에 인증을 완료해주세요. <br /> 24시간 초과 시
              재로그인하여 인증메일을 재전송받으세요.
            </div>
            <Link className="nav-link" to={"/"}>
              메인으로
            </Link>
          </div>
        </div>
      )}
      {type === "password" && (
        <div className="email-section">
          <div className="email-logo">
            <img src="/assets/common/email.svg" alt="" />
            <div className="email-title">
              비밀번호 변경 메일을 <br />
              보냈습니다
            </div>
            <div className="email-line"></div>
            <div className="email-id">
              <span>{emailValue}</span>으로 <br />
              비밀번호 변경 메일이 발송되었습니다. <br /> 링크를 통해 비밀번호를
              변경해주세요.
            </div>

            <Link className="nav-link" to={"/"}>
              메인으로
            </Link>
          </div>
        </div>
      )}
    </main>
  );
};

export default EmailSend;
