import React, { Dispatch, SetStateAction } from "react";
import { ReactComponent as AddSvg } from "../../../svg/add.svg";
import { CardType, PurposeType } from "../../../interface/interface";
import PayCard from "../../../components/PayCard/PayCard";

type Props = {
  cards: CardType[];
  nowCard: CardType | undefined;
  setNowCard: Dispatch<SetStateAction<CardType | undefined>>;
  __initCard: () => void;
  purpose: PurposeType;
};

const PaymentCardSection = ({
  cards,
  nowCard,
  setNowCard,
  __initCard,
  purpose,
}: Props) => {
  return (
    <div className="projectpayment-card">
      <div className="title-wrapper">
        <div className="title">결제정보</div>
        <div className="sub">
          이전 펀딩시 등록한 카드로 편리하게 선택할 수 있습니다.
        </div>
      </div>
      {cards.length > 0 && (
        <div className="card-list-section">
          {cards.map((item, idx) => {
            return (
              <PayCard
                key={idx}
                data={item}
                nowCard={nowCard}
                setNowCard={setNowCard}
                purpose={purpose}
              />
            );
          })}
        </div>
      )}
      {cards.length === 0 && (
        <div className="card-none-box">
          <img src="/assets/common/card-none.svg" alt="" />
          <div className="text">등록된 카드가 없습니다</div>
        </div>
      )}
      {cards.length < 4 && (
        <button
          className={`add-button payment-box ${purpose}-add-button`}
          onClick={__initCard}
        >
          <AddSvg />
          <div className="text">새로운 카드를 등록 후 결제에 사용합니다.</div>
        </button>
      )}
      <ul>
        <li>
          리워드의 예상전달일과 개별 환불 규정을 숙지하고 프로젝트에
          후원해주세요.
        </li>
        <li>
          이를 확인하지 않아 발생한 모든 손실과 손해는 후원자 본인이 부담합니다.
        </li>
        <li>
          결제는 바로 이루어지는 것이 아닌 펀딩 성공일 이후 자동 이체 됩니다.
        </li>
        <li>
          프로젝트퀘스천은 카드번호를 암호화해 카드사로 전달하기 때문에
          안전합니다.
        </li>
        <li>
          결제 이후 카드정보 변경이 필요하시다면 프로젝트퀘스천으로
          문의부탁드립니다.
        </li>
        <li>
          결제가 되기 전 카드의 잔액이나 상태를 한번 더 확인해주시기 바랍니다.
        </li>
      </ul>
    </div>
  );
};

export default PaymentCardSection;
