import React from "react";
import "./css/index.css";
import { PqboxType, ProjectType } from "../../../../interface/interface";
import { ProjectStateKr } from "../../../../lib/lib";
import { NavigateFunction } from "react-router-dom";
type Props = {
  ProjectData?: ProjectType;
  InsertProject?: () => void;
  nowpath: string | undefined;
  Temporary?: (isBtn?: boolean) => void;
  navigate: NavigateFunction;
  data: PqboxType;
};

const PqboxWriteTop = ({
  ProjectData,
  nowpath,
  InsertProject,
  Temporary,
  navigate,
  data,
}: Props) => {
  if (ProjectData) {
    const percent = (ProjectData.amount.now / ProjectData.amount.goal) * 100;
    const payCount = ProjectData.rewards.content.reduce((a, b) => {
      const lenght = b.payment ? Object.values(b.payment).length : 0;
      return a + lenght;
    }, 0);
    return (
      <div className="pqbox-write-top">
        <div className="title-section">
          <div className="title">PQ 박스 리워드 작성</div>
          <div className="write-header-btn-wrapper">
            {nowpath === "content" && (
              <button
                className="header-back-btn"
                onClick={() => {
                  navigate("./period", { state: data });
                }}
              >
                이전으로
              </button>
            )}
            {Temporary && (
              <button
                className="save-btn"
                onClick={() => {
                  Temporary(true);
                }}
              >
                임시 저장
              </button>
            )}
            {nowpath === "content" && InsertProject && (
              <button className="insert-btn" onClick={InsertProject}>
                심사 요청
              </button>
            )}
          </div>
        </div>
        <div className="project-info-section">
          <div
            className="thumbnail"
            style={{
              backgroundImage: `url(${ProjectData.images.thumbnail})`,
              width: `205px`,
              height: `115px`,
              backgroundPosition: `center`,
              backgroundSize: `cover`,
            }}
          />
          <div className="project-content-wrapper">
            <div className="catagory">
              {" "}
              {ProjectData.rewards.type[0].value
                ? ProjectData.rewards.type[0].value
                : ProjectData.rewards.type[0].title}
            </div>
            <div className="title">{ProjectData.title}</div>
            <div className={`etc-wrapper ${ProjectData.purpose}-etc-wrapper`}>
              {ProjectData.purpose === "ordermade" ? (
                <div className="percent">{payCount}개 달성</div>
              ) : (
                <div className="percent">{percent.toFixed(0)}% 달성 </div>
              )}
              <div className="days">
                {ProjectStateKr(ProjectData.state, ProjectData.period.finish)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default PqboxWriteTop;
