export function customlog(title, message) {
  console.log(`[${title}] : ${message}`);
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function makeComma(str) {
  const number = String(str).replace(/[^0-9]/gi, "");
  return String(number).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
}

export function objectToArraysWithKeys(object) {
  if (object) {
    const keys = Object.keys(object);
    const values = Object.values(object);
    const resultArr = values.map((item, idx) => {
      return {
        key: keys[idx],
        value: item,
      };
    });
    return resultArr;
  } else {
    return [];
  }
}

export function twoDigits(num) {
  return String(num).length !== 1 ? String(num) : `0${num}`;
}

export function getQueryStringObject() {
  var a = window.location.hash.substr(1).split("&");

  if (a === "") return {};
  var b = {};
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split("=", 2);
    if (p.length === 1) b[p[0]] = "";
    else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return b;
}

export function normalTime(timestamp) {
  const year = new Date(timestamp).getFullYear();
  const month = twoDigits(new Date(timestamp).getMonth() + 1);
  const date = twoDigits(new Date(timestamp).getDate());
  return `${year}.${month}.${date}`;
}
