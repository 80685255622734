import "./css/index.css";
import ImgBanner from "../../components/Story/StoryDetail/ImgBanner";
import FeadbackOptions from "../../components/Story/StoryDetail/FeadbackOptions";
import { story_category_layouts } from "../Story/Story";
import { CommentInter, StoryType } from "../../interface/interface";
import { authStoryType } from "../../reducers/auth";
import { Dispatch, SetStateAction } from "react";
import CommentWrite from "../../components/Comment/CommentWrite/CommentWrite";
import CommentList from "../../components/Comment/CommentList/CommentList";

type Props = {
  uid: string | undefined;
  user_story?: authStoryType;
  ChangeLike: (key: string) => void;
  data: StoryType;
  userId: string | undefined;
  setPopupType: Dispatch<
    SetStateAction<
      "comment-remove" | "comment-report" | "delete-popup" | undefined
    >
  >;
  OnPoopup: (
    data: CommentInter,
    type: "comment-remove" | "comment-report"
  ) => void;
  CommentData: CommentInter[];
  SubmitComment: (value: string, isScret?: boolean) => Promise<boolean>;
  FixText: (
    data: CommentInter,
    text: string,
    isSceret?: boolean
  ) => Promise<boolean>;
  ReplyComment: (text: string, data: CommentInter) => void;
};
const StoryDetail = ({
  uid,
  data,
  ChangeLike,
  user_story,
  userId,
  setPopupType,
  FixText,
  OnPoopup,
  ReplyComment,
  CommentData,
  SubmitComment,
}: Props) => {
  const { like, key } = data;
  return (
    <main className="story-detail-page">
      <div className="story-detail-wrapper">
        <ImgBanner img={data.images} />
        <div className="description-container">
          <div className="category">
            <span>{story_category_layouts[data.category + 1]}</span>
          </div>
          <div className="description-title">
            <h3>{data.title}</h3>
          </div>
          <div className="author-info-container">
            <FeadbackOptions
              uid={uid}
              team={data.profile.nickname}
              ChangeLike={ChangeLike}
              datetime={data.timestamp}
              isLike={uid && like && like[uid] ? true : false}
              like={like ? Object.keys(like).length : 0}
              id={key}
              userId={userId}
              data={data}
              setPopupType={setPopupType}
            />
          </div>
          <div className="description">
            <span>{data.content}</span>
          </div>
        </div>
      </div>
      <hr />
      <div className="story-detail-wrapper">
        {uid && (
          <CommentWrite
            length={CommentData.length}
            SubmitComment={SubmitComment}
            type="notice"
          />
        )}
        <CommentList
          type="story"
          data={CommentData}
          myUid={uid}
          FixText={FixText}
          OnPoopup={OnPoopup}
          isMaker={uid === data.profile.uid}
          ReplyComment={ReplyComment}
        />
      </div>
    </main>
  );
};

export default StoryDetail;
