import React, { useCallback } from "react";

import {
  NoticeInterface,
  UserAgentType,
} from "../../../common/common.inerface";
import { useNavigate } from "react-router-dom";
import NoticeCard from "../../../components/NoticeCard/NoticeCard";
import Infinite from "../../../components/Infinite/Infinite";
import EmtyList from "../../../components/EmtyList/EmtyList";
import NoticeCardSk from "../../../components/NoticeCard/NoticeCardSk";

//공지사항 BODY
type Props = {
  data?: NoticeInterface[];
  userAgent: UserAgentType;
  type: "main" | "notice";
  GetNotice?: (id?: string) => void;
  isStop?: boolean;
};

const NoticeBody = ({ data, type, GetNotice, isStop }: Props) => {
  const navigate = useNavigate();
  const __nav = useCallback(
    (item: any) => {
      navigate(`/notice/detail/${item.key}`);
    },

    [data, navigate]
  );
  const PagingUpdate = useCallback(() => {
    if (GetNotice && data) {
      GetNotice(data[data.length - 1].key);
    }
  }, [GetNotice, data]);

  return (
    <div className="body">
      {!data && Array(6).fill(0).map((item,idx)=>{
        return <NoticeCardSk key={idx}/>
      })
      
      }
      {data && data.length > 0 && (
        <>
          {data.map((item: any, idx: any) => {
            return <NoticeCard data={item} __nav={__nav} key={idx} />;
          })}
          {!isStop && GetNotice && <Infinite PagingUpdate={PagingUpdate} />}
        </>
      )}
      {data && data.length < 1 && (
        <div className="notice-emty-wrapper">
          <EmtyList title="검색 결과가 없습니다" sub="" />
        </div>
      )}
    </div>
  );
};

export default NoticeBody;
