import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainContainer from "./page/Main/container/MainContainer";
import NoticeContainer from "./page/Notice/container/NoticeContainer";
import { UserAgentType } from "./common/common.inerface";
import NoticeDetailContainer from "./page/Notice/NoticeDetail/container/NoticeDetailContainer";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
// import LoginContainer from "./page/Login/container/LoginContainer";
// import SignupContainer from "./page/Signup/container/SignupContainer";
// import PasswordContainer from "./page/PasswordChange/container/PasswordContainer";
import SignupFinish from "./page/SignupFinish/SignupFinish";
import Loading from "./components/Loading/Loading";
import Toast from "./components/Toast/Toast";
import SocialSignupContainer from "./page/Signup/container/SocialSignupContainer";
import LoginLoading from "./page/LoginLoading/LoginLoading";
// import PQfundingContainer from "./page/PQFunding/container/PQFundingContainer";
// import PQfundingOpenContainer from "./page/PQFunding/container/PQFundingOpenContainer";
// import OrderMadeContainer from "./page/PQFunding/container/OrderMadeContainer";
// import OrderMadeOpenContainer from "./page/PQFunding/container/OrderMadeOpenContainer";
// import UserRoutes from "./routes/UserRoutes";
// import WriteProjectRoutes from "./routes/WriteProjectRoutes";
// import ProjectDetailContainer from "./page/ProjectDetail/container/ProjectDetailContainer";
// import OrderDetailContainer from "./page/ProjectDetail/container/OrderDetailContainer";
// import ProjectPaymentContainer from "./page/ProjectPayment/container/ProjectPaymentContainer";
// import PaymentFinish from "./page/PaymentFinish/PaymentFinish";
// import OrderMainContainer from "./page/Main/container/OrderMainContainer";
// import Inquiry from "./page/Inquiry/Inquiry";
// import StoryContainer from "./page/Story/container/StoryContainer";
// import Policy from "./page/Policy/Policy";
// import ProjectPqBoxContainer from "./page/PqBox/container/ProjectPqBoxContainer";
// import OrderPqBoxContainer from "./page/PqBox/container/OrderPqBoxContainer";
// import PasswordFindContainer from "./page/PasswordFind/container/PasswordFindContainer";
import { useDispatch } from "react-redux";
import { BannerGetApi, PolicyGetApi } from "./api/get_api";
import { SetBanner, SetPolicy } from "./reducers/data";
import PaymentFixContainer from "./page/ProjectPayment/container/PaymentFixContainer";
import RewardRoutes from "./routes/RewardRoutes";
import StoryWriteContainer from "./page/StoryWrite/container/StoryWriteContainer";
import StoryFixContainer from "./page/StoryWrite/container/StoryFixContainer";
import StoryDetailContainer from "./page/StoryDetail/container/StoryDetailContainer";
import SearchContainer from "./page/Search/containers/SearchContainer";
import HomeContainer from "./page/Home/container/HomeContainer";
import Page_404 from "./components/Error/Page404/Page_404";
// import StoryWriteContainer from "./page/Story/container/StoryWriteContainer";

const SignupContainer = lazy(
  () => import("./page/Signup/container/SignupContainer")
);
const PasswordContainer = lazy(
  () => import("./page/PasswordChange/container/PasswordContainer")
);
const LoginContainer = lazy(
  () => import("./page/Login/container/LoginContainer")
);

const AboutPage = lazy(() => import("./page/About/About"));
const PQfundingContainer = lazy(
  () => import("./page/PQFunding/container/PQFundingContainer")
);
const PQfundingOpenContainer = lazy(
  () => import("./page/PQFunding/container/PQFundingOpenContainer")
);
const OrderMadeContainer = lazy(
  () => import("./page/PQFunding/container/OrderMadeContainer")
);
const OrderMadeOpenContainer = lazy(
  () => import("./page/PQFunding/container/OrderMadeOpenContainer")
);

const UserRoutes = lazy(() => import("./routes/UserRoutes"));
const WriteProjectRoutes = lazy(() => import("./routes/WriteProjectRoutes"));
const ProjectDetailContainer = lazy(
  () => import("./page/ProjectDetail/container/ProjectDetailContainer")
);
const OrderDetailContainer = lazy(
  () => import("./page/ProjectDetail/container/OrderDetailContainer")
);
const ProjectPaymentContainer = lazy(
  () => import("./page/ProjectPayment/container/ProjectPaymentContainer")
);
const OrderMainContainer = lazy(
  () => import("./page/Main/container/OrderMainContainer")
);
const Inquiry = lazy(() => import("./page/Inquiry/Inquiry"));
const PaymentFinish = lazy(() => import("./page/PaymentFinish/PaymentFinish"));
const StoryContainer = lazy(
  () => import("./page/Story/container/StoryContainer")
);
const Policy = lazy(() => import("./page/Policy/Policy"));
const ProjectPqBoxContainer = lazy(
  () => import("./page/PqBox/container/ProjectPqBoxContainer")
);
const OrderPqBoxContainer = lazy(
  () => import("./page/PqBox/container/OrderPqBoxContainer")
);
const PasswordFindContainer = lazy(
  () => import("./page/PasswordFind/container/PasswordFindContainer")
);
function App() {
  const dispatch = useDispatch();
  const [userAgent, setUserAgent] = useState<UserAgentType>("pc");

  useEffect(() => {
    function change(e: any) {
      const width = e.target.innerWidth;
      if (width < 1366 && width > 767 && userAgent !== "tablet") {
        setUserAgent("tablet");
      }
      if (width < 768 && userAgent !== "mb") {
        setUserAgent("mb");
      }
      if (width > 1366 && userAgent !== "pc") {
        setUserAgent("pc");
      }
    }
    window.addEventListener("resize", change);
    return () => {
      window.removeEventListener("resize", change);
    };
  }, [userAgent]);

  useEffect(() => {
    const Kakao = (window as any).Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init("9d6518ea64f58fc2789e4d233c15b3fb");
    }
    const size = window.innerWidth;
    if (size < 1366 && size > 767) {
      setUserAgent("tablet");
    }
    if (size < 768) {
      setUserAgent("mb");
    }
    if (size > 1365) {
      setUserAgent("pc");
    }
    return () => {};
  }, []);

  useEffect(() => {
    BannerGetApi().then(({ state, data }) => {
      if (state) {
        dispatch(SetBanner(data));
      }
    });
    PolicyGetApi().then(({ state, data, msg }) => {
      console.log(msg);
      if (state) {
        dispatch(SetPolicy(data));
      }
    });
    return () => {};
  }, []);

  return (
    <BrowserRouter>
      <Header userAgent={userAgent} />
      <Suspense fallback={<main></main>}>
        <Routes>
          {/* 오더메이드 페이지 */}
          <Route
            path="/ordermade"
            element={<OrderMainContainer userAgent={userAgent} />}
          />
          {/* 펀딩 페이지 */}
          <Route
            path="/funding"
            element={<MainContainer userAgent={userAgent} />}
          />
          <Route
            path="/policy/:id"
            element={<Policy userAgent={userAgent} />}
          />
          <Route
            path="/policy"
            element={<Navigate replace to="/policy/terms" />}
          />
          {/* 유저 정보 관련 페이지 */}
          <Route path="/my/*" element={<UserRoutes userAgent={userAgent} />} />
          {/* 로그인 페이지 */}
          <Route
            path="/login"
            element={<LoginContainer userAgent={userAgent} />}
          />
          <Route path="/login/loading" element={<LoginLoading />} />
          {/* 어바웃 페이지 */}
          <Route path="/about" element={<AboutPage />} />
          {/* 회원가입 페이지 */}
          <Route
            path="/signup"
            element={<SignupContainer userAgent={userAgent} />}
          />
          {/*소셜 회원가입 페이지 */}
          <Route
            path="/signup/social"
            element={<SocialSignupContainer userAgent={userAgent} />}
          />
          {/* 회원가입 완료 페이지 */}
          <Route path="/signup/finish" element={<SignupFinish />} />
          {/* 비밀번호찾기 페이지 */}
          <Route path="/password/find" element={<PasswordFindContainer />} />
          {/* 비밀번호변경 페이지 */}
          <Route path="/password/change" element={<PasswordContainer />} />
          {/* 공지사항 */}
          <Route
            path="/notice"
            element={<NoticeContainer userAgent={userAgent} />}
          />
          {/* pq 스토리 */}
          <Route
            path="/story"
            element={<StoryContainer userAgent={userAgent} />}
          />
          {/* pq 스토리 작성 */}
          <Route path="/story/write" element={<StoryWriteContainer />} />
          {/* pq 스토리 수정 */}
          <Route path="/story/fix" element={<StoryFixContainer />} />
          {/* 스토리 상세 */}
          <Route path="/story/:id" element={<StoryDetailContainer />} />

          <Route path="/ask" element={<Inquiry />} />
          {/* 공지사항 상세페이지 */}
          <Route
            path="/notice/detail/:id"
            element={<NoticeDetailContainer userAgent={userAgent} />}
          />
          {/* PQ펀딩 전체프로젝트페이지 */}
          <Route
            path="/projects/funding/list"
            element={<PQfundingContainer userAgent={userAgent} />}
          />
          {/* PQ펀딩 오픈 프로젝트페이지 */}
          <Route
            path="/projects/open/list"
            element={<PQfundingOpenContainer userAgent={userAgent} />}
          />
          {/* PQ펀딩 pq박스 프로젝트페이지 */}
          <Route
            path="/projects/pqbox/list"
            element={<ProjectPqBoxContainer />}
          />
          {/* 결제 페이지 */}
          <Route
            path="/payment"
            element={<ProjectPaymentContainer userAgent={userAgent} />}
          />
          {/* 결제 수정 페이지 */}
          <Route
            path="/payment/fix"
            element={<PaymentFixContainer userAgent={userAgent} />}
          />
          {/* 결제 완료 페이지 */}
          <Route path="/payment/finish" element={<PaymentFinish />} />
          <Route
            path="/ordermade/order/list"
            element={<OrderMadeContainer userAgent={userAgent} />}
          />
          <Route
            path="/ordermade/open/list"
            element={<OrderMadeOpenContainer userAgent={userAgent} />}
          />
          {/* 오더메이드 pq박스 프로젝트페이지 */}
          <Route
            path="/ordermade/pqbox/list"
            element={<OrderPqBoxContainer />}
          />
          {/* 프로젝트 작성 관련 route */}
          <Route
            path="/write/*"
            element={<WriteProjectRoutes userAgent={userAgent} />}
          />
          {/* 검색 */}
          <Route
            path="/search"
            element={<SearchContainer userAgent={userAgent} />}
          />
          {/* 프로젝트 상세 페이지 */}
          <Route
            path="/funding/detail/:id/*"
            element={<ProjectDetailContainer userAgent={userAgent} />}
          />
          {/* 오더메이드 상세 페이지 */}
          <Route
            path="/order/detail/:id/*"
            element={<OrderDetailContainer userAgent={userAgent} />}
          />
          {/* PQBOX 관련 route */}
          <Route
            path="/pqbox/*"
            element={<RewardRoutes userAgent={userAgent} />}
          />
          {/* 메인 페이지 */}
          <Route path="/" element={<HomeContainer userAgent={userAgent} />} />
          <Route path="/*" element={<Page_404 />} />
        </Routes>
      </Suspense>
      <Loading />
      <Toast />
      <Footer userAgent={userAgent} />
    </BrowserRouter>
  );
}

export default App;
