import React from "react";
import "./css/index.css";
type Props = {
  title: string;
  sub: string;
};

const EmtyList = ({ title, sub }: Props) => {
  return (
    <div className="emty-list">
      <img src="/assets/emty/gnpd.png"  
        srcSet={`/assets/emty/gnpd@2x.png 2x , /assets/emty/gnpd@3x.png 3x`}
      alt="" />
      <div className="emty-title">{title}</div>
      <div className="emty-sub">{sub}</div>
    </div>
  );
};

export default EmtyList;
