import { useLocation, useNavigate } from "react-router-dom";
import { UseStoryWrite } from "../../../hooks/UseStoryWrite";
import StoryWrite from "../StoryWrite";
import { useEffect } from "react";

const StoryFixContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { SubmitFixStory, state, UpdateData } = UseStoryWrite("fix");
  useEffect(() => {
    if (location.state) {
      let obj = Object.assign({}, location.state);
      const arr = Array(5)
        .fill(undefined)
        .map((item, idx) => {
          return obj.images[idx] ? obj.images[idx] : item;
        });
      obj.images = arr;
      UpdateData("dispatch/all", obj);
    } else {
      navigate("/story");
      alert("잘못된 접근입니다");
    }

    return () => {};
  }, [location]);

  return (
    <StoryWrite
      data={state}
      UpdateData={UpdateData}
      SubmitStory={SubmitFixStory}
      type="fix"
    />
  );
};

export default StoryFixContainer;
