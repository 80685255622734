import React from "react";
import "./css/index.css";
import {  UserAgentType } from "../../common/common.inerface";
import LineAdd from "../../components/main/LineAdd/LineAdd";
import BannerSection from "../../components/main/BannerSection/BannerSection";
import MainList from "../../components/main/MainList/MainList";
// import NoticeSection from "../../components/main/NoticeSection/NoticeSection";
import {
  ProjectType,
  PurposeType,
  SliderType,
} from "../../interface/interface";
type Props = {
  userAgent: UserAgentType;
  bannerData: SliderType[];
  fundigData?: ProjectType[];
  OpenData: ProjectType[];
  purpose: PurposeType;
  puplarData?: ProjectType[];
  scrapList?: { key: string; purpose: PurposeType }[];
  ScrapProject: (key: string, purpose: PurposeType) => void;
};

const Main = ({
  userAgent,
  fundigData,
  OpenData,
  bannerData,
  purpose,
  puplarData,
  scrapList,
  ScrapProject,
}: Props) => {
  return (
    <main className="main-page">
      <div className="main-wrapper">
        <BannerSection
          data={bannerData}
          prjtData={puplarData}
          userAgent={userAgent}
          type={purpose === "projects" ? "funding" : "order"}
          purpose={purpose}
        />
        <MainList
          title={purpose === "projects" ? `펀딩 프로젝트` : "오더메이드"}
          type={purpose === "projects" ? "funding" : "order"}
          purpose={purpose}
          scrapList={scrapList}
          ScrapProject={ScrapProject}
          data={fundigData}
        />
      </div>
      <LineAdd />
      <div className="main-wrapper">
        <MainList
          title={`오픈예정 ${
            purpose === "projects" ? "프로젝트" : "오더메이드"
          }`}
          type="open"
          purpose={purpose}
          data={OpenData}
          scrapList={scrapList}
          ScrapProject={ScrapProject}
        />
      </div>
      {/* <div className="main-wrapper">
        <MainList title="PQ박스" type="pqbox" purpose={purpose} data={[]} />
      </div> */}
    </main>
  );
};

export default Main;
