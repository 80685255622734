import React, { useEffect, useState } from "react";
import { SignInterface } from "../../common/common.inerface";
import { passwordreg } from "../../page/Signup/Signup";

type Props = {
  data: SignInterface;
  errorType: string | undefined;
  SubmitSignup: () => void;
};
const JoinBottom = ({ data, errorType, SubmitSignup }: Props) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const __checkValidate = () => {
      if (
        errorType === undefined &&
        data.email !== "" &&
        data.name !== "" &&
        data.password !== "" &&
        passwordreg.test(data.password) &&
        data.password === data.passwordcheck &&
        data.policy[0] &&data.policy[1]
      ) {
        setIsReady(true);
      } else {
        setIsReady(false);
      }
    };

    __checkValidate();
  }, [data, errorType]);

  return (
    <div className="bottom">
      <button
        className={`nextBtn ${isReady ? "active" : ""}`}
        type={isReady ? "submit" : "button"}
        onClick={() => {
          if (isReady) {
            SubmitSignup();
          }
        }}
      >
        회원가입 완료
      </button>
    </div>
  );
};

export default JoinBottom;
