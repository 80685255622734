import React, { useCallback, useEffect, useRef } from "react";

type Props = { PagingUpdate: () => void };

function Infinite({ PagingUpdate }: Props) {
  const dom = useRef(null);
  const handleScroll = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting) {
        PagingUpdate();
      }
    },
    [PagingUpdate]
  );

  useEffect(() => {
    if (dom) {
      let observer: IntersectionObserver;
      const { current } = dom;
      if (current) {
        observer = new IntersectionObserver(handleScroll, {
          threshold: 0.1,
        });
        observer.observe(current);
        return () => observer && observer.disconnect();
      }
    }
  }, [handleScroll, dom]);

  return <div ref={dom}></div>;
}

export default Infinite;
