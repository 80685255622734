import React, { useEffect, useState } from "react";
import { ReactComponent as LikeSvg } from "../svg/like.svg";
import "./css/index.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { ToastChange } from "../../../reducers/config";
type Props = {
  like: number;
  ChangeLike: (key: string) => void;
  id: string;
  isLike: boolean;
  type?: "detail";
};

const StoryLike = ({ like, ChangeLike, id, isLike, type }: Props) => {
  const dispatch = useDispatch();
  const uid = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  const [likeNumber, setlikeNumber] = useState<number>(like);
  const [LikeState, setLikeState] = useState(isLike);
  const [timer, setTimer] = useState<undefined | NodeJS.Timeout>(undefined);
  useEffect(() => {
    setLikeState(isLike);
    setlikeNumber(like);
    return () => {};
  }, [isLike, like]);

  return (
    <button
      className={`story-like-btn ${type}-like-btn`}
      onClick={() => {
        if (uid) {
          if (!timer) {
            clearTimeout(timer);
          }
          const time = setTimeout(() => {
            ChangeLike(id);
          }, 200);
          setTimer(time);
          setLikeState(!LikeState);
          setlikeNumber(LikeState ? likeNumber - 1 : likeNumber + 1);
        } else {
          dispatch(
            ToastChange({ active: true, content: "로그인 후 이용 가능합니다" })
          );
        }
      }}
    >
      <LikeSvg className={`${LikeState && "like-on"}`} />
      {likeNumber ? likeNumber : 0}
    </button>
  );
};

export default StoryLike;
