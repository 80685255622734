import React, { ChangeEvent } from "react";
import { ref, getDownloadURL, uploadString } from "@firebase/storage";
import "./css/index.css";
import { Fstorage } from "../../config/firebaseApp";
import { Resizing } from "../../lib/lib";
type Props = {
  item: string | undefined;
  idx: number;
  UpdateData: (type: string, value: string, index?: number) => void;
  dataKey: string;
};
const ImageBox = ({ UpdateData, item, idx, dataKey }: Props) => {
  const ImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const url = await Resizing(file, 724, 724);
      const storageRef = ref(Fstorage, `/story/${dataKey}/${file.name}`);
      uploadString(storageRef, url, "data_url").then((snapshot) => {
        const downloadURLRef = ref(Fstorage, snapshot.ref.fullPath);
        getDownloadURL(downloadURLRef).then((downloadURL) => {
          UpdateData("images", downloadURL, idx);
        });
      });
    }
  };
  return (
    <div className="image-box">
      {item ? (
        <div className="selected-img">
          <img src={item} className="selected-img" alt="" />
          <div className="hover-img">
            <img
              src="/assets/story/cancel.png"
              alt=""
              onClick={() => {
                UpdateData("images", "", idx);
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <label
            className="upload-text"
            htmlFor={`common-file-label-story-${idx}`}
          >
            <div className="plus">
              <img src="/assets/story/frame.svg" alt="frame" />
            </div>
            <div>이미지 업로드</div>
          </label>
          <input
            onChange={ImageChange}
            type="file"
            id={`common-file-label-story-${idx}`}
            className="file"
            size={5000000}
            accept=".jpg, .png"
          />
        </>
      )}
    </div>
  );
};
export default ImageBox;
