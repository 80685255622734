import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";
import SavePopup from "./components/SavePopup";
import SaveDeletePopup from "./components/SaveDeletePopup";
import RemovePopup from "./components/RemovePopup";
import RejectPopup from "./components/RejectPopup";
import PreparePopup from "./components/PreparePopup";
import FixPopup from "./components/FixPopup";
import RefundPopup from "./components/RefundPopup";
import ChangeCardPopup from "./components/ChangeCardPopup";
import RemoveCardPopup from "./components/RemoveCardPopup";
import CommentRemovePopup from "./components/CommentRemovePopup";
import CommentReportPopup from "./components/CommentReportPopup";
import PaymentSubmitPopup from "./components/PaymentSubmitPopup";
import PqboxRejectPopup from "./components/PqboxRejectPopup";
import WidthDrawPopup from "./components/WidthDrawPopup";
interface PopupInt {
  type?: string;
  data?: any;
  Active: (type?: any) => void;
  DeActive?: () => void;
  setCancel?: Dispatch<SetStateAction<any | undefined>>;
}

function Popup({ type, Active, DeActive, setCancel, data }: PopupInt) {
  return (
    <div className={`popup ${type ? "popup-on" : ""}`}>
      <div className="back" />
      <div className="popup-content">
        {type === "delete-popup" && (
          <RemovePopup setCancel={setCancel} Active={Active} />
        )}
        {type === "save" && <SavePopup Active={Active} DeActive={DeActive} />}
        {type === "project-delete" && (
          <SaveDeletePopup setCancel={setCancel} Active={Active} type={type} />
        )}
        {type === "project-reject" && (
          <RejectPopup
            setCancel={setCancel}
            Active={Active}
            type={type}
            data={data}
          />
        )}
        {type === "project-prepare" && (
          <PreparePopup setCancel={setCancel} Active={Active} type={type} />
        )}
        {type === "project-fix" && (
          <FixPopup setCancel={setCancel} Active={Active} />
        )}
        {type === "refund" && (
          <RefundPopup setCancel={setCancel} Active={Active} />
        )}
        {type === "change-card" && (
          <>
            <button
              className="cancel"
              onClick={() => {
                if (setCancel) {
                  setCancel(undefined);
                }
              }}
            >
              <img src="/assets/common/cancel.svg" alt="닫기" />
            </button>{" "}
            <ChangeCardPopup initialCard={data} Active={Active} />
          </>
        )}
        {type === "remove-card" && (
          <RemoveCardPopup setCancel={setCancel} Active={Active} />
        )}
        {type === "comment-remove" && (
          <CommentRemovePopup setCancel={setCancel} Active={Active} />
        )}
        {type === "comment-report" && (
          <CommentReportPopup setCancel={setCancel} Active={Active} />
        )}
        {type === "payment-submit" && (
          <PaymentSubmitPopup
            setCancel={setCancel}
            Active={Active}
            data={data}
          />
        )}
        {type === "pqbox-reject" && (
          <PqboxRejectPopup setCancel={setCancel} data={data} />
        )}
        {type === "withdraw" && (
          <WidthDrawPopup setCancel={setCancel} Active={Active} />
        )}
      </div>
    </div>
  );
}

export default Popup;
