import React, { useState } from "react";
import "./css/index.css";
type Props = {
     ChangeSearchState:  (type:"like"|"search",value:string|boolean)=>void;
};

const SearchInput = ({ChangeSearchState}: Props) => {
  const [timer, setTimer] = useState<undefined| NodeJS.Timeout>(undefined)
  return (
    <div className="common-search-input">
      <input type="text" placeholder="검색하기"  onChange={(e)=>{
        if (timer) {
          clearTimeout(timer)
        }
        const time =setTimeout(() => {
 ChangeSearchState("search",e.target.value)
        }, 250)
        setTimer(time)
      }}/>
      <img src="/assets/common/search.svg" alt="검색" />
    </div>
  );
};

export default SearchInput;
