import React, { Dispatch, SetStateAction } from "react";

type Props = {
  Active: () => void;
  setCancel?: Dispatch<SetStateAction<undefined>>;
};

const RemoveCardPopup = ({ Active, setCancel }: Props) => {
  return (
    <div className="save-popup">
      <div className="title">카드를 삭제하시겠습니까?</div>
      <div className="sub">결제 대기중인 카드는 삭제가 불가능합니다.</div>
      <div className="btn-wrapper">
        <button
          className="active-btn"
          onClick={() => {
            Active();
          }}
        >
          삭제하기
        </button>
        <button
          className="deactive-btn"
          onClick={() => {
            if (setCancel) {
              setCancel(undefined);
            }
          }}
        >
          취소
        </button>
      </div>
    </div>
  );
};

export default RemoveCardPopup;
