import React from "react";
import "./css/index.css";
import SearchInput from "../../Common/SearchInput/SearchInput";
import { Link, useNavigate } from "react-router-dom";
import { UserAgentType } from "../../../common/common.inerface";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import { ToastChange } from "../../../reducers/config";
type Props = {
  userAgent: UserAgentType;
  ChangeSearchState: (type: "like" | "search", value: string | boolean) => void;
  isLike: boolean;
};

const StoryListSearch = ({ userAgent, isLike, ChangeSearchState }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uid = useSelector<RootState, string | undefined>(
    (state) => state.auth.uid
  );
  return (
    <div className="story-list-search">
      <button
        className="sort-btn"
        onClick={() => {
          ChangeSearchState("like", !isLike);
        }}
      >
        <div className="circle">
          {isLike && <img src="/assets/common/check.svg" alt="체크" />}
        </div>
        <span>좋아요 순{userAgent !== "mb" && "으로 보기"}</span>
      </button>
      <SearchInput ChangeSearchState={ChangeSearchState} />
      <button
        className="story-write-nav"
        onClick={() => {
          if (uid) {
            navigate("/story/write");
          } else {
                        navigate("/login");
            dispatch(
              ToastChange({
                active: true,
                content: "로그인 후 이용해주세요",
              })
            );
          }
        }}
      >
        {userAgent === "mb" && (
          <img src="/assets/story/story-write.svg" alt="작성" />
        )}
        스토리 작성
      </button>
    </div>
  );
};

export default StoryListSearch;
