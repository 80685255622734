import React, { useCallback, useEffect, useState } from "react";
import Main from "../Main";
import { UserAgentType } from "../../../common/common.inerface";
import { GetProjectList } from "../../../api/project_api";
import { ProjectType, SliderType } from "../../../interface/interface";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers";
import UseScrap from "../../../hooks/UseScrap";
import ChannelService from "../../../lib/ChannelService";

type Props = {
  userAgent: UserAgentType;
};

const MainContainer = ({ userAgent }: Props) => {
  const puplarData = useSelector<RootState, ProjectType[] | undefined>(
    (state) => state.data.popular.projects
  );
  const bannerData = useSelector<RootState, SliderType[]>(
    (state) => state.data.banner.slider
  );
  const [fundigData, setFundigData] = useState<ProjectType[]|undefined>(undefined);
  const [OpenData, setOpenData] = useState<ProjectType[]>([]);
  const [boxData, setboxData] = useState([]);
  const { scrapList, ScrapProject } = UseScrap();

  const GetFundingData = useCallback(async () => {
    const { state, data } = await GetProjectList({
      page: 0,
      number: userAgent === "pc" ? 3 : 2,
      purpose: "projects",
    });
    if (state) {
      setFundigData(data);
    }
  }, [userAgent]);
  const GetOpenData = useCallback(async () => {
    const { state, data } = await GetProjectList({
      page: 0,
      number: userAgent === "pc" ? 3 : 2,
      purpose: "projects",
      state: "ready",
    });
    if (state) {
      setOpenData(data);
    }
  }, [userAgent]);
  const GetBoxData = useCallback(async () => {}, []);

  useEffect(() => {
    GetFundingData();
    GetOpenData();
    ChannelService.loadScript();
    ChannelService.boot({
      pluginKey: "9bf18051-16ad-4695-9699-17f64cbada0c",
    });
    return () => {
      ChannelService.shutdown();
    };
  }, [GetFundingData, GetOpenData]);

  return (
    <Main
      userAgent={userAgent}
      fundigData={fundigData}
      OpenData={OpenData}
      bannerData={bannerData}
      puplarData={puplarData}
      scrapList={scrapList}
      ScrapProject={ScrapProject}
      purpose="projects"
    />
  );
};

export default MainContainer;
