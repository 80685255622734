import React, { Dispatch, SetStateAction } from "react";
import "./css/index.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthType } from "../../../../reducers/auth";
import { UserAgentType } from "../../../../common/common.inerface";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  SignOut: () => void;
  userInfo: AuthType;
  userAgent: UserAgentType;
};

const UserNavSection = ({
  isOpen,
  setIsOpen,
  SignOut,
  userInfo: {
    profile: { nickname, image },
  },
  userAgent,
}: Props) => {
  return (
    <div className={`user-nav-section ${isOpen && "open-nav"}`}>
      <button
        className="user-nav-cancel"
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <img src="/assets/header/cancel.svg" alt="닫기" />
      </button>
      <Link
        className="user-nav-profile-wrapper"
        to={"/my/info"}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <div className="user-nav-profile-left">
          <div
            className="profile-img"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
          <div className="profile-nicknmae">
            <b>{nickname}님</b> 안녕하세요
          </div>
        </div>
        <img src="/assets/header/info.svg" alt="내정보" />
      </Link>
      <div className="user-nav-btn-wrapper">
        {userAgent === "pc" && (
          <Link className="project-btn" to="/write/select">
            프로젝트 만들기
          </Link>
        )}
      </div>
      {top_layouts.map(({ title, link }, idx) => {
        return (
          <Link
            to={link}
            key={idx}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {title}
          </Link>
        );
      })}
      <div className="line" />
      {profile_layouts.map(({ title, link }, idx) => {
        return (
          <Link
            to={link}
            key={idx}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {title}
          </Link>
        );
      })}
      <div className="line" />
      {commment_layouts.map(({ title, link }, idx) => {
        return (
          <Link
            to={link}
            key={idx}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {title}
          </Link>
        );
      })}
      <div className="line" />
      {sub_profile_layouts.map(({ title, link, type }, idx) => {
        if (type === "btn") {
          return (
            <button
              key={idx}
              onClick={() => {
                setIsOpen(false);
                SignOut();
              }}
            >
              {title}
            </button>
          );
        }
        return (
          <Link
            to={link}
            key={idx}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {title}
          </Link>
        );
      })}
    </div>
  );
};

export default UserNavSection;

const top_layouts = [
  {
    title: "결제카드 등록",
    link: "/my/card",
    type: "link",
  },
];

const profile_layouts = [
  {
    title: "후원한 프로젝트 관리",
    link: "/my/list",
    type: "link",
  },
  {
    title: "오픈한 프로젝트 관리",
    link: "/my/open",
    type: "link",
  },
  {
    title: "스크랩 프로젝트",
    link: "/my/scrap",
    type: "link",
  },
];
const commment_layouts = [
  {
    title: "프로젝트 문의목록",
    link: "/my/inquiry",
    type: "link",
  },
  {
    title: "스토리 작성목록",
    link: "/my/str",
    type: "link",
  },
  {
    title: "댓글 작성목록",
    link: "/my/comment",
    type: "link",
  },
];

const sub_profile_layouts = [
  {
    title: "로그아웃",
    link: "/my/card",
    type: "btn",
  },
];
