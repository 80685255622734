import React, { useCallback, useEffect, useReducer, useState } from "react";
import { clientApi } from "../api/config";
import { AuthType } from "../reducers/auth";
import { useNavigate } from "react-router-dom";
import { StoryReducer } from "../lib/reducer";
import { StoryInitial } from "../lib/initial";
import { ToastChange } from "../reducers/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers";

export const UseStoryWrite = (type:"fix"|"write") => {
  const navigate = useNavigate();
  const ToastMsg = useDispatch();
  const [state, dispatch] = useReducer(StoryReducer, undefined, StoryInitial);
  const UserInfo = useSelector<RootState, AuthType>((state) => state.auth);

  const UpdateData = (type: string, value: string | number, index?: number) => {
    dispatch({
      type,
      payload: value,
      index,
    });
  };

  const SubmitStory = useCallback(() => {
    const data = Object.assign(
      {
        timestamp: Date.now(),
        images: state.images.filter((item: string) => item),
      },
      state
    );
    let profile = Object.assign({ uid: UserInfo.uid }, UserInfo.profile);
    clientApi
      .post("/story/write", {
        data: data,
        profile,
      })
      .then(({ data: { state, msg } }) => {
        if (state) {
          navigate("/story");
        }
        ToastMsg(ToastChange({ active: true, content: msg }));
      })
      .catch((error) => {
        console.log("err", error);
      });
  }, [state, navigate, UserInfo]);

  const SubmitFixStory = useCallback(() => {
    const data = Object.assign(state, {
      images: state.images.filter((item: string) => item),
    });
    let profile = Object.assign({ uid: UserInfo.uid }, UserInfo.profile);
    clientApi
      .post("/story/fix", {
        data: data,
        profile,
      })
      .then(({ data: { state, msg } }) => {
        if (state) {
          navigate("/story");
        }
        ToastMsg(ToastChange({ active: true, content: msg }));
      })
      .catch((error) => {
        console.log("err", error);
      });
  }, [state, navigate, UserInfo]);

  useEffect(() => {
    if (type==="write") {
      clientApi.get("/push/key").then(({data:{
        state,data
      }})=>{
        if (state) {
          dispatch({
            type:"key",
            payload:data
          })
        }
      })
    }
    return () => {};
  }, [type]);

  useEffect(() => {
    if (UserInfo.uid !== undefined && !UserInfo.uid) {
      navigate("/login");
      dispatch(
        ToastChange({ active: true, content: "로그인 후에 접근 가능합니다." })
      );
    }
  }, [UserInfo]);

  return { SubmitStory, state, UpdateData, SubmitFixStory };
};
