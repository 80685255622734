import { useCallback, useEffect, useState } from "react";
import { UserAgentType } from "../../../common/common.inerface";
import UseScrap from "../../../hooks/UseScrap";
import Search from "../Search";
import UseStoryDetail from "../../../hooks/UseStoryDetail";
import {
  ProjectType,
  PurposeType,
  StoryType,
} from "../../../interface/interface";
import { clientApi } from "../../../api/config";
import { useLocation } from "react-router-dom";

type paramsType = {
  page: number;
  number: number;
  text: string;
  sort: number;
  purpose?: PurposeType;
  reward?: "PT10" | "PT01";
  isPq: boolean;
  state?: "funding" | "finish";
};

type Props = {
  userAgent: UserAgentType;
};
const SearchContainer = ({ userAgent }: Props) => {
  const location = useLocation();
  const [projectPage, setProjectPage] = useState<number>(0);
  const [storyPage, setStoryPage] = useState<number>(0);
  const [Sort, setSort] = useState<number>(0);
  const [isPq, setIsPq] = useState<boolean>(false);
  const [RewardFilter, setRewardFilter] = useState<
    undefined | "funding" | "finish"
  >(undefined);
  const [searchtext, setSearchtext] = useState<string>(
    location.state ? location.state : ""
  );
  const [projectData, setProjectData] = useState<ProjectType[] | undefined>(undefined);
  const [storyData, setStoryData] = useState<StoryType[] | undefined>(undefined);
  const [nowCategory, setNowCategory] = useState<number>(0);
  const [prevSearch, setPrevSearch] = useState<paramsType | undefined>(
    undefined
  );
  const { scrapList, ScrapProject } = UseScrap();
  const { ChangeLike, user_story } = UseStoryDetail();

  const SearchInputData = useCallback(async (text: string) => {
    setProjectPage(0);
    setStoryPage(0);
    setSearchtext(text);
  }, []);

  const ProjectData = useCallback(async () => {
    try {
      let obj: any = prevSearch
        ? Object.assign(prevSearch, { page: projectPage })
        : {};
      const {
        data: { state, data },
      } = await clientApi.post("/project/search", obj);
      if (state) {
        if (obj.page === 0) {
          setProjectData(data);
        } else {
          setProjectData((prevData) => {
            if (prevData) {
              return [...prevData, ...data];
            }
            return [...data];
          });
        }
      } else {
        setProjectData([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [projectPage, prevSearch]);
  const GetStory = useCallback(async () => {
    try {
      let obj: any = {
        page: storyPage,
        number: 9,
        search: searchtext,
      };
      const {
        data: { state, data },
      } = await clientApi.get("/story/list", {
        params: obj,
      });
      if (state) {
        if (obj.page === 0) {
          setStoryData(data);
        } else {
          setStoryData((prevData) => {
            if (prevData) {
              return [...prevData, ...data];
            }
            return [...data];
          });
        }
      } else {
        setStoryData([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [storyPage, searchtext]);

  useEffect(() => {
    let obj: paramsType = {
      page: 0,
      number: 6,
      text: searchtext,
      sort: Sort,
      purpose: nowCategory
        ? nowCategory === 1
          ? "ordermade"
          : "projects"
        : undefined,
      state: RewardFilter,
      isPq,
    };
    setProjectPage(0);
    setProjectData(undefined)
    setPrevSearch(obj);
  }, [searchtext, Sort, nowCategory, RewardFilter, isPq]);
  useEffect(() => {
    setSearchtext(location.state ? location.state : "");
    return () => {};
  }, [location.state]);

  useEffect(() => {
    if (prevSearch) {
      ProjectData();
    }
    return () => {};
  }, [ProjectData]);

  useEffect(() => {
    GetStory();
    return () => {};
  }, [GetStory]);

  return (
    <Search
      pqPage={storyPage}
      projectPage={projectPage}
      setStoryPage={setStoryPage}
      projectData={projectData}
      nowCategory={nowCategory}
      setNowCategory={setNowCategory}
      userAgent={userAgent}
      ChangeLike={ChangeLike}
      user_story={user_story}
      ScrapProject={ScrapProject}
      scrapList={scrapList}
      purpose="projects"
      RewardFilter={RewardFilter}
      Sort={Sort}
      setSort={setSort}
      setRewardFilter={setRewardFilter}
      isPq={isPq}
      setIsPq={setIsPq}
      SearchInputData={SearchInputData}
      storyData={storyData}
      setProjectPage={setProjectPage}
      searchtext={searchtext}
    />
  );
};
export default SearchContainer;
